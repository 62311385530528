import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  HStack,
  Popover as ChakraPopover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'

import { NucDownload } from '@nucba-coding/nucba-ui'

type Option = {
  id: string
  desc: string
}

type ProductOptsPopoverProps = {
  triggerText: string
  header: string
  options: Option[]
  onSelect: (id: string) => void

  initialSelected?: string
}

export const ProductOptsPopover = ({
  header,
  triggerText,
  options,
  onSelect,
  initialSelected,
}: ProductOptsPopoverProps) => {
  const [selected, setSelected] = useState<null | Option>(null)

  useEffect(() => {
    setSelected(options.find(option => option.id === initialSelected) ?? null)
  }, [initialSelected])

  return (
    <ChakraPopover placement='bottom' matchWidth>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <HStack
              as={Button}
              justify='space-between'
              background='rgba(0, 0, 0, 0.16)'
              boxShadow=' 0px 0px 20px rgba(0, 0, 0, 0.25)'
              borderRadius={20}
            >
              <Text fontSize='13px' fontWeight={400} noOfLines={1}>
                {!selected ? triggerText : selected.desc}
              </Text>
              <NucDownload width='18' height='18' />
            </HStack>
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              w='full'
              bgColor='nucba.background'
              borderRadius={30}
              border='none'
              boxShadow='0px 0px 20px 1px rgba(0, 0, 0, 0.25)'
            >
              <PopoverHeader
                fontSize='sm'
                fontWeight={700}
                textAlign='center'
                textTransform='uppercase'
                border='none'
              >
                {header}
              </PopoverHeader>
              <PopoverCloseButton mr={3} mt={1} />

              <Stack as={PopoverBody}>
                {options.length ? (
                  options.map(option => (
                    <Box
                      key={option.id}
                      as={motion.div}
                      whileTap={{ scale: 0.97 }}
                      paddingInline={4}
                      paddingBlock={3}
                      borderRadius={30}
                      borderBottom='2px solid rgba(0, 0, 0, 0.16);'
                      cursor='pointer'
                      userSelect='none'
                      onClick={() => {
                        setSelected(option)
                        onSelect(option.id)
                        onClose()
                      }}
                    >
                      <Text
                        fontSize='13px'
                        fontWeight={selected?.id === option.id ? 800 : 400}
                      >
                        {option.desc}
                      </Text>
                    </Box>
                  ))
                ) : (
                  <Text fontSize='13px' fontWeight={400} textAlign='center'>
                    No hay opciones disponibles 😢
                  </Text>
                )}
              </Stack>
            </PopoverContent>
          </Portal>
        </>
      )}
    </ChakraPopover>
  )
}
