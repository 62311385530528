import React from 'react'
import { GridItem, Skeleton } from '@chakra-ui/react'
import { Card } from '@nucba-coding/nucba-ui'

import { generateArrayWithRandomKeys } from '@/shared/utils'

export const MyCohortesLoader = () => {
  return (
    <>
      {generateArrayWithRandomKeys(2).map(li => (
        <Skeleton key={li} borderRadius={23}>
          <GridItem>
            <Card title='' bgImage='' w='auto' />
          </GridItem>
        </Skeleton>
      ))}
    </>
  )
}
