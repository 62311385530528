import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { useAppSelector } from '@/shared/app/hooks'
import { sessionState } from '@/shared/features/session.slice'

export const SubscriptionsRoot = () => {
  const { isStaff } = useAppSelector(sessionState)

  if (isStaff) {
    return <Navigate to='/' replace />
  }

  return <Outlet />
}
