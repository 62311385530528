import React, { useEffect } from 'react'
import { Grid, Box, Text, Stack, HStack } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { Button } from '@nucba-coding/nucba-ui'

import { useGetUserCohortsQuery } from '@/shared/services/userQuery.service'
import { useAppDispatch, useAppSelector } from '@/shared/app/hooks'
import { isStudentOf, sessionState } from '@/shared/features/session.slice'
import { PageHeading } from '../../shared/components/PageHeading'
import { MyCohortesLoader } from '../../shared/components/loaders/MyCohortesLoader'
import { CohortCard } from './CohortCard'

export const MyCohortes = () => {
  const dispatch = useAppDispatch()
  const { id } = useAppSelector(sessionState)
  const { data, isLoading, isSuccess } = useGetUserCohortsQuery(id)

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        isStudentOf(
          data?.cohort_integrants.map(
            ({ cohort, id, expelledAt, duo, subscription }) => ({
              studentId: id,
              cohort: cohort.code,
              expelledAt,
              subscription,
              duo,
            }),
          ),
        ),
      )
    }
  }, [isSuccess])

  const cohorts = [...(data?.cohort_integrants ?? [])]

  cohorts.sort((a, b) => +a.cohort.code - +b.cohort.code)

  return (
    <Box w={{ base: '70%', '2xl': '75%' }} mx='auto' my={10}>
      <PageHeading mb={3}>Mis Cohortes</PageHeading>
      <Grid templateColumns='repeat(auto-fit, minmax(330px, 430px))' gap={6}>
        {isLoading ? (
          <MyCohortesLoader />
        ) : cohorts.length ? (
          cohorts.map(({ cohort }) => (
            <CohortCard
              key={cohort.id}
              id={cohort.id}
              code={cohort.code}
              image={cohort.cohort_classes[0].class.course.image}
            />
          ))
        ) : (
          <Stack mt={4} textAlign='center' gap={2}>
            <Text textAlign='left'>
              Todavía no formas parte de ninguna camada 😢
            </Text>
            <HStack>
              <Link to='/mis-suscripciones'>
                <Button>Ver mis suscripciones</Button>
              </Link>
            </HStack>
          </Stack>
        )}
      </Grid>
    </Box>
  )
}
