import React from 'react'

import { HStack, Skeleton, Stack } from '@chakra-ui/react'

import { generateArrayWithRandomKeys } from '@/shared/utils'
import { useAppSelector } from '@/shared/app/hooks'
import { sessionState } from '@/shared/features/session.slice'

export const ChatLoader = () => {
  const { isStaff } = useAppSelector(sessionState)

  return (
    <>
      {generateArrayWithRandomKeys(2).map((li, i) => {
        const isSender = isStaff ? i : !i

        return (
          <Stack
            key={li}
            spacing={3}
            align={isSender ? 'flex-end' : 'flex-start'}
          >
            <HStack
              mt={3}
              ml={isSender ? 0 : 2}
              mr={isSender ? 2 : 0}
              gap={isSender ? 4 : 0}
            >
              <Skeleton
                w='48px'
                h='48px'
                borderRadius={13}
                order={isSender ? 2 : 1}
              />
              <Stack spacing={3} order={isSender ? 1 : 2}>
                <Skeleton w='110px' h='15px' />
                <Skeleton w='55px' h='10px' />
              </Stack>
            </HStack>
            <Skeleton w='250px' h='35px' borderRadius={26} />
          </Stack>
        )
      })}{' '}
    </>
  )
}
