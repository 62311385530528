import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  SkeletonText,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'

import { PageHeading } from '@/inscription/components/PageHeading'
import { TOAST_DEFUALT_OPTIONS } from '@/shared/constants'
import { CustomError, SUBSCRIPTION_ADDITIONAL_TYPES } from '@/shared/types'
import { formatMoney } from '@/inscription/utils/formatMoney'
import { NucUpload } from '@nucba-coding/nucba-ui'
import { ContentTemplate } from '@/education/shared/template/ContentTemplate'
import {
  billingQueryApi,
  useGetInvoiceDetailQuery,
} from '../services/billing-query.service'
import { useUploadReceiptFileMutation } from '../services/billing-mutation.service'
import { useAppDispatch } from '@/shared/app/hooks'
import { userQueryApi } from '@/shared/services/userQuery.service'

const MESSAGES_BY_ADDITIONAL_TYPE: Record<
  SUBSCRIPTION_ADDITIONAL_TYPES,
  string
> = {
  [SUBSCRIPTION_ADDITIONAL_TYPES.CAMBIO_CAMADA]: 'cambio de camada',
  [SUBSCRIPTION_ADDITIONAL_TYPES.MEJORA_ACCESO]: 'mejora de acceso',
}

const transferData = [
  { title: 'CUENTA', subtitle: 'NUCBA S.R.L' },
  { title: 'Alias', subtitle: 'LaNucbanetta' },
  { title: 'CBU', subtitle: '00700146-20000006053224' },
]

export const TransferInvoice = () => {
  const toastSuccess = useToast({
    ...TOAST_DEFUALT_OPTIONS,
    title: 'Comprobante de pago adjunto',
    status: 'success',
  })

  const toastError = useToast({
    ...TOAST_DEFUALT_OPTIONS,
    title: 'Lo sentimos, ha ocurrido un error',
    status: 'error',
  })

  const navigate = useNavigate()
  const { invoiceId } = useParams()

  const dispatch = useAppDispatch()

  const {
    data: invoice,
    isSuccess,
    isLoading,
  } = useGetInvoiceDetailQuery(invoiceId!)

  useEffect(() => {
    if (isSuccess && !invoice) {
      navigate('/mis-suscripciones', { replace: true })
    }
  }, [isSuccess, invoice])

  let invoiceOrder = 0
  const isAdditionalInvoice = !!invoice?.additionalId

  if (invoice) {
    if (isAdditionalInvoice) {
      invoiceOrder =
        invoice?.subscription.invoices
          .filter(inv => inv.additionalId === invoice.additionalId)
          .findIndex(inv => inv.id === invoice.id) + 1
    } else {
      invoiceOrder =
        invoice?.subscription.invoices
          .filter(inv => !inv.additionalId)
          .findIndex(inv => inv.id === invoice.id) + 1
    }
  }

  const inputRef = useRef<any>()
  const [file, setFile] = useState<File | null>(null)
  const [isUploadingReceipt, setIsUploadingReceipt] = useState(false)

  const [uploadReceiptFile] = useUploadReceiptFileMutation()

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0])
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsUploadingReceipt(true)
    if (!file || !invoiceId) return

    const formData = new FormData()
    formData.append('deposit', file)

    try {
      await uploadReceiptFile({ formData, id: invoiceId || '' }).unwrap()
      dispatch(userQueryApi.util.invalidateTags(['Subscriptions']))
      dispatch(billingQueryApi.util.invalidateTags(['OpenInvoice']))
      toastSuccess()
      navigate('/mis-suscripciones')
    } catch (error) {
      toastError({
        description: (error as CustomError).data.message,
      })
    } finally {
      setIsUploadingReceipt(false)
    }
  }

  return (
    <ContentTemplate>
      <Stack w='100%' p={14} spacing={8}>
        <PageHeading title='Pago' />
        <Stack spacing={5}>
          {isLoading ? (
            <SkeletonText noOfLines={2} skeletonHeight={6} width='60%' />
          ) : (
            <>
              <Text fontSize='14px'>
                Para confirmar tu{' '}
                {isAdditionalInvoice
                  ? MESSAGES_BY_ADDITIONAL_TYPE[invoice.additional.type]
                  : 'inscripción'}
                , debés realizar el pago.
              </Text>
              <Text fontWeight={600}>
                Debes transferir{' '}
                {invoice &&
                  `${formatMoney(invoice.amount, {
                    currency: invoice?.subscription.plan?.currency || 'ARS',
                  })} correspondiente a la cuota nro ${invoiceOrder} / ${
                    isAdditionalInvoice
                      ? invoice.additional.installments
                      : invoice.subscription.plan.installments
                  } a la siguiente cuenta:`}
              </Text>
            </>
          )}
          {transferData?.map(val => (
            <Stack key={val.title} spacing={1}>
              <Text fontWeight={600} textTransform='capitalize'>
                {val.title}
              </Text>
              <Text>{val.subtitle}</Text>
            </Stack>
          ))}
          <Box>
            <form onSubmit={handleSubmit}>
              <InputGroup position='relative'>
                <input
                  type='file'
                  accept='.pdf,.jpg,.jpeg,.png'
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  ref={inputRef}
                />
                <Input
                  variant='outline'
                  isReadOnly
                  placeholder='Adjuntar comprobante'
                  width={{ base: 260, '2xl': 300 }}
                  pr={10}
                  cursor='pointer'
                  value={inputRef.current?.files[0]?.name || ''}
                  onClick={() => inputRef.current?.click()}
                />

                <InputRightElement
                  onClick={() => inputRef.current?.click()}
                  position='absolute'
                  left={{ base: 215, '2xl': 255 }}
                >
                  <NucUpload iconColor='#CBD5E0' />
                </InputRightElement>
              </InputGroup>
              <Button
                size='sm'
                fontSize='lg'
                type='submit'
                disabled={!file}
                isLoading={isUploadingReceipt}
                mt={4}
              >
                Enviar
              </Button>
            </form>
          </Box>
        </Stack>
      </Stack>
    </ContentTemplate>
  )
}
