import { DateTime } from '@/shared/helpers'
import { CohortProduct } from '@/shared/types'

export const serializeProductCohort = (_cohort: CohortProduct) => {
  const { days, startDate, ...cohort } = _cohort

  let extendedDays = ''
  if (days.includes(0) && days.includes(2)) {
    extendedDays = 'Lunes y Miércoles - 19:30hs a 21:30hs'
  } else if (days.includes(1) && days.includes(3)) {
    extendedDays = 'Martes y Jueves - 19:30hs a 21:30hs'
  } else if (days.includes(1)) {
    extendedDays = 'Martes - 19:30hs a 21:30hs'
  } else if (days.includes(3)) {
    extendedDays = 'Jueves - 19:30hs a 21:30hs'
  }

  const startDateObj = DateTime.createFromString(startDate, false)
  const currentDate = DateTime.now()

  let startDesc = ''
  if (startDateObj.greaterThan(currentDate)) {
    startDesc = 'Inicia el '
  } else {
    startDesc = 'Inició el '
  }

  const date = startDateObj.formatDate({
    dateStyle: 'long',
  })

  return {
    ...cohort,
    days: extendedDays,
    startDate: date,
    fullDesc: `#${cohort.code} - ${startDesc}${date} | ${extendedDays}`,
  }
}
