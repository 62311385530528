import { createApi } from '@reduxjs/toolkit/query/react'
import { gql } from 'graphql-request'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'

import { CACHE_TIME } from '@/shared/constants'
import { CohortClassStatus } from '@/shared/enums'
import { RootState } from '@/shared/app/store'

type GetExercisesByCourseResponse = {
  cohort_classes: {
    id: string
    status: CohortClassStatus
    date: Date
    class: {
      exercises: {
        id: string
        name: string
      }[]
    }
  }[]
}

type GetExerciseDetailsResponse = {
  exercises: {
    name: string
    description: string
    resources: string[]
    solution: string
    class: {
      course: {
        name: string
      }
    }
    users_assignments_aggregate: {
      aggregate: {
        count: number
      }
    }
    users_assignments: { id: string }[]
  }[]
}

type GetExerciseDetailsTransformResponse = {
  name: string
  description: string
  resources: string[]
  solution: string
  class: {
    course: {
      name: string
    }
  }
  corrected: number
  submitted: number
}

export const exerciseApi = createApi({
  reducerPath: 'exerciseApi',
  baseQuery: graphqlRequestBaseQuery({
    url: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).session.token

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  keepUnusedDataFor: CACHE_TIME,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: ['Exercise'],
  endpoints: builder => ({
    getExercisesByCourse: builder.query<
      GetExercisesByCourseResponse,
      { code: string; courseId: string }
    >({
      query: ({ code, courseId }) => ({
        document: gql`
          query ExercisesByCourse($code: String, $courseId: String) {
            cohort_classes(
              where: {
                cohort: { code: { _eq: $code } }
                class: { exercises: {}, courseId: { _eq: $courseId } }
              }
              order_by: { class: { order: asc } }
            ) {
              id
              status
              date
              class {
                exercises {
                  id
                  name
                }
              }
            }
          }
        `,
        variables: {
          code,
          courseId,
        },
      }),
      providesTags: ['Exercise'],
    }),
    getExerciseDetails: builder.query<
      GetExerciseDetailsTransformResponse,
      { exerciseId: string; code: string }
    >({
      query: ({ exerciseId, code }) => ({
        document: gql`
          query ExerciseDetails($exerciseId: String, $code: String) {
            exercises(where: { id: { _eq: $exerciseId } }) {
              name
              description
              resources
              solution
              class {
                course {
                  name
                }
              }
              users_assignments_aggregate(
                where: {
                  cohort: { code: { _eq: $code } }
                  status: { _eq: "APROBADA" }
                }
              ) {
                aggregate {
                  count(columns: status)
                }
              }
              users_assignments(
                where: {
                  cohort: { code: { _eq: $code } }
                  status: { _eq: "ENTREGADA" }
                }
              ) {
                id
              }
            }
          }
        `,
        variables: {
          exerciseId,
          code,
        },
      }),
      providesTags: ['Exercise'],
      transformResponse: (data: GetExerciseDetailsResponse) => ({
        ...data.exercises[0],
        submitted:
          data.exercises[0].users_assignments.length +
          data.exercises[0].users_assignments_aggregate.aggregate.count,
        corrected: data.exercises[0].users_assignments.length,
      }),
    }),
  }),
})

export const {
  useGetExercisesByCourseQuery,
  useGetExerciseDetailsQuery,
  usePrefetch: useExercisePrefetch,
} = exerciseApi
