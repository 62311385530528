import { createApi } from '@reduxjs/toolkit/query/react'
import { gql } from 'graphql-request'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { CONTRACT_STATUS } from '@/shared/types'
import { RootState } from '@/shared/app/store'
import { CACHE_TIME } from '@/shared/constants'

type GetPendingContractsResponse = {
  users: {
    contracts: {
      id: string
      subscriptionId: string
      status: CONTRACT_STATUS
      userId: string
    }[]
  }[]
}

type GetPendingContractsTransformResponse = {
  contracts: {
    id: string
    subscriptionId: string
    status: CONTRACT_STATUS
    userId: string
  }[]
}

type GetSubscriptionContractResponse = {
  contracts: {
    id: string
    createdAt: Date
    signatureUrl: string
    signedAt: Date
    signedFromIp: number
    viewedAt: Date
    status: CONTRACT_STATUS
    subscription: {
      totalAmount: number
      duration: string
      finishAt: Date
      createdAt: Date
      paymentMethods: string[]
      plan: {
        name: string
        installments: number
        price: number
        currency: string
        product: {
          id: string
          name: string
        }
      }
      cohortId: string
      user: {
        id: string
        profiles: {
          firstname: string
          lastname: string
          document: number
          addresses: {
            province: string
            city: string
            locality: string
            street: string
            number: string
            postalCode: string
          }[]
        }[]
      }
    }
  }[]
}

export type GetSubscriptionContractTransformResponse =
  | GetSubscriptionContractResponse['contracts'][number]
  | undefined

type GetContractAuditResponse = {
  contracts: {
    id: string
    status: CONTRACT_STATUS
    signedAt: Date
    signedFromIp: string
    createdAt: Date
    viewedAt: Date
    user: {
      email: string
      profiles: {
        firstname: string
        lastname: string
        document: string
      }[]
    }
  }[]
}

type GetContractAuditTransformResponse =
  | GetContractAuditResponse['contracts'][number]
  | undefined

type GetDuoPendingContractsResponse = {
  duos: {
    subscriptionOwner: string
    subscription: {
      contracts: {
        id: string
        status: CONTRACT_STATUS
      }[]
    }
  }[]
}

type GetDuoPendingContractsTransformResponse =
  | GetDuoPendingContractsResponse['duos'][number]
  | undefined

export const contractQueryApi = createApi({
  reducerPath: 'contractQueryApi',
  baseQuery: graphqlRequestBaseQuery({
    url: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).session.token

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  keepUnusedDataFor: CACHE_TIME,
  endpoints: builder => ({
    GetPendingContracts: builder.query<
      GetPendingContractsTransformResponse,
      string
    >({
      query: id => ({
        document: gql`
          query GetPendingContracts($id: String_comparison_exp) {
            users(where: { id: $id }) {
              contracts(limit: 1, where: { status: { _eq: "PENDIENTE" } }) {
                id
                subscriptionId
                status
                userId
              }
            }
          }
        `,
        variables: {
          id: {
            _eq: id,
          },
        },
      }),
      transformResponse: (data: GetPendingContractsResponse) => ({
        contracts: data.users[0]?.contracts || [],
      }),
    }),
    getSubscriptionContract: builder.query<
      GetSubscriptionContractTransformResponse,
      string
    >({
      query: subscriptionId => ({
        document: gql`
          query GetSubscriptionContract($subscriptionId: String) {
            contracts(
              where: {
                subscriptionId: { _eq: $subscriptionId }
                status: { _eq: "PENDIENTE" }
              }
            ) {
              id
              createdAt
              signatureUrl
              signedAt
              signedFromIp
              status
              viewedAt
              subscription {
                totalAmount
                duration
                finishAt
                createdAt
                paymentMethods
                plan {
                  name
                  installments
                  price
                  currency
                  product {
                    id
                    name
                  }
                }
                cohortId
                user {
                  id
                  profiles {
                    firstname
                    lastname
                    document
                    addresses {
                      province
                      city
                      locality
                      street
                      number
                      postalCode
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          subscriptionId,
        },
      }),
      transformResponse: (data: GetSubscriptionContractResponse) =>
        data.contracts.at(0),
    }),
    GetContractAudit: builder.query<GetContractAuditTransformResponse, string>({
      query: contractId => ({
        document: gql`
          query GetContractAudit($contractId: String) {
            contracts(where: { id: { _eq: $contractId } }) {
              id
              status
              signedAt
              signedFromIp
              createdAt
              viewedAt
              user {
                email
                profiles {
                  firstname
                  lastname
                  document
                }
              }
            }
          }
        `,
        variables: {
          contractId,
        },
      }),
      transformResponse: (data: GetContractAuditResponse) =>
        data.contracts.at(0),
    }),
    getDuoPendingContracts: builder.query<
      GetDuoPendingContractsTransformResponse,
      { duoId: string }
    >({
      query: ({ duoId }) => ({
        document: gql`
          query GetDuoPendingContracts($duoId: String) {
            duos(where: { id: { _eq: $duoId } }) {
              subscriptionOwner
              subscription {
                contracts {
                  id
                  status
                }
              }
            }
          }
        `,
        variables: {
          duoId,
        },
      }),
      transformResponse: (data: GetDuoPendingContractsResponse) =>
        data.duos.at(0),
    }),
  }),
})

export const {
  useGetPendingContractsQuery,
  useGetSubscriptionContractQuery,
  useGetContractAuditQuery,
  useGetDuoPendingContractsQuery,
  usePrefetch: useContractPrefetch,
} = contractQueryApi
