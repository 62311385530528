import { createApi } from '@reduxjs/toolkit/query/react'
import { gql } from 'graphql-request'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { CACHE_TIME } from '@/shared/constants'
import { RootState } from '@/shared/app/store'

type GetUserCertificatesResponse = {
  users: {
    Certificates: {
      id: string
      createdAt: Date
      type: 'PARTIAL' | 'INTEGRATIVE'
      course: {
        name: string
        color: string
      }
      product: {
        name: string
      }
      user: {
        profiles: {
          firstname: string
          lastname: string
        }[]
      }
    }[]
  }[]
}

type GetUserCertificatesTransformResponse = {
  Certificates: {
    id: string
    createdAt: Date
    type: 'PARTIAL' | 'INTEGRATIVE'
    course: {
      name: string
      color: string
    }
    product: {
      name: string
    }
    user: {
      profiles: {
        firstname: string
        lastname: string
      }[]
    }
  }[]
}

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: graphqlRequestBaseQuery({
    url: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).session.token

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  keepUnusedDataFor: CACHE_TIME,
  endpoints: builder => ({
    getUserCertificates: builder.query<
      GetUserCertificatesTransformResponse,
      string
    >({
      query: id => ({
        document: gql`
          query CertificatesDetails($id: String_comparison_exp) {
            users(where: { id: $id }) {
              Certificates(order_by: { course: { order: asc } }) {
                id
                createdAt
                type
                course {
                  name
                  color
                }
                product {
                  name
                }
                user {
                  profiles {
                    firstname
                    lastname
                  }
                }
              }
            }
          }
        `,
        variables: {
          id: {
            _eq: id,
          },
        },
      }),
      transformResponse: (data: GetUserCertificatesResponse) => data.users[0],
    }),
  }),
})

export const { useGetUserCertificatesQuery } = profileApi
