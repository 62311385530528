import React from 'react'

import { HStack, Skeleton } from '@chakra-ui/react'

import { generateArrayWithRandomKeys } from '@/shared/utils'

export const ProductsOptsPopoverLoader = () => {
  return (
    <HStack>
      {generateArrayWithRandomKeys(1).map(i => (
        <Skeleton
          key={i}
          w='90%'
          borderRadius={30}
          paddingInline={5}
          paddingBlock={4}
        />
      ))}
    </HStack>
  )
}
