import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Stack, Text } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { FormInput } from '../../components/FormInput'
import { ProgressBarWithLabel } from '../../components/ProgressBarWithLabel'
import { SubmitButton } from '../../components/SubmitButton'

import type { Input } from '@/shared/types'
import { useErrorMessage } from '@/shared/hooks'
import {
  StepOneReq,
  useRecoverStepOneMutation,
} from '@/auth/services/auth.service'

const inputs: Input[] = [
  {
    name: 'email',
    label: 'Ingresa tu correo electrónico',
    placeholder: 'micorreo@gmail.com',
  },
]

const initialValues: StepOneReq = {
  email: '',
}

const validationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email('Email inválido')
    .required('Campo requerido'),
})

export const StepOne = () => {
  const navigate = useNavigate()

  const [recoverStepOne, { error }] = useRecoverStepOneMutation()

  useErrorMessage(error)

  const handleSubmit = async (values: StepOneReq) => {
    const { email } = values
    const res = await recoverStepOne({ email: email.toLowerCase().trim() })

    if (!('error' in res)) {
      const { expiresAt } = res.data.data

      return navigate('/auth/cambiar-password/paso-dos', {
        state: {
          from: 'stepOne',
          expiresAt,
          email: email.toLowerCase().trim(),
        },
        replace: true,
      })
    }
  }

  return (
    <Container centerContent maxW='container.md'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors, dirty }) => (
          <Form>
            <Stack h='100vh' justify='center' align='center' spacing={20}>
              <ProgressBarWithLabel
                label='Ingresa tu correo electrónico'
                value={33}
              />
              <Stack>
                {inputs.map(input => (
                  <FormInput key={input.name} {...input} />
                ))}
              </Stack>
              <Stack align='center' spacing={8}>
                <Text
                  maxW={{ base: 200, sm: 500 }}
                  fontSize='sm'
                  textAlign='center'
                  color='nucba.white-70'
                >
                  Te enviaremos un código a este correo para que puedas
                  continuar
                </Text>
                <SubmitButton
                  w={220}
                  textTransform='uppercase'
                  errors={errors}
                  dirty={dirty}
                  isLoading={isSubmitting}
                >
                  Siguiente
                </SubmitButton>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Container>
  )
}
