import React, { ReactNode } from 'react'
import { Link } from '@chakra-ui/react'
import Linkify from 'react-linkify'

type LinkifyWrapperProps = {
  children: ReactNode

  /**@default #3cdddc */
  linkColor?: string
}

export const LinkifyWrapper = ({
  children,
  linkColor = '#3cdddc',
}: LinkifyWrapperProps) => {
  return (
    <Linkify
      componentDecorator={(decoratedText: string) => (
        <Link
          key={decoratedText}
          color={linkColor}
          target='blank'
          rel='noopener noreferrer'
          href={decoratedText}
        >
          {decoratedText}
        </Link>
      )}
    >
      {children}
    </Linkify>
  )
}
