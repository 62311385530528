import React from 'react'
import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { Box, Container } from '@chakra-ui/react'
import { Slider } from '@nucba-coding/nucba-ui'

import { Isologo } from '@/shared/components/Isologo'
import { Logo } from '@/shared/components/Logo'

export const InscriptionLayoutWithSR = () => {
  return (
    <>
      <InscriptionLayout />
      <ScrollRestoration />
    </>
  )
}

const InscriptionLayout = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const loginPage = pathname.includes('ingresar-cuenta')
  const plansPage = pathname.includes('inscripcion/')

  const pagesWithContainer =
    !pathname.includes('datos-personales') &&
    !pathname.includes('TyC') &&
    !pathname.includes('inscripcion/') &&
    !loginPage

  if (pagesWithContainer) {
    return (
      <Container maxW='8xl'>
        <Box mb={28}>
          <Outlet />
        </Box>
      </Container>
    )
  }

  if (plansPage) {
    return (
      <Container maxW='8xl'>
        <Box
          pos='relative'
          my={2}
          onClick={() => {
            navigate('/inscripcion')
          }}
        >
          <Isologo size={70} />
        </Box>
        <Box mb={28}>
          <Outlet />
        </Box>
      </Container>
    )
  }

  if (loginPage) {
    return (
      <>
        <Box pos='absolute' top={1} left={8}>
          <Isologo />
        </Box>

        <Box pos='absolute' top={8} right={8}>
          <Slider
            titles={[
              'Educación Competitiva',
              'Cursada en vivo',
              'Contenido variado',
              'Sistema de entregas',
            ]}
          />
        </Box>

        <Container centerContent maxW='container.md'>
          <Outlet />
        </Container>

        <Box pos='absolute' bottom={3} right={8}>
          <Logo />
        </Box>
      </>
    )
  }

  return <Outlet />
}
