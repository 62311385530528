import { RootState } from '@/shared/app/store'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

type ClaimGithubEducationLinkResponse = {
  link: string
}

export const userMutationApi = createApi({
  reducerPath: 'userMutationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/usuarios`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).session.token

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: builder => ({
    githubEducationLink: builder.query<ClaimGithubEducationLinkResponse, null>({
      query: () => ({
        url: `/github-education`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGithubEducationLinkQuery } = userMutationApi
