import { createApi } from '@reduxjs/toolkit/query/react'
import { gql } from 'graphql-request'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'

import { Currencies, SUBSCRIPTION_ADDITIONAL_TYPES } from '@/shared/types'
import { RootState } from '@/shared/app/store'
import { CACHE_TIME } from '@/shared/constants'

type GetInvoiceDetailResponse = {
  invoices: {
    id: string
    amount: number
    dueDate: string
    additionalId: string
    additional: {
      type: SUBSCRIPTION_ADDITIONAL_TYPES
      installments: number
    }
    subscription: {
      invoices: {
        id: string
        additionalId: string
      }[]
      plan: {
        installments: number
        currency: Currencies
      }
    }
  }[]
}

type GetInvoiceDetailTransformResponse =
  | GetInvoiceDetailResponse['invoices'][number]
  | undefined

type GetUserOpenTransferInvoiceResponse = {
  invoices: {
    id: string
    dueDate: string
    subscriptionId: string
  }[]
}

type GetUserOpenTransferInvoiceTransformResponse =
  | GetUserOpenTransferInvoiceResponse['invoices'][number]
  | undefined

export const billingQueryApi = createApi({
  reducerPath: 'billingQueryApi',
  baseQuery: graphqlRequestBaseQuery({
    url: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).session.token

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  keepUnusedDataFor: CACHE_TIME,
  tagTypes: ['OpenInvoice'],
  endpoints: builder => ({
    getInvoiceDetail: builder.query<GetInvoiceDetailTransformResponse, string>({
      query: invoiceId => ({
        document: gql`
          query InvoiceDetail($invoiceId: String) {
            invoices(where: { id: { _eq: $invoiceId } }) {
              id
              amount
              dueDate
              additionalId
              additional: subscriptions_additional {
                type
                installments
              }
              subscription {
                invoices {
                  id
                  additionalId
                }
                plan {
                  installments
                  currency
                }
              }
            }
          }
        `,
        variables: {
          invoiceId,
        },
      }),
      transformResponse: (data: GetInvoiceDetailResponse) =>
        data.invoices.at(0),
    }),
    getUserOpenTransferInvoice: builder.query<
      GetUserOpenTransferInvoiceTransformResponse,
      string
    >({
      query: userId => ({
        document: gql`
          query UserOpenTransferInvoice($userId: String) {
            invoices(
              where: {
                subscription: { userId: { _eq: $userId } }
                status: { _eq: "OPEN" }
                paymentMethod: { _eq: "TRANSFER" }
              }
              limit: 1
              order_by: { createdAt: asc }
            ) {
              id
              dueDate
              subscriptionId
            }
          }
        `,
        variables: {
          userId,
        },
      }),
      transformResponse: (data: GetUserOpenTransferInvoiceResponse) =>
        data.invoices.at(0),
      providesTags: ['OpenInvoice'],
    }),
  }),
})

export const { useGetInvoiceDetailQuery, useGetUserOpenTransferInvoiceQuery } =
  billingQueryApi
