import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { RootState } from '@/shared/app/store'
import { useAppSelector } from '@/shared/app/hooks'
import {
  CohortProduct,
  Currencies,
  PAYMENT_METHODS_TYPES,
  PLANS_TYPES,
  PRODUCTS_TYPES,
} from '@/shared/types'
import {
  ValidateCouponReq,
  inscriptionApi,
} from '../services/inscription.service'

export interface CartState {
  product: {
    id: string
    name: string
    type: PRODUCTS_TYPES
  } | null
  paymentMethod: {
    id: string
    name: string
    type: PAYMENT_METHODS_TYPES
    policy: string
  } | null
  coupon: {
    valid: boolean | 'pending'
    code: string
    discount: number
  } | null
  plan: {
    id: string
    name: string
    price: number
    installments: number
    currency: Currencies
    type: PLANS_TYPES
    isDuo: boolean
    paymentMethod: {
      name: string
    } | null
  } | null
  campaign: {
    name: string
    discount: number
  } | null
  cohort: CohortProduct | null
  checkoutLink: string | null
}

const initialState: CartState = {
  product: null,
  cohort: null,
  paymentMethod: null,
  campaign: null,
  plan: null,
  coupon: null,
  checkoutLink: null,
}

interface PlanSelectedPayload {
  id: string
  name: string
  installments: number
  duration: number
  currency: Currencies
  price: number
  type: PLANS_TYPES
  isDuo: boolean
  paymentMethod: {
    name: string
  } | null
}

type ProductSelected = Pick<CartState, 'product'>['product']
type CohortSelected = Pick<CartState, 'cohort'>
type PaymentMethodSelected = Pick<CartState, 'paymentMethod'>['paymentMethod']
type CampaignSelected = Pick<CartState, 'campaign'>
type CouponAdded = Pick<CartState, 'coupon'>
type CheckoutLinkAdded = Pick<CartState, 'checkoutLink'>

type UpdateCart = Partial<CartState>

export const validateCouponAsync = createAsyncThunk(
  'cart/validateCouponAsync',
  async (
    { code, planId }: ValidateCouponReq,
    { dispatch, getState },
  ): Promise<boolean> => {
    const state = getState() as RootState
    if (!state.cart.coupon) return false
    try {
      const res = (await dispatch(
        inscriptionApi.endpoints.validateCoupon.initiate({ code, planId }),
      )) as any
      return Boolean(res?.data)
    } catch (error) {
      return false
    }
  },
)

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    productSelected(state, action: PayloadAction<ProductSelected>) {
      return { ...state, product: action.payload }
    },
    updateCart(state, action: PayloadAction<UpdateCart>) {
      return { ...state, ...action.payload }
    },
    cohortSelected(state, action: PayloadAction<CohortSelected>) {
      return { ...state, ...action.payload }
    },
    clearCohort(state) {
      return { ...state, cohort: null }
    },
    paymentMethodSelected(state, action: PayloadAction<PaymentMethodSelected>) {
      return { ...state, paymentMethod: action.payload }
    },
    campaignSelected(state, action: PayloadAction<CampaignSelected>) {
      return { ...state, ...action.payload }
    },
    planSelected(state, action: PayloadAction<PlanSelectedPayload>) {
      return {
        ...state,
        plan: action.payload,
      }
    },
    couponAdded(state, action: PayloadAction<CouponAdded>) {
      return { ...state, ...action.payload }
    },
    couponRemoved(state) {
      return { ...state, coupon: null }
    },
    checkoutLinkAdded(state, action: PayloadAction<CheckoutLinkAdded>) {
      return { ...state, ...action.payload }
    },
    clearCart() {
      return initialState
    },
  },
  extraReducers: builder => {
    builder.addCase(validateCouponAsync.pending, state => {
      if (state.coupon) {
        return { ...state, coupon: { ...state.coupon, valid: 'pending' } }
      }
    }),
      builder.addCase(validateCouponAsync.fulfilled, (state, action) => {
        if (state.coupon) {
          return {
            ...state,
            coupon: { ...state.coupon, valid: action.payload },
          }
        }
      })
  },
})

export const cartState = (state: RootState): CartState => state.cart
export const useCartState = () => useAppSelector(cartState)

export const {
  productSelected,
  updateCart,
  cohortSelected,
  clearCohort,
  planSelected,
  paymentMethodSelected,
  campaignSelected,
  couponAdded,
  couponRemoved,
  checkoutLinkAdded,
  clearCart,
} = cartSlice.actions
export default cartSlice.reducer
