import React from 'react'
import { useParams } from 'react-router-dom'
import { HStack, Text } from '@chakra-ui/react'

import { SectionContainerWithScroll } from '@/education/shared/components/SectionContainerWithScroll'
import { ContentTemplate } from '@/education/shared/template/ContentTemplate'
import { ExerciseResources } from '../components/ExerciseResources'
import { useGetExerciseDetailsQuery } from '../services/exercise.service'
import { ExerciseDetailsLoader } from '@/education/shared/components/loaders/ExerciseDetailsLoader'
import { ModalVideoPlayer } from '@/shared/components/ModalVideoPlayer'
import { LinkifyWrapper } from '@/shared/components/LinkifyWrapper'

export const ExerciseDetails = () => {
  const { code, exerciseId } = useParams()

  const { data, isLoading } = useGetExerciseDetailsQuery({
    code: code!,
    exerciseId: exerciseId!,
  })

  return (
    <ContentTemplate h='45vh'>
      <SectionContainerWithScroll h='100% !important' overflowY='unset'>
        {isLoading ? (
          <ExerciseDetailsLoader />
        ) : (
          <>
            <LinkifyWrapper>
              <Text whiteSpace='pre-wrap'>{data?.description}</Text>
            </LinkifyWrapper>
            {!!data?.resources.length && (
              <>
                <Text as='h4' fontSize='xl' fontWeight='bold' lineHeight={2}>
                  Recursos:
                </Text>
                <ExerciseResources resources={data?.resources} />
              </>
            )}
            {data?.solution && (
              <>
                <Text as='h4' fontSize='xl' fontWeight='bold' lineHeight={2}>
                  Solución:
                </Text>
                <HStack pb={20}>
                  <Text as='span'>👉</Text>
                  <ModalVideoPlayer
                    videoId={data?.solution || ''}
                    text='VER VIDEO RESUELTO'
                  />
                </HStack>
              </>
            )}
          </>
        )}
      </SectionContainerWithScroll>
    </ContentTemplate>
  )
}
