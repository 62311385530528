import { RootState } from '@/shared/app/store'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

type RegisterReq = {
  email: string
  password: string
  profile: {
    firstname: string
    lastname: string
    document: string
    phone: string
    birthdate: Date
    address: {
      country: string
      province: string
      city: string
      locality: string
      street: string
      number: string
      postalCode: string
    }
  }
}

type RegisterRes = {
  token: string
}

type RegisterDuoReq = {
  email: string
  password: string
  token: string
  profile: {
    firstname: string
    lastname: string
    document: string
    phone: string
    birthdate: Date
    address: {
      country: string
      province: string
      city: string
      locality: string
      street: string
      number: string
      postalCode: string
    }
  }
}

type RegisterDuoRes = {
  token: string
}

type ConfirmRegisterReq = {
  tyc: boolean
  instagram?: string
  twitter?: string
}

type ConfirmRegisterRes = {
  message: string
}

export type ValidateCouponReq = {
  code: string
  planId: string
}

export type ValidateCouponRes = {
  success: boolean
  message: string
  data: {
    name: string
    discount: number
  }
}

export type ValidateEmailRes = {
  message: string
}

type CreateSubscriptionReq = {
  planId: string
  cohortId: string
  coupon?: string
  invitationEmail?: string
}

type CreateSubscriptionRes = {
  checkout_link: string
}

export const inscriptionApi = createApi({
  reducerPath: 'inscriptionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).session.token

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: builder => ({
    register: builder.mutation<RegisterRes, RegisterReq>({
      query: body => ({
        url: '/auth/registro',
        method: 'POST',
        body,
      }),
    }),
    registerDuo: builder.mutation<RegisterDuoRes, RegisterDuoReq>({
      query: body => ({
        url: '/auth/registro-duo',
        method: 'POST',
        body,
      }),
    }),
    confirmRegister: builder.mutation<ConfirmRegisterRes, ConfirmRegisterReq>({
      query: body => ({
        url: '/auth/confirmar-registro',
        method: 'POST',
        body,
      }),
    }),
    validateCoupon: builder.mutation<ValidateCouponRes, ValidateCouponReq>({
      query: body => ({
        url: '/cupones/validar-cupon',
        method: 'POST',
        body,
      }),
    }),
    validateEmail: builder.mutation<ValidateEmailRes, { email: string }>({
      query: body => ({
        url: '/auth/verificar-disponibilidad-email',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/suscripciones`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).session.token

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: builder => ({
    createSubscription: builder.mutation<
      CreateSubscriptionRes,
      CreateSubscriptionReq
    >({
      query: body => ({
        url: '',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useRegisterMutation,
  useRegisterDuoMutation,
  useValidateCouponMutation,
  useConfirmRegisterMutation,
  useValidateEmailMutation,
} = inscriptionApi

export const { useCreateSubscriptionMutation } = subscriptionApi
