import React from 'react'
import { ProfileActions } from '../components/ProfileActions'
import { ProfileHero } from '../components/ProfileHero'

export const Profile = () => {
  return (
    <>
      <ProfileHero />
      <ProfileActions />
    </>
  )
}
