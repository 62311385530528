import daysjs from 'dayjs'
import utc from 'dayjs/plugin/utc.js'

import { isValidDate } from '../guards'
import { DEFAULT_FORMAT_DATE_OPTIONS } from '../constants'

export class DateTime {
  public date: Date

  private constructor(date: Date, useUTC = true) {
    daysjs.extend(utc)
    this.date = daysjs(date).utc(useUTC).toDate()
  }

  static createFromDate(date: Date, useUTC?: boolean): DateTime {
    const isValid = isValidDate(date)

    if (!isValid) return this.now()

    return new DateTime(date, useUTC)
  }

  static createFromString(dateStr: string, useUTC?: boolean): DateTime {
    const date = daysjs(dateStr).toDate()
    return DateTime.createFromDate(date, useUTC)
  }

  static nowUTC(): DateTime {
    const zeroTimezone = new Date().toISOString().split('Z')[0]

    const date = daysjs(zeroTimezone).utc(false).toDate()

    return DateTime.createFromDate(date, false)
  }

  static now(): DateTime {
    return new DateTime(new Date(), false)
  }

  static today(): DateTime {
    const today = daysjs().startOf('day').toDate()

    return new DateTime(today, false)
  }

  getDate(): Date {
    return this.date
  }

  getHours() {
    return this.date.getHours()
  }

  greaterThan(d: DateTime): boolean {
    return this.date > d.date
  }

  greaterThanOrEqual(d: DateTime): boolean {
    return this.date >= d.date
  }

  lowerThan(d: DateTime): boolean {
    return this.date < d.date
  }

  lowerThanOrEqual(d: DateTime): boolean {
    return this.date <= d.date
  }

  dayDiff(d: DateTime): number {
    const d1 = daysjs(this.date).utc().startOf('day')
    const d2 = daysjs(d.date).utc().startOf('day')

    return d1.diff(d2, 'day')
  }

  addDays(days: number): DateTime {
    const date = daysjs(this.date).utc()
    return DateTime.createFromDate(date.add(days, 'day').toDate(), false)
  }

  substractDays(days: number): DateTime {
    const date = daysjs(this.date).utc()
    return DateTime.createFromDate(date.subtract(days, 'day').toDate(), false)
  }

  formatDate(
    opts: Intl.DateTimeFormatOptions = DEFAULT_FORMAT_DATE_OPTIONS,
  ): string {
    return new Intl.DateTimeFormat('es', opts).format(this.date)
  }
}
