import React from 'react'
import { Heading, HeadingProps } from '@chakra-ui/react'

type PageHeadingProps = {
  title: string
} & HeadingProps

export const PageHeading = ({ title, ...props }: PageHeadingProps) => {
  return (
    <Heading
      as='h1'
      fontSize={{ base: '3xl', lg: '4xl' }}
      fontWeight={600}
      {...props}
    >
      {title}
    </Heading>
  )
}
