import React from 'react'

import { Box, FormLabel, HStack, Stack, Tooltip } from '@chakra-ui/react'

import { FormInput } from '@/auth/components/FormInput'
import { couponAdded, useCartState } from '@/inscription/features/cart.slice'
import { useValidateCouponMutation } from '@/inscription/services/inscription.service'
import { useAppDispatch } from '@/shared/app/hooks'
import { useErrorMessage } from '@/shared/hooks'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { SubmitButton } from '../SubmitButton'
import { useSearchParams } from 'react-router-dom'
import { useDiscountOnLoad } from '@/inscription/hooks'

const initialValues = {
  code: '',
}

const validationSchema = Yup.object({
  code: Yup.string(),
})

export const Discount = () => {
  const { plan, coupon } = useCartState()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()

  const couponOnQueryParam = searchParams.get('cuponcin')
  useDiscountOnLoad(couponOnQueryParam)

  const [
    validateCoupon,
    { error: validateCouponError, isLoading: validateCouponIsLoading },
  ] = useValidateCouponMutation()
  useErrorMessage(validateCouponError)

  const handleSubmit = async (
    values: typeof initialValues,
    { resetForm }: { resetForm: () => void },
  ) => {
    const { code } = values
    const upperCaseCode = code.toUpperCase().trim()

    const res = await validateCoupon({
      code: upperCaseCode,
      planId: plan?.id || '',
    })

    if (!('error' in res)) {
      dispatch(
        couponAdded({
          coupon: {
            code: upperCaseCode,
            discount: res.data.data.discount,
            valid: res.data.success,
          },
        }),
      )
      resetForm()
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => handleSubmit(values, { resetForm })}
    >
      {({ dirty, errors }) => (
        <Stack
          width={{ base: '80%', md: '60%' }}
          alignSelf={{ base: 'center', md: 'end' }}
        >
          <Form>
            <HStack
              display='flex'
              align='end'
              justify={{ base: 'normal', md: 'space-between' }}
              gap={{ base: 4, md: 0 }}
              flexWrap='wrap'
            >
              <Stack spacing={0}>
                <FormLabel
                  mb={0}
                  fontSize={{ base: 'xs', md: 'sm' }}
                  fontWeight={400}
                >
                  Agregar cupón de descuento
                </FormLabel>
                <FormInput
                  inputProps={{ width: { base: 200, xl: 240 } }}
                  name='code'
                  label=''
                  placeholder='Ingresar descuento'
                  isDisabled={!!coupon}
                />
              </Stack>

              {coupon ? (
                <Tooltip
                  placement='top-end'
                  label='Ya hay un cupón activo'
                  bg='transparent'
                  color='white'
                  fontWeight='bold'
                  fontSize='12px'
                >
                  <Box>
                    <SubmitButton
                      errors={errors}
                      dirty={dirty}
                      isLoading={validateCouponIsLoading}
                      disabled={!plan || !!coupon}
                      fontSize={{ base: 'sm', md: 'lg' }}
                      p={{ base: 3, md: 4 }}
                      mt={4}
                      size='xs'
                    >
                      Aplicar
                    </SubmitButton>
                  </Box>
                </Tooltip>
              ) : (
                <SubmitButton
                  errors={errors}
                  dirty={dirty}
                  isLoading={validateCouponIsLoading}
                  disabled={!plan || !!coupon}
                  fontSize={{ base: 'sm', md: 'lg' }}
                  p={{ base: 3, md: 4 }}
                  mt={4}
                  size='xs'
                >
                  Aplicar
                </SubmitButton>
              )}
            </HStack>
          </Form>
        </Stack>
      )}
    </Formik>
  )
}
