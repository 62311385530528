import React from 'react'
import { GridItem, Skeleton } from '@chakra-ui/react'
import { Card } from '@nucba-coding/nucba-ui'

import { generateArrayWithRandomKeys } from '@/shared/utils'

export const CohortCoursesLoader = () => {
  return (
    <>
      {generateArrayWithRandomKeys(4).map(li => (
        <Skeleton key={li} borderRadius={23}>
          <GridItem>
            <Card title='' bgImage='' w='auto' h={{ base: 260, '2xl': 400 }} />
          </GridItem>
        </Skeleton>
      ))}
    </>
  )
}
