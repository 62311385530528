import { Image } from '@chakra-ui/react'
import React from 'react'

export const Logo = () => {
  return (
    <Image
      width={150}
      src='https://res.cloudinary.com/dcatzxqqf/image/upload/v1681409540/Galileo-Platform/Assets/LogoH_u5lav8_1_q5vksa.webp'
      alt='Nucba logo'
    />
  )
}
