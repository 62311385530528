import React from 'react'

import { ContentTemplate } from '@/education/shared/template/ContentTemplate'
import { ListOfAssignments } from '../components/ListOfAssignments'

export const Exercise = () => {
  return (
    <ContentTemplate>
      <ListOfAssignments />
    </ContentTemplate>
  )
}
