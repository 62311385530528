import React from 'react'
import { Skeleton, Text } from '@chakra-ui/react'

import { generateArrayWithRandomKeys } from '@/shared/utils'

export const ContractTextLoader = () => {
  return (
    <>
      {generateArrayWithRandomKeys(8).map(li => (
        <Skeleton key={li} marginBottom={2}>
          <Text title='' bgImage='' w='auto' h={20} />
        </Skeleton>
      ))}
    </>
  )
}
