import React from 'react'
import ReactDOM from 'react-dom/client'
import { ChakraProvider, createLocalStorageManager } from '@chakra-ui/react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { nucbaTheme } from '@nucba-coding/nucba-ui'
import { RouterProvider } from 'react-router-dom'

import '@fontsource/poppins'

import { store, persistedStore } from './shared/app/store'
import { router } from './routes/router'
import { tracker } from './shared/helpers'

tracker.init()

const colorModeManager = createLocalStorageManager('color-mode')
const setToDarkMode = colorModeManager.set('dark')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ChakraProvider
      resetCSS={true}
      theme={nucbaTheme}
      colorModeManager={{ ...colorModeManager, set: () => setToDarkMode }}
      portalZIndex={1_000_000_000}
    >
      <Provider store={store}>
        <PersistGate persistor={persistedStore}>
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </ChakraProvider>
  </React.StrictMode>,
)
