import React from 'react'
import { HStack, Skeleton, Stack } from '@chakra-ui/react'

import { generateArrayWithRandomKeys } from '@/shared/utils'

type HeroLoaderProps = {
  /**@default false */
  withItems?: boolean

  /**@default false */
  withStatus?: boolean
}

export const HeroLoader = ({
  withItems = false,
  withStatus = false,
}: HeroLoaderProps) => {
  return (
    <HStack w='95%' justify='space-between' align='flex-end'>
      <Stack spacing={5}>
        <Skeleton
          h={{ base: '30px', '2xl': '38px' }}
          w='400px'
          borderRadius={5}
        />
        <HStack spacing={5} alignItems='center'>
          <Skeleton
            h={{ base: '30px', '2xl': '38px' }}
            w='200px'
            borderRadius={30}
          />
          {withStatus && (
            <Skeleton
              h={{ base: '24px', '2xl': '32px' }}
              w='100px'
              borderRadius={5}
            />
          )}
        </HStack>
      </Stack>

      {withItems && (
        <Stack w='35%' maxW='550px' spacing={4}>
          {generateArrayWithRandomKeys(2).map(li => (
            <Stack key={li} alignItems='baseline' spacing={2}>
              <Skeleton
                w={{ base: '125px', '2xl': '175px' }}
                h='20px'
                borderRadius={5}
              />
              <Skeleton
                w={{ base: '200px', '2xl': '250px' }}
                h='20px'
                borderRadius={5}
              />
            </Stack>
          ))}
        </Stack>
      )}
    </HStack>
  )
}
