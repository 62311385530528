import React, { useState } from 'react'
import {
  Box,
  ButtonProps,
  HStack,
  Modal as ChakraModal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  StyleProps,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { Button } from '@nucba-coding/nucba-ui'

import { VideoPlayer } from './VideoPlayer'

const OverlayOne = () => (
  <ModalOverlay bg='blackAlpha.400' backdropFilter='blur(12px) ' />
)

type ModalVideoPlayerProps = {
  videoId: string
  text: string
  btnStyles?: StyleProps & ButtonProps
}

export const ModalVideoPlayer = ({ videoId, text }: ModalVideoPlayerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [overlay, setOverlay] = useState(<OverlayOne />)

  return (
    <>
      <HStack
        onClick={() => {
          setOverlay(<OverlayOne />)
          onOpen()
        }}
      >
        <Text
          textTransform='uppercase'
          cursor='pointer'
          _hover={{ textDecoration: 'underline' }}
        >
          {text}
        </Text>
      </HStack>

      <ChakraModal isCentered isOpen={isOpen} onClose={onClose}>
        {overlay}
        <ModalContent
          bg='nucba.background'
          maxW='50%'
          padding={4}
          borderRadius={15}
        >
          <ModalCloseButton />

          <Box marginTop={8}>
            <VideoPlayer videoId={videoId} width='600' />
          </Box>

          <ModalFooter m='0 auto' mt={1}>
            <Button fontSize='md' w='90%' borderRadius={30} onClick={onClose}>
              Salir del video
            </Button>
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  )
}
