import React from 'react'
import { Heading, Stack, Text } from '@chakra-ui/react'

import { useAppSelector } from '@/shared/app/hooks'
import { sessionState } from '@/shared/features/session.slice'
import { assignmentState } from '../features/assignment.slice'
import { AssignmentStatus } from '@/shared/enums'

export const EmptyChat = () => {
  const { isStaff } = useAppSelector(sessionState)
  const { status } = useAppSelector(assignmentState)

  const isExpired = status === AssignmentStatus.VENCIDA

  return (
    <Stack my='auto' textAlign='center' fontSize='xl'>
      <Heading fontSize='inherit' fontWeight={800}>
        {isExpired
          ? '¡NO SE INICIÓ LA CONVERSACIÓN!'
          : '¡TODAVÍA NO SE INICIO LA CONVERSACIÓN!'}
      </Heading>
      <Text fontSize='inherit' fontWeight={400}>
        {isStaff
          ? 'El estudiante no ha realizado la entrega'
          : isExpired
            ? 'La entrega ha vencido, el chat no está disponible 😐'
            : 'Primero debes hacer la entrega.'}
      </Text>
    </Stack>
  )
}
