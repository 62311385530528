import React from 'react'
import { Outlet } from 'react-router-dom'

import { AssignmentHero } from '@/education/assignments/components/AssignmentHero'

export const AssignmentLayout = () => {
  return (
    <>
      <AssignmentHero />
      <Outlet />
    </>
  )
}
