import React, { useRef, useState, useEffect } from 'react'
import { Container, HStack, Stack } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ProgressBarWithLabel } from '../../components/ProgressBarWithLabel'
import { TimeLeft } from '../../components/TimeLeft'
import { useRecoverStepTwoMutation } from '@/auth/services/auth.service'
import { useErrorMessage, useRedirect } from '@/shared/hooks'
import { Button, PinInput } from '@nucba-coding/nucba-ui'

const FIELDS = 6

export const StepTwo = () => {
  useRedirect('/auth/cambiar-password/paso-uno', 'from', 'expiresAt', 'email')

  const { state } = useLocation()
  const navigate = useNavigate()

  const [isInvalid, setIsInvalid] = useState(false)
  const refCode = useRef('')

  const [recoverStepTwo, { isLoading, error, isError }] =
    useRecoverStepTwoMutation()

  useErrorMessage(error)

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (isError) {
      setIsInvalid(true)

      timer = setTimeout(() => {
        setIsInvalid(false)
      }, 2500)
    }

    return () => clearTimeout(timer)
  }, [isError])

  const handleSubmit = async () => {
    const { current: code } = refCode
    const res = await recoverStepTwo({ code: +code, email: state?.email })

    if (!('error' in res) && res.data) {
      return navigate('/auth/cambiar-password/paso-tres', {
        state: {
          from: 'stepTwo',
          code,
          email: state.email,
        },
        replace: true,
      })
    }
  }

  return (
    <Container centerContent maxW='container.md'>
      <Stack h='100vh' justify='center' align='center' spacing={20}>
        <ProgressBarWithLabel label='Ingresa el código recibido' value={66} />
        <HStack spacing={5}>
          <PinInput
            fields={FIELDS}
            isInvalid={isInvalid}
            onChange={value => (refCode.current = value)}
          />
        </HStack>
        <Stack align='center' spacing={8}>
          <TimeLeft expiresAt={state?.expiresAt} />
          <Button
            type='submit'
            w={220}
            textTransform='uppercase'
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            Siguiente
          </Button>
        </Stack>
      </Stack>
    </Container>
  )
}
