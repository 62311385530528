import React from 'react'
import { HStack, Stack, Text, Button as ChakraButton } from '@chakra-ui/react'

import {
  INVOICE_STATUS,
  PAYMENT_METHODS_TYPES,
  SUBSCRIPTIONS_TYPES,
} from '@/shared/types'
import { DateTime } from '@/shared/helpers'
import { SUBSCRIPTIONS_STATUS_COLOR } from '@/shared/constants'

export const statusLabels: Record<SUBSCRIPTIONS_TYPES, string> = {
  [SUBSCRIPTIONS_TYPES.ACTIVE]: 'Activa',
  [SUBSCRIPTIONS_TYPES.PENDING]: 'Pendiente',
  [SUBSCRIPTIONS_TYPES.WAITING_PAYMENT_CONFIRMATION]:
    'Esperando confirmacion de pago',
  [SUBSCRIPTIONS_TYPES.CANCELLED]: 'Cancelada',
  [SUBSCRIPTIONS_TYPES.FINISHED]: 'Finalizada',
  [SUBSCRIPTIONS_TYPES.PAUSED]: 'Pausada',
}

type SubscriptionCardProps = {
  plan: {
    name: string
    product: { name: string }
    payment_method: { type: PAYMENT_METHODS_TYPES; name: string }
  }
  duos: { id: string; invitationEmail: string }[]
  invoice?: { id: string; status: INVOICE_STATUS }
  id: string
  status: SUBSCRIPTIONS_TYPES
  activatedAt?: string
  finishAt?: string
  checkoutLink: string
}

export const SubscriptionCard = ({
  plan,
  duos,
  ...sub
}: SubscriptionCardProps) => {
  return (
    <Stack
      w='100%'
      minW='600px'
      border='1px solid #ccc'
      borderRadius='md'
      p={4}
    >
      <Text fontWeight='bold' fontSize='xl'>
        {plan.product.name}
      </Text>
      <HStack>
        <Text>{plan.name}</Text>
      </HStack>
      <Text>
        Estado:{' '}
        <Text as='span' textColor={SUBSCRIPTIONS_STATUS_COLOR[sub.status]}>
          {statusLabels[sub.status]}
        </Text>{' '}
        {duos.map(duo => (
          <Text key={duo.id}>Usuario invitado: {duo.invitationEmail}</Text>
        ))}
      </Text>
      <HStack justify='space-between'>
        <HStack align='start'>
          {sub.activatedAt && (
            <Text>
              Acceso desde el{' '}
              <b>
                {' '}
                {''}
                {DateTime.createFromDate(new Date(sub.activatedAt))
                  .addDays(1)
                  .formatDate({
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}{' '}
              </b>
            </Text>
          )}
          {sub.finishAt && (
            <Text>
              hasta el {''}
              <b>
                {DateTime.createFromDate(new Date(sub.finishAt))
                  .addDays(1)
                  .formatDate({
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
              </b>
            </Text>
          )}
        </HStack>
        <DoPaymentButton
          paymentMethod={plan.payment_method.type}
          subId={sub.id}
          subStatus={sub.status}
          checkoutLink={sub.checkoutLink}
          invoice={{ id: sub.invoice?.id, status: sub.invoice?.status }}
        />
      </HStack>
    </Stack>
  )
}

type DoPaymentButtonProps = {
  paymentMethod: PAYMENT_METHODS_TYPES
  subId: string
  subStatus: SUBSCRIPTIONS_TYPES
  invoice: {
    id?: string
    status?: INVOICE_STATUS
  }
  checkoutLink: string
}

const DoPaymentButton = ({
  paymentMethod,
  subId,
  subStatus,
  invoice,
  checkoutLink,
}: DoPaymentButtonProps) => {
  // INCREASE => Solo por SUB PENDIENTE => CHECKOUT LINK
  // MP => SUB Pendiente o SUB Cancelada => CHECKOUT LINK
  // TRANSFERENCIA => si tiene una boleta OPEN o INCOBRABLE => ARMAR RUTA

  let link = '',
    showButton = false

  if (paymentMethod === PAYMENT_METHODS_TYPES.INCREASE) {
    if (subStatus === SUBSCRIPTIONS_TYPES.PENDING) {
      link = checkoutLink
      showButton = true
    }
  } else if (paymentMethod === PAYMENT_METHODS_TYPES.MERCADO_PAGO) {
    if (
      subStatus === SUBSCRIPTIONS_TYPES.PENDING ||
      subStatus === SUBSCRIPTIONS_TYPES.CANCELLED
    ) {
      link = checkoutLink
      showButton = true
    }
  } else if (paymentMethod === PAYMENT_METHODS_TYPES.TRANSFER) {
    if (
      invoice.status === INVOICE_STATUS.OPEN ||
      invoice.status === INVOICE_STATUS.UNCOLLECTIBLE
    ) {
      link = `/mis-suscripciones/${subId}/adjuntar-pago/${invoice.id}`
      showButton = true
    }
  }

  return showButton ? (
    <a href={link} target='_blank' rel='noreferrer'>
      <ChakraButton w='100%'>Realizar pago</ChakraButton>
    </a>
  ) : null
}
