import React from 'react'
import { GridItem, Stack, Text, Box, StackProps } from '@chakra-ui/react'

type ItemProfileProps = {
  /** @default #ff005c */
  color?: string
  text: string
} & StackProps

export const ItemProfile = ({
  color = '#ff005c',
  text,
  ...props
}: ItemProfileProps) => {
  return (
    <Stack
      as={GridItem}
      w='full'
      h='150px'
      borderRadius={11}
      backgroundColor='#131415'
      justify='center'
      align='center'
      cursor='pointer'
      boxShadow='11px 6px 20px 7px rgba(0, 0, 0, 0.25)'
      _hover={{ bgColor: '#1b1c1c' }}
      {...props}
    >
      <Text maxW='70%' textAlign='center' fontWeight='bold'>
        {text}
      </Text>
      <Box
        w='50px'
        h='6px'
        as='span'
        backgroundColor={color}
        borderRadius={6}
        boxShadow={`0 1px 10px 0 ${color}`}
      />
    </Stack>
  )
}
