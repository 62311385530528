import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const useRedirect = (redirectTo: string, ...keys: string[]) => {
  const { state } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!state || !keys.every(key => Object.keys(state).includes(key))) {
      navigate(redirectTo, { replace: true })
    }
  }, [])
}
