import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { HStack, Heading, Show, Stack, Text, useToast } from '@chakra-ui/react'
import { Button, NucCheckCircle } from '@nucba-coding/nucba-ui'

import { Summary } from '../components/summary/Summary'
import { TOAST_DEFUALT_OPTIONS } from '@/shared/constants'
import { PageContainer } from '../container/PageContainer'
import { useAppDispatch } from '@/shared/app/hooks'
import { useSessionState } from '@/shared/features/session.slice'
import { clearCart, useCartState } from '../features/cart.slice'
import Scrollable from '@/shared/components/Scroleable'

export const SuccessPay = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const { session, token } = useSessionState()
  const { checkoutLink } = useCartState()

  const toast = useToast({
    ...TOAST_DEFUALT_OPTIONS,
    status: 'success',
  })

  useEffect(() => {
    if (session) {
      toast({ title: 'Pago exitoso', description: 'Tu pago ha sido aprobado' })

      return navigate('/mis-suscripciones', { replace: true })
    }

    if (!checkoutLink) {
      navigate('/inscripcion/crear-cuenta', { replace: true })
    }
  }, [session, checkoutLink])

  return (
    <PageContainer>
      <Stack spacing={8} w='520px'>
        <HStack>
          <Heading mb={1.5}>Pago confirmado</Heading>
          <NucCheckCircle width='25' height='25' iconColor='#38A169' />
        </HStack>
        <Stack spacing={10}>
          <Stack spacing={3}>
            <Text fontSize='14px'>
              {session
                ? 'Ahora sos parte de una nueva camada. Éxitos!!'
                : 'A continuación vas a completar tus datos personales y de inscripción'}
            </Text>
          </Stack>
        </Stack>
        <Button
          size='sm'
          p={4}
          alignSelf='flex-start'
          fontSize='md'
          letterSpacing={1}
          onClick={() => {
            dispatch(clearCart())
            return navigate(
              token && !session
                ? '/inscripcion/redes-sociales'
                : session
                  ? '/auth/login'
                  : '/inscripción',
              { replace: true },
            )
          }}
        >
          {session ? 'Ir a la plataforma' : 'Continuar inscripción'}
        </Button>
      </Stack>
      <>
        <Show above='base' below='md'>
          <Scrollable withCta />
        </Show>
        <Show above='md'>
          <Stack w='60%'>
            <Summary />
          </Stack>
        </Show>
      </>
    </PageContainer>
  )
}
