import React from 'react'
import { Skeleton, VStack } from '@chakra-ui/react'
import { Card } from '@nucba-coding/nucba-ui'

import { generateArrayWithRandomKeys } from '@/shared/utils'

export const SubscriptionsCardLoader = () => {
  return (
    <VStack>
      {generateArrayWithRandomKeys(1).map(li => (
        <Skeleton key={li} borderRadius='md' w='800px'>
          <Card title='' bgImage='' w='auto' h='150px' />
        </Skeleton>
      ))}
    </VStack>
  )
}
