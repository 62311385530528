import React from 'react'
import { Navigate } from 'react-router-dom'

import { RootLayout } from '@/container/RootLayout'
import { useAppSelector } from '@/shared/app/hooks'
import { sessionState } from '@/shared/features/session.slice'
import { ScreenNotification } from '@/shared/components/ScreenNotification'
import { tracker } from '@/shared/helpers'

export const Root = () => {
  const { session, id } = useAppSelector(sessionState)

  const isMaintenance = false

  if (isMaintenance) {
    return (
      <ScreenNotification
        title='GALILEO SE ENCUENTRA EN UN MANTENIMIENTO DE RUTINA ⚠️'
        subtitle='En las próximas horas estará disponible para que puedas utilizarla 💪'
        imgWidth='20%'
      />
    )
  }

  if (session) {
    tracker.setUserId(id)

    return <RootLayout />
  }

  tracker.clearUserId()

  return <Navigate to='auth/login' replace />
}
