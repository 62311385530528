import React from 'react'
import {
  Button,
  Popover as ChakraPopover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  Text,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { AnswerItem } from './AnswerItem'

type PopoverProps = {
  title: string
  btnText: string
  answers: string[]
}

export const DefaultAnswersPopover = ({
  title,
  btnText,
  answers,
}: PopoverProps) => {
  return (
    <ChakraPopover placement='top'>
      <PopoverTrigger>
        <Button
          bg='nucba.gray'
          as={motion.button}
          whileTap={{ scale: 0.95 }}
          fontSize={{ base: 'sm', '2xl': 'sm' }}
          textTransform='capitalize'
          borderRadius={30}
        >
          {title}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent bg='nucba.gray' border='none' borderRadius={15}>
          <PopoverHeader textTransform='uppercase' textAlign='center'>
            {btnText}
          </PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody display='flex' flexDir='column' gap={2}>
            {!answers.length ? (
              <Text textAlign='center'>
                No hemos encontrado mensajes directos para esta entrega 😢
              </Text>
            ) : (
              answers?.map(answer => (
                <AnswerItem key={answer} answer={answer} />
              ))
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </ChakraPopover>
  )
}
