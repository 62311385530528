import React from 'react'
import { Stack, Skeleton } from '@chakra-ui/react'

export const ClassCardLoader = () => {
  return (
    <Stack minH='40px' spacing={3}>
      <Skeleton w='300px' h='16px' borderRadius={5} />
      <Skeleton w='60px' h='13px' borderRadius={5} />
    </Stack>
  )
}
