import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { HStack, Stack, Text } from '@chakra-ui/react'
import { Button } from '@nucba-coding/nucba-ui'

import { ModulePresentation } from '@/education/shared/components/ModulePresentation'
import { ContentTemplate } from '@/education/shared/template/ContentTemplate'
import { Breadcrumb } from '@/shared/components/Breadcrumb'
import { Hero } from '@/shared/components/Hero'
import { Isologo } from '@/shared/components/Isologo'
import { useGetExerciseDetailsQuery } from '../services/exercise.service'
import { unformatCourseName } from '@/education/shared/utils'
import { HeroLoader } from '@/education/shared/components/loaders/HeroLoader'
import { HeaderNovu } from '@/shared/components/Novu'

export const ExercisesHero = () => {
  const { exerciseId, code, course } = useParams()
  const { pathname, state } = useLocation()
  const navigate = useNavigate()

  const isAllExercises = pathname.endsWith('entregas')
  const isExerciseDetails = pathname.endsWith('detalles')

  const { data: exercise, isLoading } = useGetExerciseDetailsQuery(
    {
      exerciseId: exerciseId!,
      code: code!,
    },
    { skip: isAllExercises },
  )

  return (
    <Hero image='https://res.cloudinary.com/dcatzxqqf/image/upload/v1679871403/Galileo-Platform/Images/banner/assigmentHero_uqi30o.webp'>
      <ContentTemplate
        h='100%'
        display='flex'
        flexDir='column'
        justifyContent='space-around'
      >
        <HStack w='95%' justify='space-between'>
          <Breadcrumb />
          <HStack>
            <HeaderNovu />
            <Isologo />
          </HStack>
        </HStack>
        {isLoading ? (
          <HeroLoader withStatus />
        ) : (
          <HStack w='95%' justify='space-between' align='flex-end'>
            <Stack spacing={5}>
              <Stack spacing={0}>
                {!isAllExercises && (
                  <Text
                    ml='calc(12px + 0.75rem)'
                    fontWeight={700}
                    fontSize='14px'
                    textTransform='uppercase'
                    letterSpacing={0.75}
                  >
                    {exercise?.class.course.name}
                  </Text>
                )}
                <ModulePresentation
                  title={
                    isAllExercises
                      ? unformatCourseName(course!)
                      : exercise?.name || ''
                  }
                  fontSize='4xl'
                />
              </Stack>
              <HStack spacing={3}>
                <Button
                  size='sm'
                  fontSize='sm'
                  borderRadius={30}
                  textTransform='uppercase'
                  onClick={() =>
                    navigate(
                      isAllExercises || isExerciseDetails ? '..' : 'detalles',
                      {
                        relative: 'path',
                        state,
                      },
                    )
                  }
                >
                  {isAllExercises
                    ? 'Ver clases'
                    : isExerciseDetails
                      ? 'Ver asignaciones'
                      : 'Ver Consigna'}
                </Button>
                {!isAllExercises && !isExerciseDetails && (
                  <Text fontSize='sm' textTransform='uppercase'>
                    {exercise?.corrected}/{exercise?.submitted} Por corregir
                  </Text>
                )}
              </HStack>
            </Stack>
          </HStack>
        )}
      </ContentTemplate>
    </Hero>
  )
}
