import { Currencies } from '../types'

export class Currency {
  private type: Currencies

  constructor(type = Currencies.ARS) {
    this.type = type
  }

  equals(that: Currency): boolean {
    return this.type === that.type
  }

  toString(): string {
    return this.type.toString()
  }
  getValue(): Currencies {
    return this.type
  }
  static create(type?: Currencies): Currency {
    return new Currency(type)
  }
}
