import { useAppSelector } from '../app/hooks'

import { StudentOf, sessionState } from '../features/session.slice'

export const useStudent = (code: string): StudentOf => {
  const { studentOf } = useAppSelector(sessionState)
  const student = studentOf.find(student => student.cohort === code)

  if (!student) {
    return {
      studentId: '',
      cohort: '',
      expelledAt: null,
    }
  }

  return student
}
