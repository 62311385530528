import React, { useEffect } from 'react'
import { Heading, Stack, Text } from '@chakra-ui/react'

import { ProductOptsPopover } from './ProductOptsPopover'
import { cohortSelected, useCartState } from '../features/cart.slice'
import { useAppDispatch } from '@/shared/app/hooks'
import { serializeProductCohort } from '../utils'
import { useGetCohortsWithProductAvailableQuery } from '../services/inscriptionQuery.service'
import { ProductsOptsPopoverLoader } from './loaders/ProductOptsPopoverLoader'
import { PRODUCTS_TYPES } from '@/shared/types'

export const SelectCohort = () => {
  const { product, cohort } = useCartState()

  const { data: cohorts, isLoading } = useGetCohortsWithProductAvailableQuery(
    {
      productId: product?.id ?? '',
    },
    { skip: !product },
  )

  const dispatch = useAppDispatch()

  const isOndemand = product?.type === PRODUCTS_TYPES.ON_DEMAND

  useEffect(() => {
    if (isOndemand && cohorts) {
      dispatch(
        cohortSelected({
          cohort: cohorts.cohorts[0],
        }),
      )
    }
  }, [cohort, isOndemand])

  return (
    <Stack spacing={5}>
      <Heading fontSize={{ base: 'xl', lg: '3xl' }}>Fechas</Heading>
      {product?.type !== PRODUCTS_TYPES.ON_DEMAND && (
        <Text fontWeight={400} fontSize='13px'>
          Antes de inscribirte, revisa los horarios de cursada
        </Text>
      )}
      <Stack spacing={1}>
        {product?.type === PRODUCTS_TYPES.ON_DEMAND ? (
          <Text fontSize='14px' fontWeight={700} textTransform='uppercase'>
            Elegir cursada
          </Text>
        ) : (
          <Text fontSize='14px' fontWeight={700} textTransform='uppercase'>
            Elegir fecha de cursada
          </Text>
        )}
        {isLoading ? (
          <ProductsOptsPopoverLoader />
        ) : (
          <ProductOptsPopover
            header={isOndemand ? 'Elegir cursada' : 'Elegir fechas de cursada'}
            triggerText={
              isOndemand ? 'Modos de cursada' : 'Fechas y camadas para cursar'
            }
            options={
              cohorts
                ? cohorts.cohorts.map(cohort => {
                    const { id, fullDesc } = serializeProductCohort(cohort)
                    if (isOndemand) {
                      return {
                        id,
                        desc: 'Cursada Ondemand - Cursa a tu tiempo y ritmo',
                      }
                    } else {
                      return {
                        id,
                        desc: fullDesc,
                      }
                    }
                  })
                : []
            }
            initialSelected={cohort?.id}
            onSelect={id =>
              dispatch(
                cohortSelected({
                  cohort: cohorts!.cohorts.find(coh => coh.id === id)!,
                }),
              )
            }
          />
        )}
      </Stack>
    </Stack>
  )
}
