import React from 'react'

import { Show, Stack, Text } from '@chakra-ui/react'

import { PageHeading } from '../components/PageHeading'
import { Summary } from '../components/summary/Summary'
import { PageContainer } from '../container/PageContainer'

import Scrollable from '@/shared/components/Scroleable'
import { PersonalInfoForm } from '../components/forms/PersonalInfoForm'

export const PersonalInfo = () => {
  return (
    <PageContainer>
      <Stack spacing={8}>
        <Stack align={{ base: 'center', md: 'start' }}>
          <PageHeading title='2. Datos Personales' />
          <Text fontSize='14px'>Para poder inscribirte en NUCBA</Text>
        </Stack>
        <PersonalInfoForm />
      </Stack>
      <>
        <Show above='base' below='md'>
          <Scrollable withCta />
        </Show>
        <Show above='md'>
          <Stack w='50%'>
            <Summary />
          </Stack>
        </Show>
      </>
    </PageContainer>
  )
}
