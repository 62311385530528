import React, { useEffect } from 'react'
import { Grid, HStack, Stack, Text, VStack, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@nucba-coding/nucba-ui'

import { useGetContractAuditQuery } from '../services/contract-query.service'
import { ContractAuditLoader } from '@/education/shared/components/loaders/ContractAuditLoader'
import { TOAST_DEFUALT_OPTIONS } from '@/shared/constants'
import { Logo } from '@/shared/components/Logo'
import { DateTime } from '@/shared/helpers'
import { CONTRACT_STATUS } from '@/shared/types'

export const AuditLog = () => {
  const navigate = useNavigate()
  const { subscriptionId, contractId } = useParams()

  const {
    data: contract,
    isLoading,
    isSuccess,
  } = useGetContractAuditQuery(contractId!)

  const toast = useToast({
    ...TOAST_DEFUALT_OPTIONS,
    status: 'error',
  })

  useEffect(() => {
    if (isSuccess && contract?.status === CONTRACT_STATUS.PENDIENTE) {
      toast({
        title: 'Acceso restringido',
        description: 'El registro de auditoria no está disponible',
      })

      navigate(`/mis-suscripciones/${subscriptionId}/contrato`, {
        replace: true,
      })
    }
  }, [isSuccess, contract])

  return isLoading ? (
    <ContractAuditLoader />
  ) : (
    <Stack w='100%' justifyContent='center' p={20} spacing={10}>
      <VStack align='self-start' p={8}>
        <HStack w='full' justify='space-between'>
          <Logo />
          <Text fontSize='4xl' fontWeight='bold'>
            Registro de auditoria
          </Text>
        </HStack>
        <HStack w='full' justify='space-between'>
          <Text fontWeight='black'>ID. DEL DOCUMENTO</Text>
          <Text>{contract?.id}</Text>
        </HStack>
        <HStack w='full' justify='space-between'>
          <Text fontWeight='black'>FORMATO FECHA REG. AUDIT.</Text>
          <Text>
            {DateTime.createFromDate(
              new Date(contract?.signedAt || new Date()),
            ).formatDate({
              dateStyle: 'long',
            })}
          </Text>
        </HStack>
        <HStack w='full' justify='space-between'>
          <Text fontWeight='black'>ESTADO</Text>
          <Text color='#48BB78' fontWeight='bold'>
            {contract?.status}
          </Text>
        </HStack>
      </VStack>
      <hr />
      <Text fontSize='lg' fontWeight='bold'>
        Historial del documento
      </Text>

      <Grid templateColumns='1fr 1fr 4fr' gap={8} w='full'>
        <Text>Enviado</Text>
        <Text fontWeight='bold'>
          {DateTime.createFromDate(
            new Date(contract?.createdAt || new Date()),
          ).formatDate({
            dateStyle: 'medium',
          })}
        </Text>
        <Text>
          Enviado para firmar a{' '}
          <b>
            {' '}
            {contract?.user.profiles[0].firstname}{' '}
            {contract?.user.profiles[0].lastname}
          </b>{' '}
          | <b> {contract?.user.profiles[0].document}</b> {''}(
          {contract?.user.email}) por secretaria@nucba.com.ar.
        </Text>
        <Text>Visto</Text>
        <Text fontWeight='bold'>
          {DateTime.createFromDate(
            new Date(contract?.viewedAt || new Date()),
          ).formatDate({
            dateStyle: 'medium',
          })}
        </Text>
        <Text>
          Visto por{' '}
          <b>
            {' '}
            {contract?.user.profiles[0].firstname}{' '}
            {contract?.user.profiles[0].lastname}
          </b>{' '}
          | <b> {contract?.user.profiles[0].document}</b> {''}(
          {contract?.user.email}) <b> IP: {contract?.signedFromIp} </b>
        </Text>
        <Text>Firmado</Text>
        <Text fontWeight='bold'>
          {DateTime.createFromDate(
            new Date(contract?.signedAt || new Date()),
          ).formatDate({
            dateStyle: 'medium',
          })}
        </Text>
        <Text>
          Firmado por{' '}
          <b>
            {' '}
            {contract?.user.profiles[0].firstname}{' '}
            {contract?.user.profiles[0].lastname}
          </b>{' '}
          | <b> {contract?.user.profiles[0].document}</b> {''}(
          {contract?.user.email}) <b> IP: {contract?.signedFromIp} </b>
        </Text>
      </Grid>

      <Button
        size='sm'
        w={200}
        fontSize='md'
        alignSelf='end'
        onClick={() => navigate('/', { replace: true })}
      >
        Ir a la plataforma
      </Button>
    </Stack>
  )
}
