import React from 'react'
import { Outlet } from 'react-router-dom'
import { Box, useMediaQuery } from '@chakra-ui/react'
import { Slider } from '@nucba-coding/nucba-ui'

import { Isologo } from '@/shared/components/Isologo'
import { Logo } from '@/shared/components/Logo'

export const AuthLayout = () => {
  const [isSmallerThan1280] = useMediaQuery('(max-width: 768px)')

  return (
    <>
      {!isSmallerThan1280 && (
        <>
          <Box pos='absolute' top={1} left={8}>
            <Isologo />
          </Box>

          <Box pos='absolute' top={8} right={8}>
            <Slider
              titles={[
                'Educación Competitiva',
                'Cursada en vivo',
                'Contenido variado',
                'Sistema de entregas',
              ]}
            />
          </Box>
        </>
      )}

      <main>
        <Outlet />
      </main>

      {!isSmallerThan1280 && (
        <Box pos='absolute' bottom={3} right={8}>
          <Logo />
        </Box>
      )}
    </>
  )
}
