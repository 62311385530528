import React from 'react'
import { Skeleton } from '@chakra-ui/react'

import { generateArrayWithRandomKeys } from '@/shared/utils'

type ListLoaderProps = {
  withScroll?: boolean
}

export const ListLoader = ({ withScroll = false }: ListLoaderProps) => {
  return (
    <>
      {generateArrayWithRandomKeys(10).map(li => (
        <Skeleton
          key={li}
          w={withScroll ? '97%' : '100%'}
          minH='60px'
          borderRadius={20}
        />
      ))}
    </>
  )
}
