import React from 'react'
import { Skeleton } from '@chakra-ui/react'

import { generateArrayWithRandomKeys } from '@/shared/utils'

export const ListItemsLoader = () => {
  return (
    <>
      {generateArrayWithRandomKeys(3).map(li => (
        <Skeleton w='full' h='20px' borderRadius={5} key={li} />
      ))}
    </>
  )
}
