import React from 'react'
import { Text } from '@chakra-ui/react'

type ProductItemProps = {
  title: string
  content: string
}

export const ProductItem = ({ title, content }: ProductItemProps) => {
  return (
    <Text fontSize={{ base: 'xs', md: 'sm' }} fontWeight={400}>
      <Text as='span' color='#B6B6B6'>
        {title}:{' '}
      </Text>
      {content}
    </Text>
  )
}
