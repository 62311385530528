import React from 'react'
import { Show, Stack, Text } from '@chakra-ui/react'

import { PageHeading } from '@/education/shared/components/PageHeading'
import Scrollable from '@/shared/components/Scroleable'
import { LoginFormDuo } from '../components/forms/LoginFormDuo'
import { Summary } from '../components/summary/Summary'
import { PageContainer } from '../container/PageContainer'
import { useCartState } from '../features/cart.slice'
import { LoginInscriptionForm } from '../components/forms/LoginInscriptionForm'

export const LoginInscription = () => {
  const { plan } = useCartState()

  return (
    <>
      {!plan?.isDuo ? (
        <PageContainer>
          <Stack spacing={8}>
            <Stack>
              <PageHeading>Iniciar sesión</PageHeading>
              <Text fontSize='14px'>Para poder iniciar sesión en NUCBA</Text>
            </Stack>
            <LoginInscriptionForm />
          </Stack>
          <>
            <Show above='base' below='md'>
              <Scrollable withCta />
            </Show>
            <Show above='md'>
              <Stack w='60%'>
                <Summary />
              </Stack>
            </Show>
          </>
        </PageContainer>
      ) : (
        <LoginFormDuo />
      )}
    </>
  )
}
