import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box } from '@chakra-ui/react'
import { motion } from 'framer-motion'

import { useNucbaTheme } from '@/shared/hooks'
import { NucBoomerang } from '@nucba-coding/nucba-ui'

export const Arrow = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { colors } = useNucbaTheme()

  return (
    <Box
      as={motion.div}
      bg={colors.nucba.primary}
      borderRadius='full'
      w={8}
      h={8}
      display='inline-flex'
      alignItems='center'
      justifyContent='center'
      boxShadow=' 0px 0px 6px 2px rgba(255, 0, 92, 0.3)'
      cursor='pointer'
      userSelect='none'
      onClick={() => navigate('..', { state })}
    >
      <NucBoomerang width='20' height='20' />
    </Box>
  )
}
