import React, { useState } from 'react'
import { Stack, TabList, TabPanels, Tabs } from '@chakra-ui/react'
import { motion } from 'framer-motion'

import { CustomTabItem } from './CustomTabItem'
import { useGetNucbaProductsQuery } from '@/inscription/services/inscriptionQuery.service'
import { ProductsLoader } from '../loaders/ProductsLoader'
import { PRODUCTS_TYPES } from '@/shared/types'
import { ProductCard } from '../ProductCard'

const tabs: { name: string; value: PRODUCTS_TYPES }[] = [
  { name: 'Carreras', value: PRODUCTS_TYPES.CARRERA },
  { name: 'Cursos', value: PRODUCTS_TYPES.CURSO },
  { name: 'Ondemand', value: PRODUCTS_TYPES.ON_DEMAND },
]

export const TabMenu = () => {
  const [selectedTab, setSelectedTab] = useState(0)
  const { data: products, isLoading } = useGetNucbaProductsQuery()

  return (
    <Tabs isLazy variant='unstyled' defaultIndex={0}>
      <Stack spacing={5} maxW='min-content'>
        <TabList
          as={motion.ul}
          layout
          w={{ base: '90%', sm: '80%', md: '60%' }}
          bg='#2F2F2F'
          borderRadius={28}
          h='50px'
          alignItems='center'
          alignSelf='center'
        >
          {tabs.map(({ name }, i) => (
            <CustomTabItem
              key={name}
              isSelected={selectedTab === i}
              onClick={() => setSelectedTab(i)}
            >
              {name}
            </CustomTabItem>
          ))}
        </TabList>
        <TabPanels>
          {isLoading ? (
            <ProductsLoader />
          ) : (
            tabs.map(({ name, value }) => (
              <ProductCard
                key={name}
                products={products ? products[value] : []}
                type={value}
                isLoading={isLoading}
              />
            ))
          )}
        </TabPanels>
      </Stack>
    </Tabs>
  )
}
