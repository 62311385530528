import styled from '@emotion/styled'
import { Input } from '@nucba-coding/nucba-ui'

export const InputWithScroll = styled(Input)`
  padding-block: 0.5rem;
  padding-inline: 2.5rem;
  resize: none;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #17181e;
    border-radius: 20px;
    border: 3px solid transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 20px;
    margin: 16px;
    cursor: default;
  }
`
