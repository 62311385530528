import React from 'react'
import { Box, Heading, Image, Stack, Text } from '@chakra-ui/react'
import { Logo } from './Logo'

type screenNotificationProps = {
  title: string
  subtitle: string
  imgWidth: string
}

export const ScreenNotification = ({
  title,
  subtitle,
  imgWidth,
}: screenNotificationProps) => {
  return (
    <Stack
      w='80%'
      h='100vh'
      m='0 auto'
      textAlign='center'
      justify='center'
      align='center'
    >
      <Box pos='absolute' bottom={3} right={8}>
        <Logo />
      </Box>
      <Heading>{title}</Heading>
      <Text>{subtitle} </Text>
      <Image
        w={imgWidth}
        src='https://res.cloudinary.com/dcatzxqqf/image/upload/v1681409344/Galileo-Platform/Images/icons/Martin-Sn_robots_icons_2d_icons_tools_icons_rpg_skills_icons_co_c880dca8-56c9-4dc4-8c55-3bc316538a80_sweiud.webp'
      />
    </Stack>
  )
}
