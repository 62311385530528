import { CustomError } from '../types'

export const isMyKnownError = (error: any): error is CustomError => {
  if (typeof error !== 'object' || !error) return false

  if ('data' in error) {
    const { data } = error

    if (typeof data !== 'object' || !data) return false

    if ('message' in data) {
      return (error as CustomError).data.message !== undefined
    }

    return false
  }

  return false
}
