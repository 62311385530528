import React from 'react'
import { Button, Text, useClipboard } from '@chakra-ui/react'

type AnswerItemProps = {
  answer: string
}

export const AnswerItem = ({ answer }: AnswerItemProps) => {
  const { hasCopied, onCopy, value } = useClipboard(answer)

  return (
    <Button
      bg='rgba(0, 0, 0, 0.16);'
      borderRadius={15}
      onClick={onCopy}
      value={value}
    >
      <Text
        w='95%'
        textTransform='none'
        overflow='hidden'
        textOverflow='ellipsis'
        whiteSpace='nowrap'
      >
        {hasCopied ? 'Copiado 🎓' : answer}
      </Text>
    </Button>
  )
}
