import React from 'react'

import { useSessionState } from '@/shared/features/session.slice'
import { HStack, Heading, Stack } from '@chakra-ui/react'
import { Button, NucCheckCircle } from '@nucba-coding/nucba-ui'
import { useNavigate } from 'react-router-dom'
import { PageContainer } from '../container/PageContainer'

export const InvitationSuccess = () => {
  const navigate = useNavigate()
  const { session } = useSessionState()

  return (
    <PageContainer>
      <Stack spacing={8} w='full' align='center'>
        <HStack>
          <Heading mb={1.5}>Invitación ya aceptada</Heading>
          <NucCheckCircle width='25' height='25' iconColor='#38A169' />
        </HStack>
        <Button
          size='sm'
          p={4}
          fontSize='md'
          letterSpacing={1}
          onClick={() => {
            return navigate('/', { replace: true })
          }}
        >
          {session ? 'Ir a la plataforma' : 'Continuar inscripción'}
        </Button>
      </Stack>
    </PageContainer>
  )
}
