import { StyleProps, Text } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

type PageHeadingProps = {
  children: ReactNode
} & StyleProps

export const FormHeading = ({ children, ...rest }: PageHeadingProps) => {
  return (
    <Text lineHeight={2} fontSize='lg' fontWeight='semibold' {...rest}>
      {children}
    </Text>
  )
}
