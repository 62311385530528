import React from 'react'

import { ListOfExercises } from '../components/ListOfExercises'
import { SectionContainerWithScroll } from '@/education/shared/components/SectionContainerWithScroll'
import { ContentTemplate } from '@/education/shared/template/ContentTemplate'
import { useRedirect } from '@/shared/hooks'

export const Exercises = () => {
  useRedirect('..', 'courseId')

  return (
    <ContentTemplate>
      <SectionContainerWithScroll spacing={5}>
        <ListOfExercises />
      </SectionContainerWithScroll>
    </ContentTemplate>
  )
}
