import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
  Center,
  Container,
  Heading,
  Link,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'

import { FormInput } from '../components/FormInput'
import { SubmitButton } from '../components/SubmitButton'

import { useErrorMessage, useNucbaTheme } from '@/shared/hooks'
import { useLoginMutation } from '@/auth/services/auth.service'
import { useAppDispatch } from '@/shared/app/hooks'
import { startSession, updateSession } from '@/shared/features/session.slice'
import type { Input } from '@/shared/types'
import { TOAST_DEFUALT_OPTIONS } from '@/shared/constants'
import { clearCart } from '@/inscription/features/cart.slice'

const inputs: Input[] = [
  {
    name: 'email',
    label: 'Correo Electrónico',
    placeholder: 'micorreo@gmail.com',
  },
  {
    name: 'password',
    label: 'Contraseña',
    type: 'password',
    placeholder: '****************',
  },
]

const initialValues = {
  email: '',
  password: '',
}

const validationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email('Email inválido')
    .required('Campo requerido'),
  password: Yup.string().required('Campo requerido'),
})

export const Login = () => {
  const toast = useToast({
    ...TOAST_DEFUALT_OPTIONS,
    status: 'error',
  })

  const { colors } = useNucbaTheme()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [loginMutation, { error }] = useLoginMutation()
  useErrorMessage(error)

  useEffect(() => {
    dispatch(clearCart())
  }, [])

  const handleSubmit = async (values: typeof initialValues) => {
    const login = await loginMutation({
      ...values,
      email: values.email.toLowerCase().trim(),
    })

    if (!('error' in login)) {
      const { data } = login

      if (data?.tyc) {
        dispatch(startSession(data!))
        return navigate('/', { replace: true })
      } else {
        dispatch(updateSession({ token: data?.token }))
        toast({
          title: 'No has completado el proceso de creación de cuenta',
          description: (
            <Text>
              Para ingresar los datos faltantes, hace{' '}
              <Link
                href='/inscripcion/redes-sociales'
                color={colors.nucba.primary}
                _hover={{ textDecoration: 'underline' }}
              >
                click aquí
              </Link>
            </Text>
          ),
          duration: 10000,
        })
      }
    }
  }

  return (
    <Container centerContent maxW='container.md'>
      <Stack h='100vh' justify='center' align='center' spacing={7}>
        <Heading as='h1' textTransform='uppercase'>
          Iniciar Sesión
        </Heading>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, dirty }) => (
            <Form>
              <Stack spacing={8} align='center'>
                {inputs.map(input => (
                  <FormInput key={input.name} {...input} />
                ))}
                <Center
                  fontSize='sm'
                  color='nucba.white-70'
                  _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => navigate('/auth/cambiar-password/paso-uno')}
                >
                  Cambiar contraseña
                </Center>
                <SubmitButton
                  errors={errors}
                  dirty={dirty}
                  isLoading={isSubmitting}
                >
                  Iniciar Sesión
                </SubmitButton>
                <Center
                  fontSize='sm'
                  color='nucba.white-70'
                  w={{ base: 300, sm: 500 }}
                  flexWrap='wrap'
                >
                  ¿Es la primera vez que usas NUCBA?{' '}
                  <Text
                    as='span'
                    color='nucba.primary'
                    marginLeft={1}
                    _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => navigate('/inscripcion')}
                  >
                    Inscribite
                  </Text>
                </Center>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </Container>
  )
}
