import React from 'react'
import { Box, GridItem, Skeleton, Stack } from '@chakra-ui/react'

export const ContractAuditLoader = () => {
  return (
    <Stack w='100%' justifyContent='center' p={20} spacing={5}>
      <Skeleton w='100%' marginBottom={2} alignSelf='self-start' p={8}>
        <Box h={60} />
      </Skeleton>
      <Box w='100%' boxShadow='base' borderRadius='md'>
        <Skeleton w='25%' h='6' bg='gray.200' mb='8' />
        <GridItem colSpan={3}>
          <Skeleton w='100%' h='10' bg='gray.200' mb='4' />
          <Skeleton w='100%' h='10' bg='gray.200' mb='4' />
          <Skeleton w='100%' h='10' bg='gray.200' />
        </GridItem>
      </Box>
    </Stack>
  )
}
