import React, { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'

import { TOAST_DEFUALT_OPTIONS } from '@/shared/constants'
import { CONTRACT_STATUS } from '@/shared/types'
import { useGetSubscriptionContractQuery } from '@/education/subscriptions/services/contract-query.service'
import { ApplicationLoader } from '@/shared/components/loaders/ApplicationLoader'

export const ContractRoot = () => {
  const navigate = useNavigate()
  const { subscriptionId } = useParams()

  const {
    data: contract,
    isSuccess,
    isLoading,
  } = useGetSubscriptionContractQuery(subscriptionId!)

  const toast = useToast({
    ...TOAST_DEFUALT_OPTIONS,
    status: 'error',
  })

  useEffect(() => {
    if (isSuccess) {
      if (!contract || contract.status === CONTRACT_STATUS.FIRMADO) {
        toast({
          title: 'El contrato ya se encuentra firmado',
        })
        navigate('/', { replace: true })
      }
    }
  }, [isSuccess, contract])

  if (isLoading) {
    return <ApplicationLoader />
  }

  return <Outlet />
}
