import React, { ReactNode } from 'react'
import { Box, StyleProps } from '@chakra-ui/react'

type ContentTemplateProps = {
  children: ReactNode
} & StyleProps

export const ContentTemplate = ({
  children,
  ...rest
}: ContentTemplateProps) => {
  return (
    <Box w='calc(100% - 180px)' ml='auto' {...rest}>
      {children}
    </Box>
  )
}
