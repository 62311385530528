import React, { useState } from 'react'
import { Link, Text } from '@chakra-ui/react'
import { Button } from '@nucba-coding/nucba-ui'

import { Checkbox } from '@/shared/components/Checkbox'
import { useCartState } from '../features/cart.slice'

export const PaymentConfirmation = () => {
  const { checkoutLink } = useCartState()

  const [isChecked, setIsChecked] = useState(false)

  return (
    <>
      <Checkbox isChecked={isChecked} onChange={() => setIsChecked(!isChecked)}>
        <Text fontSize='14px'>
          Acepto el{' '}
          <Link
            href='https://www.nucba.com.ar/tyc'
            target='_blank'
            cursor='pointer'
          >
            <Text as='span' color='nucba.primary'>
              Cumplimiento de pago e inscripción.
            </Text>
          </Link>
        </Text>
      </Checkbox>
      <Link href={checkoutLink || ''}>
        <Button
          w='max-content'
          fontSize='md'
          borderRadius={30}
          textTransform='uppercase'
          isDisabled={!isChecked}
        >
          Inscribirse
        </Button>
      </Link>
    </>
  )
}
