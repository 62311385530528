import React, { ReactNode } from 'react'
import { Box, Text, useMultiStyleConfig, useTab } from '@chakra-ui/react'

import { Slider } from './Slider'
import { useNucbaTheme } from '@/shared/hooks'

type CustomTabItemProps = {
  children: ReactNode
  isSelected: boolean
  onClick: () => void
}

export const CustomTabItem = ({ isSelected, ...props }: CustomTabItemProps) => {
  const { colors } = useNucbaTheme()

  const tabProps = useTab({ ...props })

  const styles = useMultiStyleConfig('Tabs', tabProps)

  return (
    <Box flexGrow={1}>
      <Text
        __css={styles.tab}
        pos='relative'
        w='min-content'
        mx='auto'
        textAlign='center'
        fontWeight={isSelected ? 700 : 500}
        fontSize={{ base: 'xs', md: 'md' }}
        textTransform='uppercase'
        userSelect='none'
        cursor='pointer'
        _focusVisible={{
          outline: 'none',
        }}
        {...tabProps}
      >
        {tabProps.children}
        {isSelected && (
          <Slider
            transition={{ duration: 0.2 }}
            layoutId='underline'
            style={{
              width: '100%',
              backgroundColor: colors.nucba.primary,
              boxShadow: `0 1px 10px 0 ${colors.nucba.primary}`,
            }}
          />
        )}
      </Text>
    </Box>
  )
}
