import React, { ReactNode } from 'react'
import { HStack, Text } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'

const itemVariants = {
  closed: {
    opacity: 0,
  },
  open: {
    opacity: 1,
    transition: {
      delay: 0.2,
      duration: 0.5,
    },
  },
}

type SidebarItemProps = {
  text: string
  element: ReactNode
  isOpen: boolean
  onClick?: () => void
}

export const SidebarItem = ({
  element,
  text,
  isOpen,
  onClick,
}: SidebarItemProps) => {
  return (
    <HStack
      onClick={onClick && onClick}
      h='24px'
      align='center'
      spacing={8}
      cursor='pointer'
    >
      {element}
      <AnimatePresence>
        {isOpen && (
          <Text
            as={motion.p}
            variants={itemVariants}
            fontSize='sm'
            _hover={{ textDecoration: 'underline' }}
          >
            {text}
          </Text>
        )}
      </AnimatePresence>
    </HStack>
  )
}
