import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Divider, Text, Grid, Stack, Box, GridItem } from '@chakra-ui/react'
import { PDFDownloadLink } from '@react-pdf/renderer'

import { CertificadoPdf } from './CertificadoPdf'
import { ItemProfile } from './ItemProfile'
import { RedeemGitHubEducation } from './RedeemGitHubEducation'
import { useAppDispatch, useAppSelector } from '@/shared/app/hooks'
import { endSession, sessionState } from '@/shared/features/session.slice'
import { useGetUserCertificatesQuery } from '../services/profile.service'
import { ItemProfileLoader } from '@/education/shared/components/loaders/ItemProfileLoader'
import { ContentTemplate } from '@/education/shared/template/ContentTemplate'
import { Modal } from '@/shared/components/Modal'

export const ProfileActions = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const { id, isStaff } = useAppSelector(sessionState)

  const { data, isLoading } = useGetUserCertificatesQuery(id, { skip: isStaff })

  return (
    <ContentTemplate>
      <Stack py={8} spacing={4}>
        <Divider w='70%' mx='auto' />
        <Text textAlign='center'>Herramientas</Text>
        <Grid
          justifyContent='center'
          justifyItems='center'
          templateColumns='repeat(3, minmax(250px, 280px))'
          gap={10}
        >
          <ItemProfile
            text='Cambiar contraseña'
            onClick={() => {
              dispatch(endSession())
              return navigate('/auth/cambiar-password/paso-uno', {
                replace: true,
              })
            }}
          />
          <ItemProfile
            text='Mis suscripciones'
            onClick={() => {
              return navigate('/mis-suscripciones', {
                replace: true,
              })
            }}
          />
          <Modal
            trigger={<ItemProfile text='GitHub Education' />}
            modalProps={{
              isCentered: true,
              autoFocus: false,
            }}
          >
            {RedeemGitHubEducation}
          </Modal>
        </Grid>
      </Stack>

      {!isStaff && (
        <Stack py={8} spacing={4}>
          <Divider w='70%' m='0 auto' />
          <Text textAlign='center' mt={1}>
            Mis certificados
          </Text>

          <Grid
            justifyContent='center'
            justifyItems='center'
            templateColumns='repeat(3, minmax(250px, 280px))'
            gap={10}
          >
            {isLoading ? (
              <ItemProfileLoader />
            ) : !data?.Certificates.length ? (
              <GridItem colSpan={3} py={2}>
                <Text textAlign='center'>
                  Todavía no tenes certificados para descargar. <br /> Seguí
                  cursando para conseguirlos 😎
                </Text>
              </GridItem>
            ) : (
              data?.Certificates.map(
                ({ course, product, type, id, createdAt, user }) => (
                  <Box key={id} w='full' h='full'>
                    <PDFDownloadLink
                      document={
                        <CertificadoPdf
                          title={
                            type === 'PARTIAL'
                              ? course?.name.split(' ').join('_')
                              : product.name.split(' ').join('_')
                          }
                          firstName={user.profiles[0].firstname}
                          lastName={user.profiles[0].lastname}
                          isPartial={type === 'PARTIAL'}
                          createdAt={createdAt || new Date()}
                        />
                      }
                      fileName={`Certificado_${
                        type === 'PARTIAL'
                          ? course?.name.split(' ').join('_')
                          : product.name.split(' ').join('_')
                      }`}
                    >
                      <ItemProfile
                        color={course?.color}
                        text={type === 'PARTIAL' ? course?.name : product.name}
                      />
                    </PDFDownloadLink>
                  </Box>
                ),
              )
            )}
          </Grid>
        </Stack>
      )}
    </ContentTemplate>
  )
}
