import React from 'react'
import ReactPlayer from 'react-player'

type VideoPlayerProps = {
  videoId: string
  width: string
  height?: string
}

export const VideoPlayer = ({ videoId, width, height }: VideoPlayerProps) => {
  return (
    <ReactPlayer
      width={width}
      height={height}
      controls
      playing
      url={`https://player.vimeo.com/video/${videoId}?transparent=0`}
    />
  )
}
