import React from 'react'
import { ListItem, Text, UnorderedList } from '@chakra-ui/react'

import { openInNewTab } from '@/shared/utils'

type ExerciseResourcesProps = {
  resources: string[]
}

export const ExerciseResources = ({ resources }: ExerciseResourcesProps) => {
  return (
    <UnorderedList spacing={2} listStyleType='initial'>
      {resources.map(item => (
        <ListItem key={item}>
          👉 &nbsp;
          <Text
            as='a'
            cursor='pointer'
            _hover={{ textDecoration: 'underline' }}
            onClick={() => openInNewTab(item)}
          >
            {item}
          </Text>
        </ListItem>
      ))}
    </UnorderedList>
  )
}
