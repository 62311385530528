import React from 'react'

import { HStack, Skeleton, Stack } from '@chakra-ui/react'

import { generateArrayWithRandomKeys } from '@/shared/utils'

export const PlansCardLoader = () => {
  return (
    <Stack spacing={4} marginTop={4}>
      <HStack flexWrap={{ base: 'wrap', md: 'nowrap' }}>
        {generateArrayWithRandomKeys(1).map(i => (
          <Skeleton
            key={i}
            borderRadius={30}
            minW={{ base: '300px', sm: 'full' }}
            h={300}
          />
        ))}
      </HStack>
    </Stack>
  )
}
