import { useEffect } from 'react'
import { useAppDispatch } from '../../shared/app/hooks'
import { useValidateCouponMutation } from '@/inscription/services/inscription.service'
import { useErrorMessage } from '../../shared/hooks/useErrorMessage'
import { couponAdded, useCartState } from '@/inscription/features/cart.slice'

export const useDiscountOnLoad = (coupon: string | null) => {
  const { plan, cohort, coupon: couponInCart } = useCartState()

  const dispatch = useAppDispatch()
  const [validateCoupon, { error: validateCouponError }] =
    useValidateCouponMutation()
  useErrorMessage(validateCouponError)

  const returnConditions = !coupon || !plan || !cohort || couponInCart

  useEffect(() => {
    if (returnConditions) return
    const upperCaseCode = coupon.toUpperCase().trim()

    ;(async () => {
      const res = await validateCoupon({
        code: upperCaseCode,
        planId: plan?.id || '',
      })
      if (!('error' in res)) {
        dispatch(
          couponAdded({
            coupon: {
              code: upperCaseCode,
              discount: res.data.data.discount,
              valid: res.data.success,
            },
          }),
        )
      }
    })()
  }, [coupon, plan?.id, dispatch])
}
