import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { HStack, Text, Avatar, Select, Link, Stack } from '@chakra-ui/react'
import { Button } from '@nucba-coding/nucba-ui'

import {
  useAssignmentPrefetch,
  useGetAssignmentsByExerciseQuery,
} from '../services/assignment.service'
import { CardContainer } from '@/education/shared/components/ClassCardContainer'
import { ClassCardContent } from '@/education/shared/components/ClassCardContent'
import { formatStatus } from '@/education/shared/utils'
import { AssignmentStatus } from '@/shared/enums'
import { ASSIGNMENT_STATUS_COLOR } from '@/shared/constants'
import { DateTime } from '@/shared/helpers'
import { ListLoader } from '@/education/shared/components/loaders/ListLoader'
import { SectionContainerWithScroll } from '@/education/shared/components/SectionContainerWithScroll'

const assigmentOptions: Record<AssignmentStatus, string> = {
  APROBADA: 'Aprobada',
  ENTREGADA: 'Entregada',
  EN_REVISION: 'En revisión',
  PENDIENTE: 'Pendiente',
  VENCIDA: 'Vencida',
}

export const ListOfAssignments = () => {
  const { code, exerciseId } = useParams()

  const { data, isLoading } = useGetAssignmentsByExerciseQuery({
    code: code!,
    exerciseId: exerciseId!,
  })

  const prefetchAssignmentDetails = useAssignmentPrefetch(
    'getAssignmentDetails',
    {
      force: false,
    },
  )

  const [filter, setFilter] = useState<AssignmentStatus | null>(null)

  let usersAssignments = data?.users_assignments

  if (filter) {
    usersAssignments = data?.users_assignments.filter(
      assigment => assigment.status === filter,
    )
  }

  return (
    <Stack spacing={5} h='47vh'>
      <HStack justify='space-between' w='93%'>
        <Text fontSize='xl'>🛠️ Entregas de los estudiantes</Text>
        <HStack>
          <Text whiteSpace='nowrap'>Filtrar por:</Text>

          <Select
            focusBorderColor='inherit'
            placeholder='Todas'
            onChange={e => setFilter(e.target.value as AssignmentStatus)}
          >
            {Object.entries(assigmentOptions).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </Select>
        </HStack>
      </HStack>
      <SectionContainerWithScroll flexGrow={1} spacing={5} overflowY='unset'>
        {isLoading ? (
          <ListLoader />
        ) : usersAssignments?.length ? (
          usersAssignments?.map(
            ({
              id,
              status,
              cohortIntegrantByStudentid: {
                user: { profiles },
              },
              assignmentStatus_changes,
            }) => (
              <CardContainer key={id} cursor='auto !important'>
                <HStack width='97%' spacing={4}>
                  <Avatar
                    name={`${profiles[0].firstname} ${profiles[0].lastname}`}
                    w={10}
                    h={10}
                    borderRadius={10}
                  />
                  <ClassCardContent
                    heading={`${profiles[0].firstname} ${profiles[0].lastname}`}
                  >
                    <HStack spacing={5}>
                      <HStack fontSize='sm' gap={3} divider={<span>|</span>}>
                        {assignmentStatus_changes.length && (
                          <Text color='#AFAFAF'>
                            Entregado{' '}
                            {DateTime.createFromDate(
                              new Date(assignmentStatus_changes[0].updatedAt),
                            ).formatDate({
                              dateStyle: 'medium',
                              timeStyle: 'short',
                            })}
                          </Text>
                        )}
                        <Text color={ASSIGNMENT_STATUS_COLOR[status]}>
                          {formatStatus(status)}
                        </Text>
                      </HStack>

                      {status === AssignmentStatus.PENDIENTE ? (
                        <Button
                          textTransform='uppercase'
                          borderRadius={20}
                          size='sm'
                          fontSize='sm'
                          disabled
                        >
                          Corregir
                        </Button>
                      ) : (
                        <Link
                          href={`../../asignacion/${id}/devolucion`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <Button
                            textTransform='uppercase'
                            borderRadius={20}
                            size='sm'
                            fontSize='sm'
                            onMouseEnter={() =>
                              prefetchAssignmentDetails({
                                assignmentId: id,
                                code: code!,
                              })
                            }
                          >
                            {status === AssignmentStatus.ENTREGADA
                              ? 'Corregir'
                              : 'Ver entrega'}
                          </Button>
                        </Link>
                      )}
                    </HStack>
                  </ClassCardContent>
                </HStack>
              </CardContainer>
            ),
          )
        ) : (
          <Stack flexGrow={1} justify='center'>
            <Text textAlign='center' fontSize='xl'>
              {filter
                ? `Ninguna asignación ${assigmentOptions[filter].toLowerCase()}`
                : 'No hay asignaciones para esta entrega'}
            </Text>
          </Stack>
        )}
      </SectionContainerWithScroll>
    </Stack>
  )
}
