import React from 'react'

import { HStack, Stack, Text, useToast } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { SubmitButton } from '../SubmitButton'

import { useLoginMutation } from '@/auth/services/auth.service'
import { PASSWORD_VALIDATION, TOAST_DEFUALT_OPTIONS } from '@/shared/constants'
import {
  initialState,
  startSession,
  updateSession,
} from '@/shared/features/session.slice'
import { useErrorMessage } from '@/shared/hooks'
import { type CustomError, type Input } from '@/shared/types'
import { useDispatch } from 'react-redux'
import { FormOutlineInput } from '../FormOutlineInput'
import { checkoutLinkAdded, useCartState } from '../../features/cart.slice'
import { useCreateSubscriptionMutation } from '../../services/inscription.service'

const inputs: Input[] = [
  {
    name: 'email',
    label: 'Correo Electrónico',
    type: 'email',
  },
  {
    name: 'password',
    label: 'Contraseña',
    type: 'password',
  },
]

const initialValues = {
  email: '',
  password: '',
}

const validationSchema = Yup.object({
  email: Yup.string().email('Email inválido').required('Campo requerido'),
  password: Yup.string()
    .min(8, 'Mínimo 8 caracteres')
    .matches(
      PASSWORD_VALIDATION,
      'Debe contener al menos una minúscula, una mayúscula, un número y un símbolo',
    )
    .required('Campo requerido'),
})

export const LoginInscriptionForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { cohort, plan, coupon } = useCartState()

  const toast = useToast({
    ...TOAST_DEFUALT_OPTIONS,
    title: 'Lo sentimos, ha ocurrido un error',
    status: 'error',
    variant: 'top-accent',
  })

  const [createSubscription, { error: createSubscriptionError }] =
    useCreateSubscriptionMutation()
  useErrorMessage(createSubscriptionError)

  const [login, { error: loginError }] = useLoginMutation()
  useErrorMessage(loginError)

  const handleFormSubmit = async ({
    email,
    password,
  }: typeof initialValues) => {
    const loginRes = await login({ email, password })

    if (!('error' in loginRes)) {
      if (loginRes.data.tyc) {
        dispatch(startSession(loginRes.data))
      } else {
        dispatch(updateSession({ ...initialState, token: loginRes.data.token }))
      }

      const subscriptionRes = await createSubscription({
        planId: plan?.id || '',
        cohortId: cohort?.id || '',
        coupon: coupon?.code || '',
      })

      if (!('error' in subscriptionRes)) {
        dispatch(
          checkoutLinkAdded({
            checkoutLink: subscriptionRes.data.checkout_link,
          }),
        )
        navigate(`/inscripcion/pago-pendiente`)
      } else {
        toast({
          description: (subscriptionRes.error as CustomError).data.message,
        })
      }
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ isSubmitting, errors, dirty }) => (
        <Form>
          <Stack spacing={{ base: 4, '2xl': 8 }}>
            <HStack
              flexDir={{ base: 'column', sm: 'row' }}
              maxW={{ base: '840px', sm: '640px' }}
              align={{ base: 'normal', sm: 'normal' }}
              spacing={{ base: 0, sm: 8 }}
              mb={{ base: 0, sm: 6 }}
              gap={{ base: 4, sm: 0 }}
            >
              {inputs.map(input => (
                <FormOutlineInput key={input.name} {...input} />
              ))}
            </HStack>
            <Stack>
              <Text fontSize='sm'>
                ¿Es la primera vez que usas NUCBA?{' '}
                <Text
                  as='a'
                  color='nucba.primary'
                  cursor='pointer'
                  _hover={{ textDecor: 'underline' }}
                  onClick={() => {
                    navigate(`/inscripcion/crear-cuenta`)
                  }}
                >
                  Regístrate
                </Text>
              </Text>
            </Stack>
            <SubmitButton
              errors={errors}
              dirty={dirty}
              isLoading={isSubmitting}
            >
              Iniciar sesión
            </SubmitButton>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}
