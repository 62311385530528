import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'

import { isMyKnownError } from '../guards'
import { TOAST_DEFUALT_OPTIONS } from '../constants'

export const useErrorMessage = (error: unknown) => {
  const toast = useToast({
    ...TOAST_DEFUALT_OPTIONS,
    title: 'Lo sentimos, ha ocurrido un error',
    status: 'error',
    variant: 'top-accent',
  })

  useEffect(() => {
    if (error) {
      toast.closeAll()

      if (isMyKnownError(error)) {
        toast({
          description: error.data.message,
        })
      } else {
        toast({
          description: 'Algo inesperado sucedió',
        })
      }
    }
  }, [error])
}
