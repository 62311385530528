import React, { ReactNode, useState } from 'react'
import {
  Collapse as ChakraCollapse,
  CollapseProps as ChakraCollapseProps,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { NucChevronUp } from '@nucba-coding/nucba-ui'

type CollapseProps = {
  children: ReactNode
  title: string
  collapseProps?: ChakraCollapseProps
}

export const Collapse = ({ children, title, collapseProps }: CollapseProps) => {
  const [show, setShow] = useState(false)

  const handleToggle = () => setShow(!show)

  return (
    <Stack spacing={1}>
      <HStack justify='space-between'>
        <Text fontSize={{ base: 'xs', md: 'sm' }} fontWeight={600}>
          {title}
        </Text>
        <motion.button
          initial={{ rotate: '0deg' }}
          animate={{ rotate: show ? '180deg' : '0deg' }}
          style={{
            maxWidth: 'min-content',
            cursor: 'pointer',
          }}
          onClick={handleToggle}
        >
          <NucChevronUp />
        </motion.button>
      </HStack>
      <ChakraCollapse startingHeight={20} in={show} {...collapseProps}>
        {children}
      </ChakraCollapse>
    </Stack>
  )
}
