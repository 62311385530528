import React, { useEffect, useRef } from 'react'
import Player from '@vimeo/player'
import { Box } from '@chakra-ui/react'

type VimeoPlayerProps = {
  vimeoId: number
  height?: number
}

export const VimeoPlayer = ({ vimeoId, height = 360 }: VimeoPlayerProps) => {
  const playerVimeo = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (playerVimeo.current) {
      const player = new Player(playerVimeo.current, {
        id: vimeoId,
        height,
        width: undefined,
        transparent: false,
      })
      player.on('play', async () => {
        console.log('Clase iniciada')
      })

      player.on('ended', () => {
        console.log('Clase finalizada')
      })

      player.loadVideo(vimeoId)
    }
  }, [vimeoId])

  const boxStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'fit-content',
  }

  return <Box style={boxStyles} ref={playerVimeo} />
}
