import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Stack, Text, useToast, HStack } from '@chakra-ui/react'
import { Button, NucCheckCircle } from '@nucba-coding/nucba-ui'
import { AnimatePresence } from 'framer-motion'
import { Form, Formik, FieldArray, FieldProps, Field } from 'formik'
import * as Yup from 'yup'

import { FormHeading } from '../components/FormHeading'
import { UploadLinkInput } from '../components/UploadLinkInput'
import { InputWithScroll } from '../components/InputWithScroll'
import { ContentTemplate } from '../../shared/template/ContentTemplate'
import { useSubmitAssignmentMutation } from '../services/assignmentActions.service'
import { useErrorMessage } from '@/shared/hooks'
import { useAppDispatch, useAppSelector } from '@/shared/app/hooks'
import {
  assignmentState,
  updateCurrentAssignment,
} from '../features/assignment.slice'
import { cohortApi } from '@/education/cohorts/services/cohort.service'
import { assignmentApi } from '../services/assignment.service'
import { sessionState } from '@/shared/features/session.slice'
import { AssignmentStatus } from '@/shared/enums'
import { checkIfDuplicateExists } from '@/shared/utils'
import { TOAST_DEFUALT_OPTIONS } from '@/shared/constants'

export const initialValues = {
  content: '',
  URLs: [''],
}

const validationSchema = Yup.object({
  content: Yup.string().trim().required('Campo requerido'),
  URLs: Yup.array()
    .of(
      Yup.string()
        .trim()
        .url('No es un link válido')
        .required('Link requerido'),
    )
    .min(1, 'Mínimo debes enviar un link')
    .max(2, 'Máximo podes enviar 2 links'),
})

export const SubmitAssignment = () => {
  const { state } = useLocation()
  const { assignmentId } = useParams()
  const navigate = useNavigate()

  const toast = useToast({
    ...TOAST_DEFUALT_OPTIONS,
    status: 'error',
    variant: 'top-accent',
  })

  const { isStaff } = useAppSelector(sessionState)
  const { status } = useAppSelector(assignmentState)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isStaff) {
      navigate('..', { replace: true })
    }
  }, [])

  const [submitAssignment, { data, isLoading, error, isSuccess }] =
    useSubmitAssignmentMutation()
  useErrorMessage(error)

  useEffect(() => {
    if (isSuccess) {
      dispatch(cohortApi.util.invalidateTags(['Assignment', 'Class']))
      dispatch(assignmentApi.util.invalidateTags(['Assignment']))
      dispatch(updateCurrentAssignment({ status: data?.status }))
    }
  }, [isSuccess])

  const canSubmit =
    status === AssignmentStatus.PENDIENTE ||
    status === AssignmentStatus.EN_REVISION

  const isExpired = status === AssignmentStatus.VENCIDA

  const handleSubmit = async ({ content, URLs }: typeof initialValues) => {
    const existsDuplicatedLinks = checkIfDuplicateExists(URLs)

    if (existsDuplicatedLinks) {
      return toast({ title: 'Los links son idénticos' })
    }

    const response = await submitAssignment({
      assignmentId: assignmentId!,
      body: {
        URL: URLs,
        content,
      },
    })

    if (!('error' in response)) {
      return navigate('../devolucion', { state })
    }
  }

  return (
    <ContentTemplate>
      <Stack mt={{ base: 12, '2xl': 16 }} mb={5}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, dirty }) => (
            <Form>
              <Stack width='40%' minH='150px' h='auto' spacing={3}>
                <FormHeading>Ingresa el link de tu proyecto</FormHeading>
                <FieldArray name='URLs'>
                  {({ push, remove, form: { values } }) => (
                    <AnimatePresence>
                      {values.URLs.map((_URL: string, index: number) => (
                        <UploadLinkInput
                          key={index}
                          name={`URLs[${index}]`}
                          push={push}
                          remove={remove}
                          index={index}
                          length={values.URLs.length}
                        />
                      ))}
                    </AnimatePresence>
                  )}
                </FieldArray>
              </Stack>

              <Stack w='50%' align='flex-end' spacing={5} mt={5}>
                <Stack w='100%' spacing={3}>
                  <HStack spacing={2} align='baseline'>
                    <FormHeading>
                      {' '}
                      Deja tus comentarios sobre la entrega
                    </FormHeading>
                    <Text color='nucba.primary' fontSize={'sm'} as='p'>
                      * Obligatorio
                    </Text>
                  </HStack>
                  <Field name='content'>
                    {({ field }: FieldProps) => (
                      <InputWithScroll
                        as='textarea'
                        placeholder='Descripción de la entrega'
                        w='100%'
                        h={{ base: '90px', '2xl': '120px' }}
                        {...field}
                      />
                    )}
                  </Field>
                </Stack>
                <Button
                  type='submit'
                  paddingInline={3}
                  fontSize='sm'
                  fontWeight={700}
                  borderRadius={50}
                  textTransform='uppercase'
                  disabled={
                    Boolean(...Object.values(errors)) ||
                    !dirty ||
                    !canSubmit ||
                    isExpired
                  }
                  isLoading={isLoading}
                >
                  {canSubmit || isExpired ? (
                    'Enviar entrega'
                  ) : (
                    <HStack>
                      <NucCheckCircle /> <Text>ENTREGADO</Text>
                    </HStack>
                  )}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
    </ContentTemplate>
  )
}
