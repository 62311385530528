import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Container, Stack, useToast } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { FormInput } from '../../components/FormInput'
import { ProgressBarWithLabel } from '../../components/ProgressBarWithLabel'
import { SubmitButton } from '../../components/SubmitButton'
import { useRecoverStepThreeMutation } from '@/auth/services/auth.service'

import { useErrorMessage, useRedirect } from '@/shared/hooks'
import { PASSWORD_VALIDATION, TOAST_DEFUALT_OPTIONS } from '@/shared/constants'
import type { Input } from '@/shared/types'

const inputs: Input[] = [
  {
    name: 'password',
    label: 'Ingresá tu nueva contraseña',
    type: 'password',
    placeholder: '****************',
  },
  {
    name: 'confirmPassword',
    label: 'Reingresa tu nueva contraseña',
    type: 'password',
    placeholder: '****************',
  },
]

const initialValues = {
  password: '',
  confirmPassword: '',
}

const validationSchema = Yup.object({
  password: Yup.string()
    .min(8, 'Mínimo 8 caracteres')
    .matches(
      PASSWORD_VALIDATION,
      'Debe contener al menos una minúscula, una mayúscula, un número y un símbolo',
    )
    .required('Campo requerido'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Las contraseñas no coinciden')
    .required('Campo requerido'),
})

export const StepThree = () => {
  useRedirect('/auth/cambiar-password/paso-uno', 'from', 'code', 'email')

  const navigate = useNavigate()
  const { state } = useLocation()

  const toast = useToast({
    ...TOAST_DEFUALT_OPTIONS,
    status: 'success',
  })

  const [recoverStepThree, { error }] = useRecoverStepThreeMutation()
  useErrorMessage(error)

  const handleSubmit = async (values: typeof initialValues) => {
    const { password } = values
    const { email, code } = state
    const res = await recoverStepThree({ email, code: +code, password })

    if (!('error' in res)) {
      toast({
        title: 'Contraseña cambiada exitosamente',
        description:
          'Ya puedes ingresar a la paltaforma 🥑 con tu nueva contraseña',
      })

      return navigate('/auth/login', {
        replace: true,
      })
    }
  }

  return (
    <Container centerContent maxW='container.md'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors, dirty }) => (
          <Form>
            <Stack h='100vh' justify='center' align='center' spacing={20}>
              <ProgressBarWithLabel
                label='Ingresa la nueva contraseña'
                value={100}
              />
              <Stack spacing={10}>
                {inputs.map(input => (
                  <FormInput key={input.name} {...input} />
                ))}
              </Stack>
              <Stack align='center' spacing={8}>
                <SubmitButton
                  w={220}
                  textTransform='uppercase'
                  errors={errors}
                  dirty={dirty}
                  isLoading={isSubmitting}
                >
                  Finalizar
                </SubmitButton>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </Container>
  )
}
