import { UserRoles } from '@/shared/enums'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

type LoginReq = { email: string; password: string }

type LoginRes = {
  id: string
  email: string
  firstname: string
  lastname: string
  roles: UserRoles[]
  createdAt: Date
  instagram: string
  tyc: boolean
  token: string
}

export type StepOneReq = {
  email: string
}

type StepOneRes = {
  data: {
    expiresAt: Date
  }
}

type StepTwoReq = {
  email: string
  code: number
}

type StepThreeReq = {
  email: string
  code: number
  password: string
}

type StepThreeRes = {
  fields: string[]
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_AUTH_URL}/authentication`,
  }),
  endpoints: builder => ({
    login: builder.mutation<LoginRes, LoginReq>({
      query: body => ({
        url: '/signin',
        method: 'POST',
        body,
        headers: {
          'x-application-id': process.env.REACT_APP_APPLICATION_ID,
        },
      }),
    }),
    validateEmail: builder.mutation<any, { email: string }>({
      query: body => ({
        url: '/validate-email',
        method: 'POST',
        body,
      }),
    }),
    recoverStepOne: builder.mutation<StepOneRes, StepOneReq>({
      query: body => ({
        url: '/recover-password/step-1',
        method: 'POST',
        body,
      }),
    }),
    recoverStepTwo: builder.mutation<true, StepTwoReq>({
      query: body => ({
        url: '/recover-password/step-2',
        method: 'POST',
        body,
      }),
    }),
    recoverStepThree: builder.mutation<StepThreeRes, StepThreeReq>({
      query: body => ({
        url: '/recover-password/step-3',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useLoginMutation,
  useValidateEmailMutation,
  useRecoverStepOneMutation,
  useRecoverStepTwoMutation,
  useRecoverStepThreeMutation,
} = authApi
