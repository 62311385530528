import { AssignmentStatus } from '../enums'
import { SUBSCRIPTIONS_TYPES } from '../types'

export const ASSIGNMENT_STATUS_COLOR: Record<AssignmentStatus, string> = {
  PENDIENTE: 'nucba.primary',
  ENTREGADA: '#48BB78',
  VENCIDA: 'nucba.primary',
  APROBADA: '#48BB78',
  EN_REVISION: '#ffa100',
}

export const SUBSCRIPTIONS_STATUS_COLOR: Record<SUBSCRIPTIONS_TYPES, string> = {
  ACTIVE: '#48BB78',
  WAITING_PAYMENT_CONFIRMATION: '#ffa100',
  PENDING: '#ffa100',
  PAUSED: '#ffa100',
  CANCELLED: 'nucba.primary',
  FINISHED: 'nucba.background',
}
