import React from 'react'
import { StyleProps } from '@chakra-ui/react'
import { Button } from '@nucba-coding/nucba-ui'
import { FormikErrors } from 'formik'

type SubmitButtonProps = {
  children: React.ReactNode
  isLoading: boolean
  errors: FormikErrors<any>
  dirty: boolean

  /** @default 250 */
  w?: number
} & StyleProps

export const SubmitButton = ({
  children,
  isLoading,
  errors,
  dirty,
  w = 250,
  ...rest
}: SubmitButtonProps) => {
  return (
    <Button
      type='submit'
      w={w}
      isDisabled={Boolean(...Object.values(errors)) || !dirty}
      isLoading={isLoading}
      {...rest}
    >
      {children}
    </Button>
  )
}
