import React from 'react'
import { HStack, Stack, Text } from '@chakra-ui/react'

import { useNucbaTheme } from '@/shared/hooks'
import { couponRemoved, useCartState } from '@/inscription/features/cart.slice'
import { Money } from '@/shared/helpers/Money'
import { Currency } from '@/shared/helpers/Currency'
import { useAppDispatch } from '@/shared/app/hooks'
import { PAYMENT_METHODS_TYPES } from '@/shared/types'
import { formatMoney } from '@/inscription/utils/formatMoney'

type PriceProps = {
  canRemoveCoupon: boolean
}

export const Price = ({ canRemoveCoupon }: PriceProps) => {
  const { colors } = useNucbaTheme()
  const { campaign, plan, coupon, paymentMethod } = useCartState()
  const dispatch = useAppDispatch()

  if (!plan) {
    return null
  }

  const price = Money.fromString(
    String(plan.price),
    Currency.create(plan.currency),
  )
  let netPrice = price
  let campaingDiscount = Money.fromCents(0, Currency.create(plan.currency))
  let couponDiscount = Money.fromCents(0, Currency.create(plan.currency))

  if (campaign) {
    netPrice = netPrice.reducedByPercentage(campaign.discount).round(1)
    campaingDiscount = price.substract(netPrice)
  }

  if (coupon && coupon.valid) {
    netPrice = netPrice.reducedByPercentage(coupon.discount).round(1)
    couponDiscount = price.substract(campaingDiscount).substract(netPrice)
  } else {
    couponDiscount = netPrice
      .reducedByPercentage(coupon?.discount || 0)
      .round(1)
  }

  return (
    <Stack
      w={{ base: '80%', md: '60%' }}
      alignSelf={{ base: 'center', md: 'end' }}
      align='flex-end'
      spacing={2}
      p={{ base: 0, md: 0 }}
    >
      <HStack w='full' justify='space-between'>
        <Text
          fontSize={{ base: 'xs', md: 'sm' }}
          noOfLines={1}
          fontWeight={600}
        >
          {plan.name}
        </Text>
        <Text
          fontSize={{ base: 'xs', md: 'sm' }}
          fontWeight={600}
          as={campaign ? 'del' : 'p'}
        >
          {price.getFormattedMoney()}
        </Text>
      </HStack>
      {campaign && (
        <HStack w='full' justify='space-between'>
          <Text fontSize='xs' fontWeight={600} noOfLines={1}>
            {campaign.name} | {campaign.discount}%
          </Text>
          <Text fontSize={{ base: 'xs', md: 'sm' }}>
            - {campaingDiscount?.getFormattedMoney()}
          </Text>
        </HStack>
      )}
      {coupon?.valid === 'pending' ? (
        <HStack w='full' justify='space-between'>
          <Text fontSize={{ base: 'xs', md: 'sm' }} fontWeight={600}>
            Validando cupon...
          </Text>
        </HStack>
      ) : (
        coupon && (
          <>
            <HStack w='full' justify='space-between'>
              <HStack>
                <Text
                  fontSize={{ base: 'xs', md: 'sm' }}
                  fontWeight={600}
                  opacity={coupon?.valid ? '100%' : '60%'}
                >
                  {coupon?.code} | {coupon?.discount}%
                </Text>
                {canRemoveCoupon ? (
                  <Text
                    textDecoration='underline'
                    _hover={{ color: '#FF005C' }}
                    fontSize='xs'
                    cursor='pointer'
                    onClick={() => dispatch(couponRemoved())}
                  >
                    eliminar
                  </Text>
                ) : null}
              </HStack>
              <Text
                fontSize='xs'
                color={colors.nucba.primary}
                opacity={coupon?.valid ? '100%' : '60%'}
              >
                - {couponDiscount?.getFormattedMoney()}
              </Text>
            </HStack>
            <HStack w='full'>
              {!coupon.valid && (
                <HStack>
                  <Text fontSize={{ base: 'xs', md: 'md' }}>
                    ⚠️ Cupón no valido para el plan seleccionado
                  </Text>
                </HStack>
              )}
            </HStack>
          </>
        )
      )}
      <HStack w='full' justify='space-between'>
        <Text fontSize={{ base: 'lg', md: '2xl' }} fontWeight={600}>
          Total
        </Text>
        {coupon?.valid === 'pending' ? (
          <Text fontSize='xl'>Actualizando...</Text>
        ) : (
          <Text fontSize={{ base: 'lg', md: '2xl' }}>
            {netPrice.getFormattedMoney()}
          </Text>
        )}
      </HStack>
      <Text as='span' fontSize='14px' color='#B6B6B6'>
        {plan.installments === 1 ? (
          <span>
            {plan.installments} pago sin interés de{' '}
            {formatMoney(netPrice.getValue() / plan.installments, {
              currency: plan.currency,
            })}
          </span>
        ) : (
          <span>
            {paymentMethod?.type === PAYMENT_METHODS_TYPES.MERCADO_PAGO
              ? `Hasta ${plan.installments} cuotas sin interés de ${formatMoney(
                  netPrice.getValue() / plan.installments,
                  {
                    currency: plan.currency,
                  },
                )}`
              : `${plan.installments} cuotas sin interés de ${formatMoney(
                  netPrice.getValue() / plan.installments,
                  {
                    currency: plan.currency,
                  },
                )}`}
          </span>
        )}
      </Text>

      <Text as='span' fontSize='14px' color='#B6B6B6'>
        Pago con {plan.paymentMethod && plan.paymentMethod.name}
      </Text>
    </Stack>
  )
}
