import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { Box, Heading, Stack, Text, useToast } from '@chakra-ui/react'
import { Button } from '@nucba-coding/nucba-ui'

import { Checkbox } from '@/shared/components/Checkbox'
import { useErrorMessage } from '@/shared/hooks'

// REVIEW
import { useConfirmRegisterMutation } from '@/inscription/services/inscription.service'
import { TOAST_DEFUALT_OPTIONS } from '@/shared/constants'
import TyCText from '../components/TyCText'
import { useSessionState } from '@/shared/features/session.slice'

const SectionWithScroll = styled(Box)`
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #3e3e3e;
    border-radius: 5px;
  }

  &::-webkit-scrollbar:horizontal {
    display: none;
  }
`

export const TyC = () => {
  const toast = useToast({
    ...TOAST_DEFUALT_OPTIONS,
    status: 'success',
  })

  const navigate = useNavigate()
  const { state } = useLocation()
  const { token } = useSessionState()

  const [isReaded, setIsReaded] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)

  const [confirmRegister, { error, isLoading }] = useConfirmRegisterMutation()
  useErrorMessage(error)

  const [redirectTimer, setRedirectTimer] = useState(5)

  useEffect(() => {
    if (!token) {
      navigate('/inscripcion')
    }
  }, [token])

  useEffect(() => {
    let timer: NodeJS.Timer

    if (error) {
      timer = setInterval(() => {
        setRedirectTimer(prevTimer => prevTimer - 1)
      }, 1000)
    }

    if (redirectTimer === 0) {
      navigate('/auth/login')
    }

    return () => {
      clearInterval(timer)
    }
  }, [error, redirectTimer, navigate])

  const onConditionsAccepted = async () => {
    const confirm = await confirmRegister({
      tyc: isConfirmed,
      instagram: state?.instagram,
      twitter: state?.twitter,
    })

    if (!('error' in confirm)) {
      toast({
        title: 'Bienvenido/a a NUCBA!! 🎓',
        description:
          'Te deseamos el mejor de los éxitos en todo tu trayecto profesional',
        duration: 15000,
      })

      return navigate('/', { replace: true })
    }
  }

  return (
    <Stack w='80%' mx='auto' spacing={8}>
      <Heading as='h2' fontSize='2xl' fontWeight={600}>
        Términos de Uso
      </Heading>

      <SectionWithScroll gap={4} maxH={{ base: '45vh', xl: '55vh' }}>
        <TyCText />
        <Checkbox
          isChecked={isReaded}
          onChange={() => setIsReaded(!isReaded)}
          mt={4}
        >
          <Text fontSize='sm'>
            Confirmo haber leído los{' '}
            <Text as='span' color='nucba.primary'>
              Terminos de uso y políticas de privacidad
            </Text>
          </Text>
        </Checkbox>
      </SectionWithScroll>

      <Checkbox
        isChecked={isConfirmed}
        isDisabled={!isReaded}
        onChange={() => setIsConfirmed(!isConfirmed)}
      >
        <Text fontSize='sm'>
          Acepto todos los{' '}
          <Text as='span' color='nucba.primary'>
            Terminos de uso y políticas de privacidad
          </Text>
        </Text>
      </Checkbox>

      <Button
        size='sm'
        w={200}
        fontSize='md'
        alignSelf='flex-start'
        isDisabled={!isConfirmed}
        isLoading={isLoading}
        onClick={onConditionsAccepted}
      >
        Aceptar TyC
      </Button>

      {error && <Text>Redirigiendo en {redirectTimer} segundos</Text>}
    </Stack>
  )
}
