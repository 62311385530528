import React, { useEffect, useState } from 'react'

import {
  Box,
  Button as ChakraButton,
  Checkbox,
  HStack,
  Heading,
  Img,
  Stack,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Button } from '@nucba-coding/nucba-ui'
import { useNavigate, useParams } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'

import { ContractTextLoader } from '@/education/shared/components/loaders/ContractTextLoader'
import { useAppDispatch } from '@/shared/app/hooks'
import { TOAST_DEFUALT_OPTIONS } from '@/shared/constants'
import { userQueryApi } from '@/shared/services/userQuery.service'
import { CustomError } from '@/shared/types'
import { ContractText } from '../components/ContractText'
import {
  useContractViewedMutation,
  useSignedContractMutation,
} from '../services/contract-mutation.service'
import { useGetSubscriptionContractQuery } from '../services/contract-query.service'

const SectionWithScroll = styled(Box)`
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #3e3e3e;
    border-radius: 5px;
  }

  &::-webkit-scrollbar:horizontal {
    display: none;
  }
`

const EMPTY_SIGNATURE_URI_LENGTH = 130

export const Contract = () => {
  const toast = useToast({
    ...TOAST_DEFUALT_OPTIONS,
    status: 'success',
  })

  const navigate = useNavigate()
  const { subscriptionId } = useParams()

  const dispatch = useAppDispatch()

  const {
    data: contract,
    isLoading,
    isSuccess,
  } = useGetSubscriptionContractQuery(subscriptionId!)

  const [isReaded, setIsReaded] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [sign, setSign] = useState<SignatureCanvas | null>(null)
  const [url, setUrl] = useState<string | undefined>()
  const [isLoadingSignedContract, setIsLoadingSignedContract] = useState(false)

  const [viewContract] = useContractViewedMutation()

  const [signContract] = useSignedContractMutation()

  useEffect(() => {
    const markAsViewed = async (
      _contract: Exclude<typeof contract, undefined>,
    ) => {
      try {
        if (!_contract.viewedAt) {
          await viewContract(_contract.id).unwrap()
        }
      } catch (error) {
        toast({
          status: 'error',
          title: 'Error al visualizar el contrato',
        })
      }
    }

    if (isSuccess && contract) {
      markAsViewed(contract)
    }
  }, [isSuccess, contract])

  const handleClear = () => {
    sign?.clear()
    setUrl(undefined)
  }

  const handleGenerate = () => {
    if (sign) {
      setUrl(sign.getTrimmedCanvas().toDataURL('image/png'))
    }
  }

  const fetchDataUrl = async () => {
    if (!contract) return
    setIsLoadingSignedContract(true)

    try {
      const resImg = await fetch(url!)
      const buffer = new Uint8Array(await resImg.arrayBuffer())
      const file = new Blob([buffer.buffer], { type: 'image/png' })

      const formData = new FormData()
      formData.append('signature', file)

      const res = await signContract({
        id: contract.id,
        body: formData,
      })

      if ('error' in res) {
        toast({
          description: (res.error as CustomError).data.message,
          status: 'error',
        })
      } else {
        dispatch(userQueryApi.util.invalidateTags(['Contracts']))

        toast({
          title: 'Contrato firmado correctamente',
          status: 'success',
        })

        navigate(`${contract.id}/registro`)
      }
    } finally {
      setIsLoadingSignedContract(false)
    }
  }

  return (
    <Stack w='100%' justifyContent='center' p={20} spacing={8}>
      <Heading as='h2' fontSize='2xl' fontWeight={600}>
        CONTRATO DE PRESTACIÓN DE PRODUCTO EDUCATIVO
      </Heading>
      <SectionWithScroll gap={4} maxH={{ lg: '45vh', xl: '55vh' }}>
        {isLoading ? (
          <ContractTextLoader />
        ) : (
          <ContractText contract={contract!} />
        )}
        <Checkbox
          isChecked={isReaded}
          onChange={() => setIsReaded(!isReaded)}
          mt={4}
        >
          <Text fontSize='sm'>
            Confirmo haber leído los{' '}
            <Text as='span' color='nucba.primary'>
              Terminos de uso y políticas de privacidad
            </Text>
          </Text>
        </Checkbox>
      </SectionWithScroll>

      <Checkbox
        isChecked={isConfirmed}
        isDisabled={!isReaded}
        onChange={() => setIsConfirmed(!isConfirmed)}
      >
        <Text fontSize='sm'>
          Acepto todos los{' '}
          <Text as='span' color='nucba.primary'>
            Terminos de uso y políticas de privacidad
          </Text>
        </Text>
      </Checkbox>
      <Stack spacing={10}>
        <HStack justifyContent='space-between' align='end'>
          <Stack w={500}>
            <Img
              alignSelf='center'
              w={300}
              p={4}
              src='https://res.cloudinary.com/dcatzxqqf/image/upload/v1710259744/Galileo-Platform/Assets/signatureCeo_nn3h1n.png'
              alt='Signature'
            />
            <hr />
            <Text alignSelf='center'>Firma de la institución</Text>
          </Stack>
          <Stack w='50%'>
            <Text alignSelf='center'>Luciano D’Amico</Text>
            <hr />
            <Text alignSelf='center'>Aclaración</Text>
          </Stack>
        </HStack>
        <HStack justifyContent='space-between' align='baseline'>
          <div>
            <div
              style={{
                border: '2px solid black',
                backgroundColor: 'white',
                borderRadius: '20px',
                width: 500,
                height: 200,
              }}
            >
              <SignatureCanvas
                canvasProps={{ width: 500, height: 200 }}
                ref={setSign}
                minWidth={1}
              />
              {url && url?.length <= EMPTY_SIGNATURE_URI_LENGTH && (
                <Text
                  as='span'
                  fontSize='sm'
                  fontWeight='bold'
                  color='nucba.primary'
                >
                  No se puede enviar una firma en blanco.{' '}
                </Text>
              )}
            </div>

            <br />
            <HStack spacing={4} mt={2}>
              {!sign?.isEmpty() ? (
                <ChakraButton
                  size='sm'
                  onClick={handleClear}
                  disabled={!isConfirmed}
                >
                  Borrar
                </ChakraButton>
              ) : (
                <VStack align='start' w='80%'>
                  <Button
                    size='sm'
                    onClick={handleGenerate}
                    disabled={!isConfirmed || !sign?.isEmpty()}
                  >
                    Firmar
                  </Button>
                  {!isConfirmed && (
                    <Text
                      as='span'
                      fontSize='sm'
                      fontWeight='bold'
                      color='nucba.primary'
                    >
                      Debes leer y aceptar los Terminos de uso y políticas de
                      privacidad para firmar 👆
                    </Text>
                  )}
                </VStack>
              )}
            </HStack>
          </div>
          <Stack w='50%'>
            <Text alignSelf='center'>
              {contract?.subscription.user.profiles[0].firstname}{' '}
              {contract?.subscription.user.profiles[0].lastname}
            </Text>
            <hr />
            <Text alignSelf='center'>Aclaración</Text>
          </Stack>
        </HStack>
      </Stack>
      <Button
        size='sm'
        w={200}
        fontSize='md'
        alignSelf='flex-end'
        isDisabled={!isConfirmed || sign?.isEmpty()}
        onClick={() => {
          fetchDataUrl()
        }}
      >
        {isLoadingSignedContract ? 'Aceptando...' : 'Aceptar '}
      </Button>
    </Stack>
  )
}
