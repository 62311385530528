import React from 'react'
import {
  Document,
  Page,
  Text,
  View,
  Image,
  Font,
  StyleSheet,
} from '@react-pdf/renderer'

import { DateTime } from '@/shared/helpers'

Font.register({
  family: 'Poppins',
  fonts: [
    {
      src: 'https://res.cloudinary.com/dcatzxqqf/raw/upload/v1675278896/Galileo-Platform/Assets/fonts/Poppins-Regular_szvrlj.ttf',
      fontWeight: 'regular',
    },
    {
      src: 'https://res.cloudinary.com/dcatzxqqf/raw/upload/v1675278896/Galileo-Platform/Assets/fonts/Poppins-SemiBold_cehmjq.ttf',
      fontWeight: 'semibold',
    },
    {
      src: 'https://res.cloudinary.com/dcatzxqqf/raw/upload/v1675278896/Galileo-Platform/Assets/fonts/Poppins-Bold_mtmjny.ttf',
      fontWeight: 'bold',
    },
  ],
})

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Poppins',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
    color: 'black',
  },
})

type CertificadoPdfProps = {
  title: string
  firstName: string
  lastName: string
  isPartial: boolean
  createdAt: Date
}

export const CertificadoPdf = ({
  title,
  firstName,
  lastName,
  isPartial,
  createdAt,
}: CertificadoPdfProps) => {
  const titleFormat = title
    .split(/[-\s_]+/)
    .join(' ')
    .toUpperCase()

  const createdDate = DateTime.createFromDate(new Date(createdAt))

  const day = createdDate.formatDate({
    day: 'numeric',
  })

  const month = createdDate.formatDate({
    month: 'long',
  })

  const year = createdDate.formatDate({
    year: 'numeric',
  })

  return (
    <Document>
      <Page size='A4' orientation='landscape' style={styles.page}>
        <Image
          src='https://res.cloudinary.com/dcatzxqqf/image/upload/v1681945993/Galileo-Platform/Assets/logoCertificado_wre3ed.png'
          style={{
            width: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />

        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontSize: '14px',
            }}
          >
            NUCBA certifica que
          </Text>
          <Text
            style={{
              width: '600px',
              fontSize: '24px',
              textAlign: 'center',
              fontWeight: 'extrabold',
            }}
          >
            {(firstName + ' ' + lastName).toUpperCase()}
          </Text>
          <Text
            style={{
              fontSize: '14px',
            }}
          >
            ha finalizado los estudios correspondientes{' '}
            {`${isPartial ? 'al curso de' : 'a la carrera de'}`}
          </Text>
        </View>
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontSize: '24px',
              fontWeight: 'bold',
              marginBottom: '10px',
            }}
          >
            {titleFormat}
          </Text>
          <Text
            style={{
              width: '520px',
              fontSize: '14px',
              marginTop: '20px',
              textAlign: 'center',
              lineHeight: '1.5px',
            }}
          >
            el día {day} de {month} del {year}, cumpliendo con todos los
            requisitos académicos y prácticos
          </Text>
        </View>
        <View
          style={{
            width: '700px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '20px',
            marginBottom: '10px',
          }}
        >
          <View>
            <Image
              src='https://res.cloudinary.com/dcatzxqqf/image/upload/v1710259957/Galileo-Platform/Assets/signatureCeoDark_xn2e5a.png'
              style={{
                width: '100px',
                marginBottom: '10px',
                margin: '10 auto',
              }}
            />
            <View
              style={{
                width: '180px',
                height: '1px',
                backgroundColor: 'black',
                margin: '0 auto',
              }}
            ></View>
            <Text
              style={{
                marginTop: '10px',
                paddingTop: '5px',
                margin: '0 auto',
                fontWeight: 'semibold',
              }}
            >
              Luciano D&rsquo;Amico
            </Text>
            <Text
              style={{
                margin: '0 auto',
                marginTop: '10px',
                fontSize: '12px',
              }}
            >
              CEO
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}
