import React from 'react'
import { Outlet } from 'react-router-dom'

import { ExercisesHero } from '@/education/assignments/components/ExercisesHero'

const ExercisesLayout = () => {
  return (
    <>
      <ExercisesHero />
      <Outlet />
    </>
  )
}

export default ExercisesLayout
