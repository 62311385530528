import React from 'react'
import { Heading, Stack, Text } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'

import { ProductOptsPopover } from './ProductOptsPopover'
import { paymentMethodSelected, useCartState } from '../features/cart.slice'
import { useGetNucbaPaymentMethodsQuery } from '../services/inscriptionQuery.service'
import { Collapse } from './Collapse'
import { ProductsOptsPopoverLoader } from './loaders/ProductOptsPopoverLoader'
import { useAppDispatch } from '@/shared/app/hooks'

export const SelectPaymentMethod = () => {
  const [, setSearchParams] = useSearchParams()

  const { paymentMethod, product } = useCartState()
  const dispatch = useAppDispatch()

  const { data: paymentMethods, isLoading } = useGetNucbaPaymentMethodsQuery(
    {
      productId: product?.id ?? '',
    },
    {
      skip: !product,
    },
  )

  const onPaymentMethodSelected = (selectedPaymentMethodId: string) => {
    const selectedPaymentMethod = paymentMethods?.find(
      paymentMethod => paymentMethod.id === selectedPaymentMethodId,
    ) as Exclude<typeof paymentMethods, undefined>[number]

    dispatch(
      paymentMethodSelected({
        ...selectedPaymentMethod,
        policy: selectedPaymentMethod.paymentPolicy,
      }),
    )

    setSearchParams(
      prev => {
        const searchParams = new URLSearchParams(prev)
        searchParams.set('payment', selectedPaymentMethodId)
        return searchParams
      },
      { preventScrollReset: true },
    )
  }

  return (
    <Stack spacing={5}>
      <Heading fontSize={{ base: 'xl', lg: '3xl' }}>Método de Pago</Heading>
      <Text fontWeight={400} fontSize='13px'>
        Antes de inscribirte, revisá tu método de pago. Recordá que, indistinto
        al método de pago, los cumplimientos son por la totalidad.
      </Text>
      <Stack spacing={1}>
        <Text fontSize='14px' fontWeight={700} textTransform='uppercase'>
          Elegir método de pago
        </Text>
        {isLoading ? (
          <ProductsOptsPopoverLoader />
        ) : (
          <>
            <ProductOptsPopover
              header='Elegir método de pago'
              triggerText='Métodos de pagos'
              options={
                paymentMethods
                  ? paymentMethods.map(({ id, name }) => ({
                      id,
                      desc: name,
                    }))
                  : []
              }
              initialSelected={paymentMethod?.id}
              onSelect={id => onPaymentMethodSelected(id)}
            />

            {paymentMethod ? (
              <Stack pt={4}>
                <Collapse
                  title='Políticas de pago'
                  collapseProps={{ startingHeight: 0 }}
                >
                  <Text>{paymentMethod.policy}</Text>
                </Collapse>
              </Stack>
            ) : null}
          </>
        )}
      </Stack>
    </Stack>
  )
}
