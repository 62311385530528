import React from 'react'
import { Heading, Link as LinkChakra } from '@chakra-ui/react'

const TyCText = () => {
  return (
    <>
      <p>
        Términos de Uso de los Servicios y Contrato de Adhesión de{' '}
        <span>NUCBA S.R.L.</span>
        Por favor, lea cuidadosamente los Términos de Uso de los Servicios y
        Productos. Los presentes términos y condiciones generales de servicio
        (en adelante, los “Términos y Condiciones”) regulan la relación
        contractual de Adhesión – Ley N° 24.240 de Defensa del Consumidor entre
        los Usuarios (conforme se definen más adelante) que accedan y utilicen
        la plataforma en el dominio y subdominios de www.nucba.com.ar ( en
        adelante el “Sitio”), referentes al servicio y Producto de Institución
        (“NUCBA”), que se detallan a continuación, ya que los mismos contienen
        información de su interés relacionada con el uso del Servicio y
        Producto. Al acceder al Servicio o Productos y a cualquier página del
        mismo, el Usuario se compromete a cumplir y aceptar todos los términos y
        condiciones que aparecen a continuación. El presente documento
        constituye el instrumento que contemplará los Términos de Uso del
        Servicio o Producto que se aplicarán y resultarán obligatorios para
        todos los Usuarios que deseen adquirir cursos y/o utilizar la plataforma
        web www.nucba.com.ar <br /> <br />
        CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES, LOS CUALES
        TIENEN CARÁCTER DE OBLIGATORIOS Y VINCULANTES, DEBERÁ ABSTENERSE DE
        UTILIZAR EL SERVICIO. <br /> <br />
        NUCBA S.R.L. con domicilio legal en la calle Guaminí número 1428, Ciudad
        Autónoma de Buenos Aires. <br /> <br />
        Los Términos y Condiciones pueden ser modificados en cualquier momento y
        sin previo aviso, por lo cual, el Usuario deberá acceder con frecuencia
        a fin de verificar los mismos. <br /> <br />
        COOKIES <br /> <br />
        El Usuario del sitio conoce y acepta que NUCBA utiliza “cookies” de
        terceros que permiten obtener datos vinculados a las preferencias de sus
        visitantes de los que no se extraen datos identificatorios, atento a que
        es solo un recurso técnico para mejorar la experiencia de usuario para
        navegar en el mismo, posibilitando al visitante, en sitios propios y de
        terceros, la visualización de publicidad, promociones, banners e
        iniciativas comerciales. También utilizamos cookies para personalizar la
        experiencia y contenidos del sitio y adecuar nuestra oferta de
        productos. Las cookies son archivos de texto enviadas por un sitio web
        que quedan almacenadas en el navegador del visitante. Servicios <br />
        <br />
        Los Servicios alcanzados por este documento comprenden: <br /> (i)
        Plataforma virtual, incluyendo acceso al material de cada curso o
        bootcamp a todos los Usuarios; acceso a sus calificaciones; <br />
        (ii) Acceso a el curso online y su contenido.
        <br /> Usuario (INGRESANTE). Toda aquella persona que participe de los
        programas académicos de NUCBA. incluyendo alumnos y profesores. <br />
        <br /> Proveedor. <br /> NUCBA Datos Necesarios: nombre; apellido;
        e-mail; número de teléfono; domicilio legal, país de residencia; DNI.
        <br />
        <br /> Escalabilidad <br />
        NUCBA prestará los Servicios y Productos a todos los Usuarios.NUCBA
        arbitrará los medios que estén a su alcance para proveer el mejor
        servicio disponible y con la tecnología que, de acuerdo a sus recursos y
        posibilidades técnico-económicos, se considere apropiada. NUCBA se
        reserva el derecho de cambiar la forma en la que presta los Servicios,
        comprometiéndose a informar a los Usuarios con la mayor anticipación
        posible. <br /> <br /> Condiciones de Uso. <br /> NUCBA podrá retirar o
        suspender, cautelarmente, la prestación del servicio a aquellos Usuarios
        que incumplan lo establecido en estos Términos de Uso. LA INSTITUCIÓN se
        reserva el derecho de admisión del INGRESANTE; por lo que la inscripción
        en un ciclo lectivo no obliga necesariamente a LA INSTITUCIÓN a su
        admisión en un ciclo sucesivo o cualquier otro que se dicte con
        posterioridad.- <br />
        <br /> Responsabilidades del Usuario (INGRESANTE).
        <br /> El Usuario se compromete a utilizar el servicio de conformidad
        con la legislación vigente, la moral, las buenas costumbres y el orden
        público, así como a abstenerse de utilizar el Servicio con fines o
        efectos ilícitos, prohibidos en los presentes Términos de Uso de los
        Servicios, lesivos de los derechos e intereses de terceros, o que de
        cualquier forma puedan dañar, inutilizar, sobrecargar o deteriorar los
        Servicios, los equipos informáticos de otros Usuarios o de otros
        usuarios de Internet (hardware y software) así como los documentos,
        archivos y toda clase de contenidos almacenados en sus equipos
        informáticos (hacking), o impedir la normal utilización o disfrute de
        dichos Servicios, equipos informáticos y documentos, archivos y
        contenidos por parte de los demás Usuarios y de otros usuarios.
        <br /> EL INGRESANTE se compromete a abonar mensualmente, en concepto de
        arancel por servicios de LA INSTITUCIÓN, la suma acordada entre las
        partes en su totalidad.-
        <br /> <br /> El servicio educativo brindado por LA INSTITUCIÓN es
        indivisible, ya que los aranceles se establecen considerando toda la
        enseñanza correspondiente al curso objeto de este contrato. EL
        INGRESANTE reconoce que la obligación de pago es única, aun cuando pueda
        cancelarla en cuotas.- <br /> Por esta razón bajo ninguna circunstancia,
        EL INGRESANTE podrá solicitar o pretender que se le exima de cumplir
        cualquier parte de la obligación de pago asumida; sin importar para ello
        el hecho de que EL INGRESANTE perdiere su condición de regular o dejare
        de concurrir a la institución.- <br />
        Seguridad y Privacidad. <br /> El Usuario otorga su consentimiento
        informado de las siguientes cuestiones: El acceso a la información del
        Usuario queda restringido al propio Usuario mediante el uso de su
        “palabra clave” o contraseña. NUCBA no venderá ningún tipo de
        información a entidades externas ni utilizará datos de los estudiantes
        con fines publicitarios. Únicamente utilizará datos con fines educativos
        autorizados. <br /> Cuando usted visita nuestro Website o pulsa
        cualquier hipervínculo que aparece en ella, o usa uno o más de nuestros
        servicios, podremos usar una tecnología industrial llamada “cookies” la
        cual almacena cierta información en su computadora y que nos permitirá
        personalizar su experiencia para alinearla con sus intereses y
        preferencias o simplemente facilitar su acceso al usar nuestros
        servicios. La mayoría de los Navegadores permitirán que usted elimine o
        bloquee las “cookies” de su computador o su disco duro o alertará las
        mismas cuando estas se activen. Por favor refiérase a las instrucciones
        de su Navegador para ayuda o para conocer mejor sus funciones. Por favor
        note, sin embargo, que si usted bloquea de su Navegador el uso de estos
        cookies, el servicio no podrá ser utilizado o no podrá acceder a nuestra
        Website o quizás pueda afectar el funcionamiento de la página como tal.
        <br /> <br /> Protección de Datos Personales. <br /> El Usuario tiene la
        facultad de ejercer el derecho de acceso a sus datos personales en forma
        gratuita en intervalos no inferiores a seis meses, salvo que acredite un
        interés legítimo al efecto conforme lo establecido en el Art. 14, Inc. 3
        de la Ley de Protección de Datos Personales N° 25.326. La Dirección
        Nacional de Protección de Datos Personales, Órgano de Control de la
        citada ley, tiene la atribución de atender las denuncias y reclamos que
        se interpongan con relación al incumplimiento de las normas sobre
        protección de datos personales.
        <br /> Servicios de terceros con los cuales compartimos información del
        usuario. <br />
      </p>
      <br />
      <ul>
        Estadísticas y anuncios
        <li>Google Analytics</li>
        <li>Facebook Analytics</li>
        <li>Twitter Analytics</li>
        <li>Facebook Ads</li>
        <li>Google Ads</li>
      </ul>
      <br />
      <ul>
        Datos personales
        <li>Cookies</li>
        <li>Datos de uso</li>
      </ul>
      <br />
      <ul>
        Datos personales
        <li>
          Distintas clases de Datos, según se especifica en los términos de uso
          del servicio-producto.
        </li>
      </ul>
      <br />
      <ul>
        Procesamiento de pagos
        <li>Mercadopago</li>
        <li>Paypal</li>
        <li>Transferencias bancarias.</li>
      </ul>
      <br />
      <span>
        Para una mejor comprensión, puede visitar los términos de uso de
        <LinkChakra
          href='https://www.mercadopago.com.ar/ayuda/terminos-y-condiciones_299'
          isExternal
          color='nucba.primary'
        >
          {' '}
          Mercadopago
        </LinkChakra>{' '}
        o{' '}
        <LinkChakra
          href='https://www.paypal.com/ar/webapps/mpp/ua/useragreement-full?locale.x=es_AR'
          isExternal
          color='nucba.primary'
        >
          {' '}
          Paypal
        </LinkChakra>
        .
      </span>
      <p>
        {' '}
        <br />
        LA INSTITUCIÓN podrá divulgar cierta información personal de los
        Usuarios, cuando crea de buena fe que esa divulgación resulte
        razonablemente necesaria para: a) evitar una responsabilidad legal; b)
        cumplir una exigencia legal, tal como una orden de allanamiento, una
        citación o una orden judicial; c) cumplir un requerimiento de una
        autoridad gubernamental o reguladora; y/o d) proteger los derechos,
        propiedad o seguridad de LA INSTITUCIÓN, de los Usuarios, o de un
        tercero.
      </p>
      <br />
      <Heading as='h2'>Derechos de Propiedad Intelectual e Industrial.</Heading>
      <p>
        La propiedad intelectual del código desarrollado durante la etapa
        educativa pertenece exclusivamente a quien lo creó.
        <br /> <br />
        INSCRIPCIÓN A LOS CURSOS <br />
        Los siguientes Términos de Uso aplican sin excepción a todo
        alumno/alumna (Ingresante) que se inscribe en los cursos dictados por
        NUCBA. Es responsabilidad del inscripto informarse sobre los términos y
        condiciones del servicio y la utilización de éste, así como también, los
        servicios que se ofrecen implican su aceptación. NUCBA se reserva el
        derecho de pedir a un alumno que abandone el curso si, en su única y
        exclusiva opinión, no cumple con lo dispuesto por los términos y
        condiciones. El Usuario no tendrá derecho a ningún tipo de acción y/o
        reclamo en tal sentido, renunciando a través del presente a efectuar las
        mismas. <br /> <br />
        IMPORTANTE: Los cursos/carreras/bootcamps son transferibles siempre y
        cuando no se haya iniciado la cursada y se avise con anticipación, al
        menos 7 (Siete) días hábiles.
        <br /> <br />
        EL INGRESANTE tendrá como requisito esencial la disposición de un
        dispositivo electrónico, con acceso a internet, que le permita adquirir
        todos los conocimientos y cumplir con los objetivos del curso. <br />{' '}
        <br /> FECHAS DE CURSADA Y RETRASOS <br /> Sin perjuicio de ello, el
        alumno/alumna (INGRESANTE) declara conocer y acepta que la fecha de
        inicio informada DEL curso o carrera, de modo excepcional podría
        retrasarse en caso de no haberse llegado al cupo mínimo necesario de
        Estudiantes para dar inicio a la cursada, sin que ello genere derecho a
        reclamo alguno por parte del Estudiante. Excepcionalmente, si la
        modificación efectuada por NUCBA tornara imposible la cursada por parte
        del Estudiante, éste podrá solicitar a NUCBA una nueva modificación a
        efectos de concretar la cursada de la Carrera. <br /> <br /> CUPOS
        LIMITADOS <br /> Los cupos a los cursos son limitados. El cupo al curso
        se confirma y se reserva únicamente habiendo realizado el pago del mismo
        por cualquiera de los medios habilitados a tal fin. El cupo del curso se
        cerrará cuando se completen las vacantes del mismo. <br /> <br />{' '}
        PROCESO DE INSCRIPCIÓN <br /> En todos los casos la misma deberá
        efectuarse vía web en www.nucba.com.ar o contactándose con un asesor vía
        mail (info@nucba.com.ar). Deberá completar el registro correspondiente y
        en tanto haya vacantes, efectuar al menos un pago por adelantado de la
        cuota o total del curso, según corresponda, utilizando las distintas
        plataformas de pago disponibles a tal fin. Una vez completada la
        inscripción, recibirá un mail de bienvenida con el detalle de la
        inscripción e instrucciones necesarias para continuar. <br /> <br /> LA
        INSTITUCIÓN reconocerá todos los pagos realizados por EL INGRESANTE con
        anterioridad a la suscripción de este contrato; los mismos se imputarán
        como saldo a favor de las primeras cuotas pertenecientes al curso
        contratado.- <br /> El alumno podrá inscribirse únicamente a un curso o
        carrera que ya inició antes de que inicie su cuarta clase. El alumno se
        hace completamente responsable de no comprender contenido del curso por
        su iniciar en dicha instancia. <br /> En el caso de pago de
        CARRERA/BOOTCAMP, la fecha de inicio del siguiente nivel al que se
        inscriba, no podrá superar los dos (2) meses de finalizado el anterior y
        deberá tener la cuota al día. En caso que desee cambiar la fecha de
        algún nivel de la carrera, lo podrá hacer hasta cinco (5) días previos
        al comienzo del curso. En caso contrario, abonará una penalidad
        informada al momento de solicitar el cambio.
        <br /> <br />
        Las oficinas administrativas de NUCBA se encuentran en Mariano Moreno
        15, Provincia de Buenos Aires. Trabajamos de forma remota, por lo que
        debemos coordinar citas en nuestro espacio para poder atender de forma
        presencial. Las cancelaciones de las citas podrán realizarse hasta 48hs
        antes de la fecha estipulada. <br /> <br />
        Entrega de proyecto final <br />
        El plazo de tiempo máximo para cargar el proyecto final a la plataforma
        es de 20 días de corrido de finalizado el módulo del curso-carrera o el
        mismo en su totalidad. El/la docente puede corregir el proyecto dentro
        de este plazo y hasta 10 días después de finalizado este plazo de 20
        días. En total, el/la docente tiene 30 días de finalizado el curso para
        realizar las correcciones. <br /> <br />
        Políticas de Devolución o Reembolsos <br />
        LA INSTITUCIÓN no realizará devoluciones de importes abonados por el
        curso a dictarse, ya fuere esto por inconvenientes personales; mala
        interpretación de los contenidos académicos; de agenda, o por cualquier
        otro impedimento o razón que se pudiera aludir. En tales casos EL
        INGRESANTE tendrá un saldo a favor que podrá usar en cualquiera de los
        cursos; tanto presenciales como a distancia; a dictarse en el plazo de 1
        año.- <br /> <br /> Baja <br /> Podrás darte de baja de nuestros
        servicios o productos siguiendo los pasos detallados a continuación.
        <br /> Deberás escribirnos a secretaria@nucba.com.ar <br />
        EL INGRESANTE deberá comunicar fehacientemente a LA INSTITUCIÓN, con una
        antelación mínima de treinta días y por escrito, la solicitud de baja
        del alumno.- Recíprocamente LA INSTITUCIÓN quedará comprometida a
        comunicar con 30 días de anticipación la baja del alumno por
        incumplimiento de cualquiera de las cláusulas contenidas en el contrato
        o del régimen de convivencia anexo.-
        <br /> <br /> ON DEMAND <br />
        El Estudiante tendrá acceso a través del Sitio, Sistemas o Plataformas
        Educativas, durante la duración de la modalidad pautada previamente a la
        cual se inscribió (en adelante, el “Plazo de Cursada”), a la Plataforma,
        desde la cual podrá acceder a las clases grabadas (modalidad ondemand),
        proyectos prácticos y documentación puestos a disposición por NUCBA (en
        adelante, el “Material”). <br /> <br />
        LA INSTITUCIÓN no realizará devoluciones de importes abonados en las
        modalidades ondemand, ya fuere esto por inconvenientes personales; mala
        interpretación de los contenidos académicos; de agenda, o por cualquier
        otro impedimento o razón que se pudiera aludir. <br />
      </p>
      <br />
      <Heading as='h2'>Acceso a la Plataforma. Plazos.</Heading>
      <br />
      <p>
        El Estudiante podrá acceder al Material desde la Plataforma en cualquier
        momento durante el Plazo Cursada ingresando con su usuario y clave.{' '}
        <br /> A su vez, todo Estudiante que haya entregado la totalidad de los
        Proyectos y haya pagado la totalidad del Servicio tendrá acceso a la
        Plataforma y sus contenidos por un plazo adicional de 1 (un) año una vez
        finalizado el Plazo de Cursada. <br /> Todo Estudiante que no haya
        entregado la totalidad de los Proyectos antes de finalizado el Plazo de
        Cursada perderá su acceso a la Plataforma debiendo en tal caso
        comunicarse con NUCBA enviando un mail a secretaria@nucba.com.ar para
        solicitar una extensión de un mes que tendrá un costo del 45% (cuarenta
        y cinco por ciento) del valor mensual de la Carrera suscripta por el
        Estudiante. <br /> El Estudiante podrá encontrar en la Plataforma la
        grabación de las clases de la Carrera a la cual se haya inscripto
        durante 90 (noventa) días posteriores a la misma para su descarga.
        Transcurridos los 90 (noventa) días, la clase será eliminada y no podrá
        volver a ser accedida por el Estudiante.
        <br /> <br />
        PAGO DE LOS CURSOS
        <br /> Locales de pago habilitados Las oficinas administrativas de NUCBA
        se encuentran en Mariano Moreno 15, Provincia de Buenos Aires, de Lunes
        a Viernes de 10:30 a 17:30hs. En Mercadopago y con tarjeta de crédito,
        presionando sobre el botón disponible en el cupón de pago o QR enviado
        por nuestro equipo de ventas o directamente en la página web (aplicable
        al valor total del curso).
        <br />
        Transferencia bancaria solicitando sus datos a secretaria@nucba.com.ar o
        al teléfono de Whatsapp de Venta con acceso desde la página web{' '}
        <LinkChakra href='www.nucba.com.ar' isExternal color='nucba.primary'>
          (www.nucba.com.ar)
        </LinkChakra>
        .- <br />
        LA INSTITUCIÓN, se reserva el derecho de tomar las medidas judiciales y
        extrajudiciales que estime pertinentes en los casos en que se registren
        deudas por parte del Estudiante. Pago con tarjeta de crédito y débito:
        LA INSTITUCIÓN autoriza a recurrir a un tercero para procesar los pagos
        y acepta la divulgación de su información de pago a dicho tercero. El
        titular de la tarjeta es el responsable por los datos consignados al
        momento de la inscripción al Servicio y es el único obligado al pago
        frente al emisor de la misma. Cualquier desconocimiento deberá ser
        efectuado frente del Banco emisor de la tarjeta de conformidad con lo
        dispuesto por la Ley de Tarjetas de Crédito Nº 25.065 y/o la regulación
        aplicable según el territorio. <br /> Información de pago. El Estudiante
        deberá mantener actualizada su información de contacto y la información
        de facturación y de tarjeta de crédito o débito (según corresponda). Las
        obligaciones de pago no pueden cancelarse y todas las cantidades
        abonadas son no reembolsables. <br /> <br />
        DEVOLUCIÓN LA INSTITUCIÓN <br /> no realizará devoluciones de importes
        abonados por el curso a dictarse, ya fuere esto por inconvenientes
        personales; mala interpretación de los contenidos académicos; de agenda,
        o por cualquier otro impedimento o razón que se pudiera aludir. En tales
        casos EL INGRESANTE tendrá un saldo a favor que podrá usar en cualquiera
        de los cursos; tanto presenciales como a distancia; a dictarse en el
        plazo de 1 año.- <br /> <br />
        POLÍTICAS DE CANCELACIÓN, BAJA Y DEVOLUCIÓN DE CURSOS Y CARRERAS <br />
        Toda baja o solicitud de recursada por parte del alumno (INGRESANTE)
        deberá ser comunicada al mail secretaria@nucba.com.ar indicando los
        motivos. <br /> Solicitud de baja desde el inicio del curso hasta el fin
        del mismo:
        <br /> Lo abonado no es reembolsable.
        <br /> <br />
        Si el alumno necesita suspender el curso/carrera y solicita recursar en
        otra camada deberá abonar las cuotas faltantes a su plan si las tuviera
        (uso en el lapso de 1 año a partir de la misma), y podrá incorporarse a
        otro curso siempre y cuando haya vacantes libres de alumnos nuevos
        (quienes tendrán prioridad).
        <br /> El costo será anunciado por NUCBA al momento de iniciar el
        trámite. <br /> <br /> CERTIFICADOS <br /> Una vez finalizado el ciclo
        lectivo y cumplida la obligación del INGRESANTE de entregar el proyecto
        integrador que determinaría la culminación del curso contratado; LA
        INSTITUCIÓN se comprometerá a otorgar un certificado digital que refleje
        la finalización del curso objeto de este contrato.-
        <br /> <br />
        PAGO DE CUOTAS <br />
        Al elegir la opción de pagar en cuotas el alumno/alumna (EL INGRESANTE)
        está asumiendo el compromiso por el valor total del curso o carrera y es
        su responsabilidad pagar todas las cuotas sin excepción enviando el
        comprobante a la cuenta secretaria@nucba.com.ar o al celular de Alumnos
        brindado una vez realizada la inscripción.
        <br /> <br /> EL INGRESANTE se compromete a abonar cada mes por
        adelantado, mediante débito automático, link de pago, Transferencia
        Bancaria o código QR el arancel correspondiente a los servicios
        educativos de LA INSTITUCIÓN.- <br /> <br />
        El servicio educativo brindado por LA INSTITUCIÓN es indivisible, ya que
        los aranceles se establecen considerando toda la enseñanza
        correspondiente al curso objeto de este contrato. <br /> EL INGRESANTE
        reconoce que la obligación de pago es única, aun cuando pueda cancelarla
        en cuotas.- <br /> Si necesita posponer la cursada para otro momento
        (ver condiciones baja de la cursada) esto no lo habilita para posponer
        los pagos de sus cuotas. <br /> Así también, deberá cancelar todas las
        cuotas en caso de discontinuar el curso o carrera; el cual podrá
        concluir en el momento que lo decida, bajo las condiciones contratadas
        al momento de la inscripción. <br /> EL INGRESANTE se compromete a
        abonar mensualmente, en concepto de arancel por servicios de LA
        INSTITUCIÓN, la suma acordada entre las partes.- <br /> En caso de mora
        en el pago, si la cuota mensual correspondiente se abonara con
        posterioridad a la fecha de su vencimiento, LA INSTITUCIÓN cobrará un
        recargo de $350,00 por cada cuota vencida, siempre que el pago sea
        realizado dentro del mes.- Si el pago se efectuare a partir del mes
        siguiente, se cobrará además de lo estipulado precedentemente, un
        recargo del 3 % mensual, calculado sobre el valor de la cuota impaga
        desde su vencimiento hasta el día de su efectivo pago.-
        <br /> <br /> En caso de mantener incumplimiento de pago, LA INSTITUCIÓN
        notificará a las entidades financieras y de crédito correspondientes la
        situación de deuda y morosidad.
        <br /> <br />
        Fechas de pago <br /> <br />
        Cuota 1 o más: Entre el 1 y el 5 del mes (días corridos y hábiles) en
        forma mensual y consecutiva. <br />
        <br /> Información Financiera. El Usuario presta su consentimiento para
        que sus datos personales relacionados con las operaciones crediticias
        que celebre con LA INSTITUCIÓN y su nivel de cumplimiento sean
        inmediatamente registrados en su base de datos, informados al Banco
        Central de la República Argentina y a cualquier otro ente o empresa que
        se dedique a proveer bases de datos de antecedentes comerciales y
        financieros, y compartidos con sus accionistas con la finalidad de que
        éstos puedan evaluar y decidir sobre eventuales otorgamientos de
        créditos y otros productos financieros. <br /> Autorización para débito
        en cuenta y Débito Inmediato (DEBIN). La aceptación del Usuario de la
        presente Oferta de Préstamo implicará la autorización de que todo
        importe adeudado del Préstamo pueda debitarse de la siguiente cuenta
        titularidad del Usuario, o la que indique en el futuro: Banco:
        [completar]. Tipo de cuenta: [completar] CBU: [completar]. En caso de
        que la cuenta fuera cerrada o deje de ser utilizada en forma habitual,
        el Usuario se compromete a informar a LA INSTITUCIÓN los datos de la
        nueva cuenta bancaria a los efectos de reemplazar a la cuenta consignada
        anteriormente y se deja expresa autorización para debitar los importes
        adeudados en esta o en cualquier cuenta del sistema financiero en la que
        el cliente fuese titular, pudiendo LA INSTITUCIÓN realizar todas las
        averiguaciones necesarias a tal efecto. <br />
        ANEXO I: “REGIMEN DE CONVIVENCIA Y CONDUCTA DE ALUMNOS” <br /> <br />
        El régimen de convivencia está organizado en función de que los
        estudiantes adhieran a los valores universales, reconozcan los actos que
        perjudican la convivencia con otros y asumir las consecuencias de los
        mismos.-
        <br />
      </p>
      <br />
      <ol>
        <li>
          EL INGRESANTE deberá entablar un trato respetuoso frente a sus
          profesores y compañeros que posibilite una armonía necesaria para que
          todos los alumnos, al concluir el tiempo estipulado de concurrencia al
          ciclo o nivel, alcancen los máximos logros posibles en los diversos
          aspectos que configuran una formación de calidad.-
        </li>
        <li>
          Ante la presencia de desacuerdos o problemáticas entre alumnos, o de
          estos con sus profesores, deberán ser resueltas de manera amigable
          preservando la armonía existente en el curso.-
        </li>
        <li>
          Ante la existencia de discriminaciones y/o problemáticas originadas
          por diferencias de género; en tales casos; la institución se reserva
          todas las medidas que considere necesarias para palear aquellas
          situaciones, incluyendo la expulsión.-
        </li>
        <li>
          EL INGRESANTE deberá respetar los horarios y las indicaciones dadas
          por los profesores y autoridades para la entrada, salida y recreos.-
        </li>
        <li>
          EL INGRESANTE velara por la conservación del edificio escolar, las
          instalaciones, los muebles y/o material didáctico. Ante el deterioro
          intencional el causante financiara la reparación o reposición de lo
          destruido.-
        </li>
        <li>
          EL INGRESANTE se desplazara con precaución y cuidado por los espacios
          comunes para proteger su integridad física, como también de aquellos
          que se encuentren presentes y de los elementos de uso común y/o
          propio. Es por ello que LA INSTITUCIÓN se exime de responsabilidad por
          cualquier lesión o daño ocasionados en el ínterin temporal en que se
          desarrollen las clases presenciales.-{' '}
        </li>
        <li>
          LA INSTITUCIÓN no se hará responsable por la perdida, deterioro u
          extravío de los elementos personales de los alumnos.-
        </li>
        <li>
          En los casos de incumplimiento por parte de EL INGRESANTE de
          cualquiera de las cláusulas de convivencia previstas en este anexo; LA
          INSTITUCIÓN podrá decretar la inmediata expulsión del INGRESANTE sin
          mayor fundamento. En tales casos EL INGRESANTE no podrá solicitar
          resarcimiento económico alguno.
        </li>
      </ol>
      <br />
      <p>
        MARCA, PUBLICIDAD Y PROPIEDAD INTELECTUAL <br /> <br />
        Todos los elementos, incluyendo contenidos audiovisuales, las imágenes,
        textos, ilustraciones, íconos, logo e isotipos, fotografías, programas,
        animaciones, cualquier música, melodía, video clip y cualquier otro
        elemento que forma parte del sitio web o material de identidad de marca,
        sólo tiene como fin la comercialización de servicios y productos por
        parte de NUCBA, y se encuentra prohibida cualquier reproducción,
        modificación o distribución de los mismos.
        <br />
        De igual modo,todos los comentarios de terceros que sean publicadas en
        la página web de LA INSTITUCIÓN o que sean emitidos durante la
        realización de un curso o carrera, serán de exclusiva responsabilidad de
        sus autores, eximiendo éstos de total responsabilidad a NUCBA, y
        obligándose a mantenerla indemne de toda acción y/o reclamo que pudiera
        recibir como consecuencia de los mismos.
        <br />
      </p>
      <br />
      <Heading as='h2'>Uso y Garantía del Sitio y/o Servicio.</Heading>
      <br />
      <p>
        NUCBA no garantiza la inexistencia de eventuales dificultades técnicas o
        fallas en los sistemas, plataformas utilizadas o en internet. NUCBA no
        garantiza el acceso y uso continuado del Sitio Web al igual que el
        contenido realizado de las clases en formato audiovisual o plataforma
        virtual educativa. <br /> El sistema puede no estar disponible debido a
        dificultades técnicas o fallas de Internet o por cualquier otra
        circunstancia ajena a LA INSTITUCIÓN. De forma consecuente, NUCBA
        procurará restablecerlo con la mayor celeridad posible. <br /> Daños y
        Perjuicios El alumno/alumna (INGRESANTE) deberá indemnizar a NUCBA por
        todos los daños y perjuicios ocasionados como resultado de la provisión
        de información falsa o del incumplimiento de cualquiera de las
        obligaciones del INGRESANTE previstas en este Contrato, incluyendo todos
        los gastos y honorarios que origine, en caso de mora, su cobranza
        judicial o extrajudicial. <br />
        EL INGRESANTE desde ya declara conocer, aceptar y asumir los eventuales
        riesgos derivados de este tipo de modalidad contractual, ya sea riesgos
        de que terceros no autorizados accedan a la información correspondiente
        a sus datos, cumplimientos de pago, riesgos derivados de la
        indisponibilidad de las Plataformas debido a dificultades técnicas o
        fallas de Internet en los links u otras herramientas que afecten el
        proceso de adquisición del servicio/producto solicitado.
        <br /> <br /> DOMICILIO, LEY APLICABLE Y JURISDICCIÓN <br /> Cualquier
        controversia surgida de la interpretación o ejecución del presente
        contrato se interpretará conforme a la legislación argentina. Las partes
        acuerdan que todo litigio, discrepancia o reclamación del presente
        contrato se resolverá ante los tribunales de la Ciudad Autónoma de
        Buenos Aires, renunciando las partes a cualquier otro fuero o
        jurisdicción que pudiera corresponder.-. <br />
        En prueba de conformidad se firmaran dos ejemplares de un mismo tenor y
        al solo efecto de su implementación, en la Provincia de Buenos Aires,
        Localidad de La Matanza, Ciudad de Ramos Mejía.
      </p>
    </>
  )
}

export default TyCText
