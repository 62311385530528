import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Stack, Text } from '@chakra-ui/react'

import { ProductItem } from './ProductItem'
import { Collapse } from '../Collapse'
import { clearCohort, useCartState } from '@/inscription/features/cart.slice'
import { PRODUCTS_TYPES } from '@/shared/types'
import { serializeProductCohort } from '@/inscription/utils'
import { useGetCohortProductCoursesQuery } from '@/inscription/services/inscriptionQuery.service'
import { DateTime } from '@/shared/helpers'
import { PageHeading } from '../PageHeading'
import { useAppDispatch } from '@/shared/app/hooks'

const CartContainerWithScroll = styled(Stack)`
  padding-right: 1rem;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #17181e;
    border-radius: 30px;
  }
`

export const ProductSummary = () => {
  const dispatch = useAppDispatch()
  const { product, cohort } = useCartState()

  const { data, isLoading, isSuccess, isFetching } =
    useGetCohortProductCoursesQuery(
      { code: cohort?.code ?? '', productId: product?.id ?? '' },
      { skip: !cohort || !product },
    )

  useEffect(() => {
    if (isSuccess && data && !isFetching) {
      const cohortMatchWithProduct = data._product_courses.every(
        ({ course }) => (course.classes.at(0)?.cohort_classes ?? []).length > 0,
      )

      if (!cohortMatchWithProduct) {
        dispatch(clearCohort())
      }
    }
  }, [isSuccess, data, isFetching])

  const serializedProductCohort = cohort && serializeProductCohort(cohort)

  return (
    <Stack
      w={{ base: '90%', md: '60%' }}
      spacing={5}
      minH='300px'
      display={{ base: 'flex', md: 'block' }}
      alignSelf='end'
    >
      <PageHeading
        title='Resumen'
        as='h2'
        fontSize='2xl'
        display={{ base: 'none', md: 'block' }}
      />

      {!product ? (
        <Text w='92%' textAlign='center'>
          No hemos encontrado productos seleccionados 🥲
        </Text>
      ) : (
        <>
          {product.type === PRODUCTS_TYPES.ON_DEMAND ? (
            <Text fontWeight={600} fontSize={{ base: 'xs', md: 'lg' }}>
              {product.name}
            </Text>
          ) : (
            <Text fontWeight={600} fontSize={{ base: 'xs', md: 'lg' }}>
              {PRODUCTS_TYPES[product.type!]}: {product.name}
            </Text>
          )}

          <Stack>
            {cohort && (
              <>
                {!(product.type === PRODUCTS_TYPES.ON_DEMAND) && (
                  <>
                    <ProductItem
                      title='1er Fecha'
                      content={serializedProductCohort!.startDate}
                    />
                    <ProductItem
                      title='Días'
                      content={serializedProductCohort!.days}
                    />
                  </>
                )}
              </>
            )}
          </Stack>

          <Stack justify='flex-end' align='end'>
            <CartContainerWithScroll h='180px' w='100%' spacing={5}>
              {!cohort ? (
                product.type === PRODUCTS_TYPES.ON_DEMAND ? (
                  <Text textAlign='center'>
                    Seleccione la modalidad de cursada
                  </Text>
                ) : (
                  <Text textAlign='center'>
                    Seleccione una fecha de cursada
                  </Text>
                )
              ) : isLoading ? (
                <Text>Cargando cursos...</Text>
              ) : (
                data!._product_courses.map(({ course }) => (
                  <Collapse key={course.id} title={course.name}>
                    {product.type === PRODUCTS_TYPES.ON_DEMAND ? (
                      <>
                        <ProductItem title='Modalidad' content='Asíncrona' />
                        <ProductItem title='Acceso' content='Al instante' />
                      </>
                    ) : (
                      <>
                        {' '}
                        <ProductItem
                          title='1er Fecha'
                          content={DateTime.createFromString(
                            course.classes[0].cohort_classes[0]?.date,
                            false,
                          ).formatDate({ dateStyle: 'long' })}
                        />
                        <ProductItem
                          title='Días'
                          content={serializedProductCohort!.days}
                        />
                        <ProductItem title='Modalidad' content='En vivo' />
                      </>
                    )}
                  </Collapse>
                ))
              )}
            </CartContainerWithScroll>
          </Stack>
        </>
      )}
    </Stack>
  )
}
