import { Box } from '@chakra-ui/react'
import React from 'react'

export const SidebarBackOverlay = ({
  toogleSidebar,
}: {
  toogleSidebar: () => void
}) => {
  return (
    <Box
      onClick={toogleSidebar}
      position='fixed'
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={99}
      backdropFilter={'blur(2px)'}
      bgColor='rgba(0,0,0,0.5)'
    />
  )
}
