import React from 'react'
import { Stack, Text } from '@chakra-ui/react'

import { SUBSCRIPTIONS_STATUS_COLOR } from '@/shared/constants'
import { COHORT_TYPE, CohortProduct, SUBSCRIPTIONS_TYPES } from '@/shared/types'
import { statusLabels } from './SubscriptionCard'
import { serializeProductCohort } from '@/inscription/utils'
import { Link } from 'react-router-dom'

type DuoCardProps = {
  subscriptionOwner: string
  subscription: {
    status: SUBSCRIPTIONS_TYPES
    plan: { product: { name: string } }
  }
  cohort_integrant?: {
    cohort: CohortProduct
  }
}

export const DuoCard = ({
  subscription,
  cohort_integrant,
  ...duo
}: DuoCardProps) => {
  const cohortData =
    cohort_integrant?.cohort && serializeProductCohort(cohort_integrant.cohort)

  return (
    <Stack
      w='100%'
      minW='600px'
      border='1px solid #ccc'
      borderRadius='md'
      p={4}
    >
      <Text fontWeight='bold'>{subscription.plan.product.name}</Text>
      <Text>
        Estado:{' '}
        <Text
          as='span'
          textColor={SUBSCRIPTIONS_STATUS_COLOR[subscription.status]}
        >
          {statusLabels[subscription.status]}
        </Text>{' '}
      </Text>
      {!cohort_integrant && (
        <Text fontWeight='bold' color='#ffa100'>
          Estudiante no generado
        </Text>
      )}
      (
      <Text fontWeight='bold'>
        Suscripción compartida con:{' '}
        <Text as='span' fontWeight='normal'>
          {duo.subscriptionOwner}
        </Text>
      </Text>
      {cohort_integrant?.cohort &&
        (cohort_integrant.cohort.type === COHORT_TYPE.ON_DEMAND ? (
          <Text fontWeight='bold'>
            Cursando en la camada:{' '}
            <Text
              as={Link}
              to={`/camada/${cohortData?.code}`}
              fontWeight='normal'
              _hover={{ color: 'nucba.primary', textDecor: 'underline' }}
            >
              #{cohortData?.code}
            </Text>
          </Text>
        ) : (
          <Text fontWeight='bold'>
            {cohortData?.code} | {cohortData?.days}
          </Text>
        ))}
    </Stack>
  )
}
