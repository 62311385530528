import React, { useState, useEffect } from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'

import { AssignmentLayout } from '@/container/AssignmentLayout'
import { useStudent } from '@/shared/hooks'
import { useGetAllAssignmentsByStudentQuery } from '@/education/assignments/services/assignment.service'
import { useAppSelector } from '@/shared/app/hooks'
import { sessionState } from '@/shared/features/session.slice'
import { ApplicationLoader } from '@/shared/components/loaders/ApplicationLoader'

export const AssignmentRoot = () => {
  const { assignmentId, code } = useParams()
  const { state } = useLocation()

  const cameFromApp = state && state.fromApplication

  const { studentId } = useStudent(code!)

  const { isStaff } = useAppSelector(sessionState)

  const [assignmentBelongToStudent, setAssignmentBelongToStudent] = useState<
    boolean | 'isVerifying'
  >(cameFromApp ? cameFromApp : 'isVerifying')

  const { data, isLoading, isSuccess } = useGetAllAssignmentsByStudentQuery(
    {
      studentId,
    },
    { skip: isStaff || assignmentBelongToStudent === true },
  )

  useEffect(() => {
    if (isSuccess) {
      const assignmentsIDs = data.users_assignments.map(
        assignment => assignment.id,
      )

      const isAllowed = assignmentsIDs.includes(assignmentId!)

      setAssignmentBelongToStudent(isAllowed)
    }
  }, [isSuccess])

  if (!isStaff) {
    if (isLoading || assignmentBelongToStudent === 'isVerifying') {
      return <ApplicationLoader />
    }

    if (!assignmentBelongToStudent) {
      return <Navigate to='..' replace />
    }
  }

  return <AssignmentLayout />
}
