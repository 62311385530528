import React, { ReactNode } from 'react'
import {
  HStack,
  Stack,
  StyleProps,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'

type ClassCardContentProps = {
  heading: string
  description?: string
  children?: ReactNode | null
  textStyles?: StyleProps
  simplifiedVersion?: boolean
}

export const ClassCardContent = ({
  children = null,
  heading,
  description,
  textStyles,
  simplifiedVersion,
}: ClassCardContentProps) => {
  const [isSmallerThan1425] = useMediaQuery('(max-width: 1425px)')
  return (
    <HStack
      w='97%'
      minH='40px'
      justify='space-between'
      pl={simplifiedVersion ? 3 : 0}
      flexDir={simplifiedVersion && isSmallerThan1425 ? 'column' : 'row'}
      alignItems={simplifiedVersion && isSmallerThan1425 ? 'flex-start' : ''}
      gap={simplifiedVersion ? 3 : ''}
    >
      <Stack spacing={0} justifyContent={'center'} {...textStyles}>
        <Text
          fontSize={isSmallerThan1425 && simplifiedVersion ? 'small' : 'sm'}
          fontWeight={800}
        >
          {heading}
        </Text>
        {description && <Text fontSize='xs'>{description}</Text>}
      </Stack>
      {children}
    </HStack>
  )
}
