import React from 'react'
import { Box, useMediaQuery } from '@chakra-ui/react'
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom'

import { Sidebar } from '@/shared/components/sidebar/Sidebar'
import { useInLocationChange } from '@/shared/hooks/useInLocationChange'
import { ScreenNotification } from '@/shared/components/ScreenNotification'

export const RootLayout = () => {
  const [isSmallerThan1280] = useMediaQuery('(max-width: 768px)')
  const location = useLocation()

  useInLocationChange()

  const isContractPage = location.pathname.includes('/contrato')

  if (isSmallerThan1280) {
    return (
      <ScreenNotification
        title='GALILEO AÚN NO ESTÁ DISPONIBLE PARA ESTE DISPOSITIVO 🥲'
        subtitle='Seguimos trabajando para que pronto puedas usarlo donde quieras. De mientras, ingresa desde tu computadora 💻 💪'
        imgWidth='40%'
      />
    )
  }
  return (
    <>
      {!isContractPage && <Sidebar />}

      <Box as='main' width='calc(100vw - 100px)' ml='auto'>
        <Outlet />
      </Box>

      <ScrollRestoration />
    </>
  )
}
