import React, { ReactNode } from 'react'
import { StyleProps, Text } from '@chakra-ui/react'

type PageHeadingProps = {
  children: ReactNode
} & StyleProps

export const PageHeading = ({ children, ...rest }: PageHeadingProps) => {
  return (
    <Text as='h2' fontSize='2xl' fontWeight={700} {...rest}>
      {children}
    </Text>
  )
}
