import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Box,
  Grid,
  HStack,
  Heading,
  Show,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react'

import { PageHeading } from '../components/PageHeading'
import { Summary } from '../components/summary/Summary'
import { SelectPaymentMethod } from '../components/SelectPaymentMethod'

import { PageContainer } from '../container/PageContainer'
import {
  campaignSelected,
  paymentMethodSelected,
  planSelected,
  productSelected,
  useCartState,
} from '../features/cart.slice'
import { useNucbaTheme } from '@/shared/hooks'
import { SelectCohort } from '../components/SelectCohort'
import {
  useGetNucbaPaymentMethodQuery,
  useGetNucbaPlanQuery,
  useGetNucbaProductQuery,
  useGetPlansWithPaymentMethodQuery,
} from '../services/inscriptionQuery.service'
import { PLANS_TYPES } from '@/shared/types'
import { PlansCardLoader } from '../components/loaders/PlansCardLoader'
import { PlansCard } from '../components/PlansCard'
import Scrollable from '@/shared/components/Scroleable'
import { useAppDispatch } from '@/shared/app/hooks'
import styled from '@emotion/styled'

export const SectionWithScroll = styled(VStack)`
  max-height: 50vh;
  margin: 0.5rem 0;
  padding-block: 1rem;
  padding-right: 1rem;
  padding-left: 0.5rem;

  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    margin-left: 3rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #17181e;
    border-radius: 30px;
  }
`

export const Plans = () => {
  const navigate = useNavigate()
  const { colors } = useNucbaTheme()
  const dispatch = useAppDispatch()
  const cart = useCartState()

  const [searchParams] = useSearchParams()

  // VALIDACIÓN PRODUCTO
  const productParam = searchParams.get('product')

  const { data: productData, isLoading: fetchingProduct } =
    useGetNucbaProductQuery(
      { id: productParam! },
      { skip: !productParam || cart.product?.id === productParam },
    )

  useEffect(() => {
    if (!cart.product && !productData && !fetchingProduct) {
      navigate('/inscripcion')
    }

    if (productData) {
      dispatch(productSelected(productData))
    }
  }, [cart.product, productData, fetchingProduct])

  // VALIDACIÓN PLAN
  const planParam = searchParams.get('plan')

  const { data: planData } = useGetNucbaPlanQuery(
    { id: planParam! },
    { skip: !planParam || cart.plan?.id === planParam },
  )

  useEffect(() => {
    if (planData) {
      dispatch(
        planSelected({
          ...planData,
          paymentMethod: { name: planData.paymentMethod.name },
        }),
      )
      if (planData?._plans_campaigns.length > 0) {
        dispatch(campaignSelected(planData?._plans_campaigns[0]))
      }
    }
  }, [planData])

  // VALIDACIÓN MEDIO DE PAGO
  const paymentMethodParam = searchParams.get('payment')

  const { data: paymentMethodData } = useGetNucbaPaymentMethodQuery(
    {
      id: paymentMethodParam!,
    },
    {
      skip:
        !paymentMethodParam || cart.paymentMethod?.id === paymentMethodParam,
    },
  )

  useEffect(() => {
    if (paymentMethodData) {
      dispatch(
        paymentMethodSelected({
          ...paymentMethodData,
          policy: paymentMethodData.paymentPolicy,
        }),
      )
    }
  }, [paymentMethodData])

  // FETCH PLANES
  const { data: plans, isLoading } = useGetPlansWithPaymentMethodQuery(
    {
      productId: cart.product?.id ?? '',
      paymentMethodId: cart.paymentMethod?.id ?? '',
    },
    {
      skip: !cart.paymentMethod,
    },
  )

  let plansGroupedByType: Record<
    PLANS_TYPES,
    Exclude<typeof plans, undefined>
  > = {
    BECA: [],
  }

  if (plans) {
    plansGroupedByType = plans.reduce((acc, plan) => {
      plansGroupedByType[plan.type].push(plan)
      return acc
    }, plansGroupedByType)
  }

  return (
    <PageContainer>
      <Stack spacing={8} w='100%'>
        <Stack width={{ base: '100%', lg: '100%' }}>
          <PageHeading title={cart.product?.name ?? 'Producto Nucba'} />
          <SelectCohort />
          <SelectPaymentMethod />
        </Stack>

        <Stack spacing={4} width='100%'>
          <Tabs
            variant='soft-rounded'
            defaultIndex={
              cart.plan && cart.plan.type === PLANS_TYPES.BECA
                ? 0
                : cart.plan
                  ? 1
                  : 0
            }
            isLazy
            m={{ base: 'auto', md: '0' }}
          >
            <TabList gap={6} alignItems='center'>
              <HStack spacing={4} alignItems='center'>
                <Heading>Planes</Heading>

                {Object.keys(plansGroupedByType).map(planType =>
                  plansGroupedByType[planType as PLANS_TYPES].length > 0 ? (
                    <Box
                      key={planType}
                      position='relative'
                      display='inline-block'
                    >
                      <Tab
                        _selected={{ bg: 'nucba.primary', fontWeight: 'bold' }}
                        bg='nucba.black-70'
                        color='white'
                        fontWeight='medium'
                        display='inline'
                        fontSize={{ base: 'sm', md: 'md' }}
                      >
                        {planType}
                      </Tab>
                      {plans && (
                        <Box
                          position='absolute'
                          top={-2}
                          right={-2}
                          bg={colors.nucba.primaryHover}
                          color='white'
                          px={2}
                          rounded='full'
                        >
                          {plansGroupedByType[planType as PLANS_TYPES].length}
                        </Box>
                      )}
                    </Box>
                  ) : null,
                )}
              </HStack>
              {plans?.some(plan => plan.isDuo) ? (
                <Text fontWeight='bold' color='#48BB78'>
                  Incluye duos
                </Text>
              ) : null}
            </TabList>
            {!cart.paymentMethod ? (
              <HStack
                w={300}
                h={10}
                justify='center'
                borderRadius='xl'
                bg={colors.nucba['black-70']}
                mt={4}
              >
                <Text>Debes elegir un medio de pago</Text>
              </HStack>
            ) : null}
            {isLoading ? (
              <PlansCardLoader />
            ) : (
              <TabPanels>
                {plans
                  ? Object.entries(plansGroupedByType).map(
                      ([planType, plansByType]) => (
                        <TabPanel key={planType} padding={0} marginTop={4}>
                          <Grid templateColumns='repeat(auto-fit, 1fr)' gap={5}>
                            <Show above='base' below='md'>
                              {plansByType.length > 0 ? (
                                plansByType.map(plan => (
                                  <PlansCard key={plan.id} {...plan} />
                                ))
                              ) : (
                                <HStack
                                  w='100%'
                                  justify='center'
                                  borderRadius='xl'
                                  bg={colors.nucba.backgroundGradient}
                                  p={4}
                                >
                                  <Text>NO HAY PLANES {planType}</Text>
                                </HStack>
                              )}
                            </Show>
                            <Show above='md'>
                              <SectionWithScroll
                                display={{ base: 'none', md: 'block' }}
                              >
                                {plansByType.length > 0 ? (
                                  plansByType.map(plan => (
                                    <PlansCard key={plan.id} {...plan} />
                                  ))
                                ) : (
                                  <HStack
                                    w='100%'
                                    justify='center'
                                    borderRadius='xl'
                                    bg={colors.nucba.backgroundGradient}
                                    p={4}
                                  >
                                    <Text>NO HAY PLANES {planType}</Text>
                                  </HStack>
                                )}
                              </SectionWithScroll>
                            </Show>
                          </Grid>
                        </TabPanel>
                      ),
                    )
                  : null}
              </TabPanels>
            )}
          </Tabs>
        </Stack>
      </Stack>
      <>
        <Show above='base' below='md'>
          <Scrollable
            withCoupon
            withCta
            action={() => navigate('/inscripcion/crear-cuenta')}
            ctaProps={{
              isDisabled: !cart.cohort || !cart.paymentMethod || !cart.plan,
            }}
          />
        </Show>
        <Show above='md'>
          <Summary
            withCoupon
            withCta
            action={() => navigate('/inscripcion/crear-cuenta')}
            ctaProps={{
              isDisabled: !cart.cohort || !cart.paymentMethod || !cart.plan,
            }}
          />
        </Show>
      </>
    </PageContainer>
  )
}
