import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../app/hooks'
import {
  assignmentState,
  restartAssignment,
} from '@/education/assignments/features/assignment.slice'

export const useInLocationChange = () => {
  const { pathname } = useLocation()

  const dispatch = useAppDispatch()
  const { status } = useAppSelector(assignmentState)

  useEffect(() => {
    if (!pathname.includes('asignacion') && status) {
      dispatch(restartAssignment())
    }
  }, [pathname])
}
