import { Currency } from './Currency'

export class Money {
  private cents = 0
  private currency: Currency

  private constructor(amount: number, currency: Currency) {
    this.cents = amount
    this.currency = currency
  }

  static fromString(amount: string, currency: Currency) {
    return new Money(Number(amount) * 100, currency)
  }

  static fromCents(cents: number, currency: Currency) {
    return new Money(cents, currency)
  }

  add(money: Money) {
    if (!this.currency.equals(money.currency)) {
      throw new Error('La moneda no coincide')
    }

    return new Money(this.cents + money.cents, this.currency)
  }

  substract(money: Money): Money {
    if (!this.currency.equals(money.currency)) {
      throw new Error('La moneda no coincide')
    }

    return new Money(this.cents - money.cents, this.currency)
  }

  incrementByPercentage(percentage: number): Money {
    const increase = this.cents * (percentage / 100)
    const sum = this.cents + increase
    const rounded = Math.round(sum)

    return Money.fromCents(rounded, this.currency)
  }

  reducedByPercentage(percentage: number) {
    const reduction = this.cents * (percentage / 100)
    const difference = this.cents - reduction
    const rounded = Math.round(difference)
    return Money.fromCents(rounded, this.currency)
  }

  round(unit: 1 | 10 | 100 | 1000): Money {
    const zeros = 100 * unit
    const rounded = Math.round(this.cents / zeros)
    const cents = rounded * zeros

    return Money.fromCents(cents, this.currency)
  }

  getCurrency() {
    return this.currency
  }

  getCents() {
    return this.cents
  }

  getFormattedMoney() {
    return new Intl.NumberFormat('es-AR', {
      currency: this.currency.getValue(),
      style: 'currency',
    }).format(this.getValue())
  }

  getValue() {
    //return rounded value to 2 decimal places
    return Number(Math.round(this.cents / 100).toFixed(2))
  }
}
