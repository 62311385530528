import React from 'react'
import { Flex, Spinner } from '@chakra-ui/react'

export const ApplicationLoader = () => {
  return (
    <Flex
      position='fixed'
      zIndex={10000}
      w='100vw'
      h='100vh'
      justify='center'
      align='center'
      bgColor=' rgba(0, 0, 0, 0.17)'
      backdropBlur='12.5px'
    >
      <Spinner size='xl' />
    </Flex>
  )
}
