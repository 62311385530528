import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, HStack, Stack, Text } from '@chakra-ui/react'
import { NucCalendar, NucFilm } from '@nucba-coding/nucba-ui'

import { Arrow } from './Arrow'
import { AssignmentHeroButton } from './AssignmentHeroButton'
import { AboutAssignmentItem } from './AboutAssignmentItem'
import { useGetAssignmentDetailsQuery } from '../services/assignment.service'
import {
  assignmentState,
  currentAssignment,
} from '../features/assignment.slice'
import { ModulePresentation } from '../../shared/components/ModulePresentation'
import { CardContainer } from '../../shared/components/ClassCardContainer'
import { ClassCardContent } from '../../shared/components/ClassCardContent'
import { ContentTemplate } from '../../shared/template/ContentTemplate'
import { formatStatus } from '../../shared/utils'

import { Isologo } from '@/shared/components/Isologo'
import { Breadcrumb } from '@/shared/components/Breadcrumb'
import { Hero } from '@/shared/components/Hero'
import { ASSIGNMENT_STATUS_COLOR } from '@/shared/constants'
import { useAppDispatch, useAppSelector } from '@/shared/app/hooks'
import { DateTime } from '@/shared/helpers'
import { sessionState } from '@/shared/features/session.slice'
import { HeroLoader } from '@/education/shared/components/loaders/HeroLoader'
import { ClassCardLoader } from '@/education/shared/components/loaders/ClassCardLoader'
import { AssignmentStatus } from '@/shared/enums'
import { ModalVideoPlayer } from '@/shared/components/ModalVideoPlayer'
import { useNucbaTheme } from '@/shared/hooks'
import { HeaderNovu } from '@/shared/components/Novu'

export const AssignmentHero = () => {
  const { isStaff } = useAppSelector(sessionState)

  const { colors } = useNucbaTheme()

  const { code, assignmentId } = useParams()

  const dispatch = useAppDispatch()
  const { status: stateStatus } = useAppSelector(assignmentState)
  const { data, isSuccess, isLoading } = useGetAssignmentDetailsQuery({
    assignmentId: assignmentId!,
    code: code!,
  })

  useEffect(() => {
    if (isSuccess) {
      dispatch(currentAssignment({ id: assignmentId!, status: data.status }))
    }
  }, [isSuccess])

  const status = stateStatus ? stateStatus : data?.status

  return (
    <Hero image='https://res.cloudinary.com/dcatzxqqf/image/upload/v1679871403/Galileo-Platform/Images/banner/assigmentHero_uqi30o.webp'>
      <ContentTemplate
        h='100%'
        display='flex'
        flexDir='column'
        justifyContent='space-around'
      >
        <Stack flexGrow={1} justifyContent='space-between' spacing={0}>
          <HStack w='95%' justify='space-between'>
            <Breadcrumb />
            <HStack>
              <HeaderNovu />
              <Isologo />
            </HStack>
          </HStack>
          {isLoading ? (
            <HeroLoader withItems withStatus />
          ) : (
            <HStack w='95%' justify='space-between' align='flex-end'>
              <Stack spacing={5}>
                <ModulePresentation
                  title={`📋 ${data?.exercise.name || ''} `}
                  fontSize={{ base: 'xl', '2xl': '2xl' }}
                />

                <HStack spacing={5} alignItems='center'>
                  <AssignmentHeroButton status={status!} />
                  <Text
                    as='span'
                    marginLeft={5}
                    fontSize='sm'
                    fontWeight='bold'
                    color={
                      status
                        ? ASSIGNMENT_STATUS_COLOR[status]
                        : colors.nucba.primary
                    }
                  >
                    {formatStatus(status || '')}
                  </Text>
                </HStack>
              </Stack>

              <Stack w='35%' maxW='550px' spacing={3}>
                <AboutAssignmentItem title='Apertura' icon={<NucCalendar />}>
                  <Text>
                    {data &&
                      DateTime.createFromDate(
                        new Date(data?.exercise.class.cohort_classes[0]!.date),
                        false,
                      )
                        .addDays(1)
                        .formatDate({ dateStyle: 'long' })}
                    , 00:00hs
                  </Text>
                </AboutAssignmentItem>
                <AboutAssignmentItem
                  title='Fecha de cierre'
                  icon={<NucCalendar />}
                >
                  <Text>
                    {data &&
                      DateTime.createFromString(
                        data?.dueDate,
                        false,
                      ).formatDate({
                        dateStyle: 'long',
                      })}
                    , 23:59hs
                  </Text>
                </AboutAssignmentItem>

                {(status === AssignmentStatus.APROBADA || isStaff) && (
                  <AboutAssignmentItem
                    icon={<NucFilm width='14' height='14' />}
                  >
                    <ModalVideoPlayer
                      videoId={data?.exercise.solution || ''}
                      text='VER VIDEO RESUELTO'
                    />
                  </AboutAssignmentItem>
                )}
              </Stack>
            </HStack>
          )}
        </Stack>

        <CardContainer
          position='relative'
          bottom={{ base: -9, '2xl': -10 }}
          width='95%'
          border={`2px solid ${colors.nucba.primary}`}
          cursor='auto !important'
        >
          <Box
            position='absolute'
            bottom={0}
            top={0}
            left={-4}
            h='min-content'
            m='auto'
          >
            <Arrow />
          </Box>
          {isLoading ? (
            <ClassCardLoader />
          ) : (
            <ClassCardContent
              heading={data?.exercise.class.name || ''}
              description='Entrega'
            />
          )}
        </CardContainer>
      </ContentTemplate>
    </Hero>
  )
}
