import React, { ReactNode } from 'react'
import { HStack, Stack, Text } from '@chakra-ui/react'

type AboutAssignmentItemProps = {
  children: ReactNode
  title?: string
  icon: ReactNode

  /**@default auto */
  w?: string
}

export const AboutAssignmentItem = ({
  children,
  title,
  icon,
  w = 'auto',
}: AboutAssignmentItemProps) => {
  return (
    <Stack alignItems='baseline' spacing={0}>
      {title && (
        <Text fontSize='lg' fontWeight={700}>
          {title}
        </Text>
      )}
      <HStack alignItems='center' w={w}>
        {icon}
        {children}
      </HStack>
    </Stack>
  )
}
