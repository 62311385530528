import React from 'react'
import { Field, FieldProps } from 'formik'
import { FormControl, FormErrorMessage, HStack, Stack } from '@chakra-ui/react'
import { Input, NucCircleMinus, NucCirclePlus } from '@nucba-coding/nucba-ui'
import { motion } from 'framer-motion'

import { initialValues } from '../pages/SubmitAssignment'

type UploadLinkInputProps = {
  name: string
  push: (obj: any) => void
  remove: <T>(index: number) => T | undefined
  index: number
  length: number
}

export const UploadLinkInput = ({
  name,
  push,
  remove,
  index,
  length,
}: UploadLinkInputProps) => {
  const isLast = !!index

  return (
    <motion.div
      initial={isLast ? { x: 125, opacity: 0 } : false}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: 125, opacity: 0 }}
    >
      <Field name={name}>
        {({
          field,
          form: { errors, touched },
        }: FieldProps<any, typeof initialValues>) => (
          <Stack
            as={FormControl}
            spacing={1}
            isInvalid={Boolean(
              errors.URLs &&
                errors.URLs![index] &&
                touched.URLs &&
                (touched.URLs as any)[index],
            )}
          >
            <HStack w='100%'>
              <Input
                w='calc(100% - 24px)'
                placeholder='https://galileo.nucba.io/'
                {...field}
              />
              <motion.button type='button' whileTap={{ scale: 0.95 }}>
                {isLast ? (
                  <NucCircleMinus
                    onClick={() => remove(index)}
                    cursor='pointer'
                  />
                ) : (
                  length < 2 && (
                    <NucCirclePlus onClick={() => push('')} cursor='pointer' />
                  )
                )}
              </motion.button>
            </HStack>
            <FormErrorMessage maxW={300} color='nucba.primary' fontSize='xs'>
              {errors.URLs &&
                (typeof errors.URLs === 'string'
                  ? errors.URLs
                  : errors.URLs![index])}
            </FormErrorMessage>
          </Stack>
        )}
      </Field>
    </motion.div>
  )
}
