import React, { ReactNode } from 'react'
import { Stack } from '@chakra-ui/react'

type SidebarStackProps = {
  children: ReactNode
  isOpen: boolean
}

export const SidebarStack = ({ children, isOpen }: SidebarStackProps) => {
  return (
    <>
      {isOpen ? (
        <Stack h='full' align='start' justify='space-evenly' pl={5}>
          {children}
        </Stack>
      ) : (
        <Stack h='full' align='center' justify='space-evenly'>
          {children}
        </Stack>
      )}
    </>
  )
}
