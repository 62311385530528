import React, { useEffect, useState } from 'react'

import { useGetCohortQuery } from '../../cohorts/services/cohort.service'
import { GetSubscriptionContractTransformResponse } from '../services/contract-query.service'
import { formatMoney } from '@/inscription/utils/formatMoney'
import { serializeProductCohort } from '@/inscription/utils'
import { DateTime } from '@/shared/helpers'
import { COHORT_TYPE, PAYMENT_METHODS_TYPES } from '@/shared/types'

type CohortData = {
  days: string
  startDate: string
}

type ContractTextProps = {
  contract: Exclude<GetSubscriptionContractTransformResponse, undefined>
}

export const ContractText = ({ contract }: ContractTextProps) => {
  const { data: cohort, isSuccess } = useGetCohortQuery({
    id: contract.subscription?.cohortId,
  })

  const [cohortData, setCohortData] = useState<CohortData | null>(null)

  useEffect(() => {
    if (isSuccess && cohort) {
      const { days, startDate } = serializeProductCohort(cohort)
      setCohortData({ days, startDate })
    }
  }, [isSuccess, cohort])

  const {
    plan: { product, ...plan },
    user: {
      profiles: [userProfile],
    },
  } = contract.subscription

  const {
    addresses: [address],
    ...profile
  } = userProfile

  return (
    <>
      <p>
        Entre la empresa denominada <b>NUCBA S.R.L</b>, C.U.I.T. 30-71741223-7,
        representada en este acto por el SR. <b>LUCIANO EZEQUIEL D´AMICO</b>,
        D.N.I. N° 39.760.747, constituyendo domicilio en la calle Mariano Moreno
        N° 15, Ramos Mejía, Partido de La Matanza, Provincia de Buenos Aires, en
        adelante “LA INSTITUCIÓN”, y por otra parte el SR/A {profile.firstname}{' '}
        {profile.lastname} Documento de Identidad N° D.N.I.
        {profile.document}, con domicilio en calle {address.street} -{' '}
        {address.number}, {address.locality}, {address.city}, {address.province}
        , en adelante “EL INGRESANTE”, libre y voluntariamente convienen
        celebrar el presente contrato educativo correspondiente al ciclo lectivo{' '}
        {new Date().getFullYear()}, el cual se regirá por las siguientes
        cláusulas:
        <br />
        <br />
        <b>PRIMERA:</b> LA INSTITUCIÓN se compromete a dictar el curso
        denominado “{product.name}”.{' '}
        {cohort?.type === COHORT_TYPE.ON_DEMAND
          ? 'con modalidad diferida u on demand a través de su plataforma.'
          : `El mismo comenzará el ${
              cohortData?.startDate
            } impartiéndose las clases los días ${
              cohortData?.days
            } hasta su efectiva culminación, la cual ocurrirá ${
              !contract.subscription.finishAt
                ? 'en ' + contract.subscription.duration + ' meses'
                : 'el día ' +
                  DateTime.createFromDate(
                    new Date(contract.subscription.finishAt || new Date()),
                    false,
                  ).formatDate({ dateStyle: 'long' })
            }`}{' '}
        -
        <br />
        <br />
        {contract.subscription.paymentMethods[0] ===
        PAYMENT_METHODS_TYPES.TRANSFER ? (
          <>
            <b>SEGUNDA:</b> EL INGRESANTE se compromete a abonar cada mes por
            adelantado, mediante transferencia bancaria el arancel
            correspondiente a los servicios educativos de LA INSTITUCIÓN; los
            mismos ascenderán a la suma de pesos {''}
            {formatMoney(contract.subscription.totalAmount || 0, {
              currency: plan.currency,
            })}{' '}
            {''}
            la cual se oblará en {plan.installments} {''}
            {plan.installments === 1
              ? 'cuota mensual'
              : 'cuotas mensuales iguales y consecutivas,'}
            {''} de pesos {''}
            {formatMoney(
              (contract.subscription.totalAmount || 0) /
                (plan?.installments || 1),
              {
                currency: plan.currency,
              },
            )}
            .-
          </>
        ) : contract.subscription.paymentMethods[0] ===
          PAYMENT_METHODS_TYPES.MERCADO_PAGO ? (
          <>
            <b>SEGUNDA:</b> EL INGRESANTE se compromete a abonar cada mes por
            adelantado, mediante débito automático, link de pago o código QR
            (suministrado por la plataforma denominada “mercado pago”) el
            arancel correspondiente a los servicios educativos de LA
            INSTITUCIÓN; los mismos ascenderán a la suma de pesos {''}
            {formatMoney(contract.subscription.totalAmount || 0, {
              currency: plan.currency,
            })}{' '}
            {''}
            la cual se oblará en {plan.installments} {''}
            {plan.installments === 1
              ? 'cuota mensual'
              : 'cuotas mensuales iguales y consecutivas,'}
            {''} de pesos {''}
            {formatMoney(
              (contract.subscription.totalAmount || 0) /
                (plan?.installments || 1),
              {
                currency: plan.currency,
              },
            )}
            .-
          </>
        ) : contract.subscription.paymentMethods[0] ===
          PAYMENT_METHODS_TYPES.INCREASE ? (
          <>
            <b>SEGUNDA:</b> EL INGRESANTE se compromete a abonar cada mes por
            adelantado, mediante tarjeta de crédito/débito (suministrado por la
            plataforma denominada “increase”) el arancel correspondiente a los
            servicios educativos de LA INSTITUCIÓN; los mismos ascenderán a la
            suma de pesos {''}
            {formatMoney(contract.subscription.totalAmount || 0, {
              currency: plan.currency,
            })}{' '}
            {''}
            la cual se oblará en {plan.installments} {''}
            {plan.installments === 1
              ? 'cuota mensual'
              : 'cuotas mensuales iguales y consecutivas,'}
            {''} de pesos {''}
            {formatMoney(
              (contract.subscription.totalAmount || 0) /
                (plan?.installments || 1),
              {
                currency: plan.currency,
              },
            )}
            .-
          </>
        ) : null}
        <br />
        <br />
        <b>TERCERA:</b> LA INSTITUCIÓN reconocerá todos los pagos realizados por
        EL INGRESANTE con anterioridad a la suscripción de este contrato; los
        mismos se imputarán como saldo a favor de las primeras cuotas
        pertenecientes al curso contratado.-
        <br />
        <br />
        <b> CUARTA:</b> El servicio educativo brindado por LA INSTITUCIÓN es
        indivisible, ya que los aranceles se establecen considerando toda la
        enseñanza correspondiente al curso objeto de este contrato. EL
        INGRESANTE reconoce que la obligación de pago es única, aun cuando pueda
        cancelarla en cuotas.- Por esta razón bajo ninguna circunstancia, EL
        INGRESANTE podrá solicitar o pretender que se le exima de cumplir
        cualquier parte de la obligación de pago asumida; sin importar para ello
        el hecho de que EL INGRESANTE perdiere su condición de regular o dejare
        de concurrir a la institución.-
        <br />
        <br />
        <b> QUINTA:</b> EL INGRESANTE se compromete a abonar mensualmente, en
        concepto de arancel por servicios de LA INSTITUCIÓN, la suma acordada
        entre las partes.- Ante el caso de mora en el pago, si la cuota mensual
        correspondiente se abonara con posterioridad a la fecha de su
        vencimiento, la Institución cobrará un recargo de $980,00 por cada cuota
        vencida, siempre que el pago sea realizado dentro del mes.- Si el pago
        se efectuare a partir del mes siguiente, se cobrará además de lo
        estipulado precedentemente, un recargo del 3 % mensual, calculado sobre
        el valor de la cuota impaga desde su vencimiento hasta el día de su
        efectivo pago.-
        <br />
        <br />
        <b>SEXTA:</b> El presente contrato tendrá vigencia por un plazo de{' '}
        {contract.subscription.duration} meses; dicho lapso se contabilizara una
        vez que el presente acuerdo adquiera plena validez a través de la firma
        de las partes.-
        <br />
        <br />
        <b>SÉPTIMA:</b> EL INGRESANTE manifiesta plenamente que ha aceptado y
        acepta todos y cada uno de los términos y condiciones vigentes en la
        plataforma digital de LA INSTITUCIÓN (nucba.com.ar); asimismo se deja
        constancia que cualquier modificación a dichos términos y condiciones
        deberá ser comunicada por LA INSTITUCIÓN, con el objetivo que EL
        INGRESANTE preste o no conformidad ante los mismos.-
        <br />
        <br />
        <b>OCTAVA:</b> A su vez LA INSTITUCIÓN se reserva el derecho de accionar
        en forma judicial en caso de incumplimiento de cualquiera de las
        clausulas en el presente contrato.-
        <br />
        <br />
        <b>NOVENA:</b> EL INGRESANTE deberá comunicar a LA INSTITUCIÓN en el
        término de cinco días, toda variación que se produzca en los datos
        consignados por EL INGRESANTE al momento de suscribir este contrato,
        como cualquier otro documento suministrado por LA INSTITUCIÓN.-
        <br />
        <br />
        <b>DÉCIMA:</b> EL INGRESANTE deberá comunicar fehacientemente a LA
        INSTITUCIÓN, con una antelación mínima de treinta días y por escrito, la
        solicitud de baja del alumno. Asimismo se deja expresa constancia que
        dicha notificación no acarreara modificación alguna a las obligaciones
        asumidas por EL INGRESANTE en el presente contrato.- Recíprocamente LA
        INSTITUCIÓN quedará comprometida a comunicar con 30 días de anticipación
        la baja del alumno por incumplimiento de cualquiera de las cláusulas
        contenidas en el contrato.-
        <br />
        <br />
        <b>DECIMOPRIMERA:</b> LA INSTITUCIÓN no realizará devoluciones de
        importes abonados por el curso a dictarse, ya fuere esto por
        inconvenientes personales; mala interpretación de los contenidos
        académicos; de agenda, o por cualquier otro impedimento o razón que se
        pudiera aludir.-
        <br />
        <br />
        <b>DECIMOSEGUNDA:</b> LA INSTITUCIÓN se reserva el derecho de admisión
        del INGRESANTE; por lo que la inscripción en un ciclo lectivo no obliga
        necesariamente a LA INSTITUCIÓN a su admisión en un ciclo sucesivo o
        cualquier otro que se dicte con posterioridad.-
        <br />
        <br />
        <b>DECIMOTERCERA:</b> EL INGRESANTE expresa que ante la existencia de
        circunstancias de fuerza mayor o caso fortuito; mientras aquellas
        persistan; LA INSTITUCIÓN tendrá la facultad de modificar los días y
        horarios en que se dictarán las clases.-
        <br />
        <br />
        <b>DECIMOCUARTA:</b> Una vez finalizado el ciclo lectivo y cumplida la
        obligación del INGRESANTE de entregar el proyecto integrador que
        determinaría la culminación del curso contratado; LA INSTITUCIÓN se
        comprometerá a otorgar un certificado digital que refleje la
        finalización del curso objeto de este contrato.-
        <br />
        <br />
        <b>DECIMOQUINTA:</b> EL INGRESANTE tendrá como requisito esencial la
        disposición de un dispositivo electrónico, con acceso a internet, que le
        permita adquirir todos los conocimientos y cumplir con los objetivos del
        curso.-
        <br />
        <br />
        <b>DECIMOSEXTA:</b> Cualquier controversia surgida de la interpretación
        o ejecución del presente contrato se interpretará conforme a la
        legislación Argentina. Las partes acuerdan que todo litigio,
        discrepancia o reclamación del presente contrato se resolverá ante los
        tribunales de la Ciudad Autónoma de Buenos Aires-.
        <br />
        <br />
        En prueba de conformidad se firmaran dos ejemplares de un mismo tenor y
        al solo efecto de su implementación, en la Provincia de Buenos Aires,
        Localidad de La Matanza, Ciudad de Ramos Mejía, fijando fecha cierta del
        presente acuerdo a la establecida en la firma de las partes.-
      </p>
    </>
  )
}
