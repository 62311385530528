import React, { useState, useEffect } from 'react'
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { UseToastOptions, useToast } from '@chakra-ui/react'

import { useSessionState } from '@/shared/features/session.slice'
import { useStudent } from '@/shared/hooks'
import { ApplicationLoader } from '@/shared/components/loaders/ApplicationLoader'
import { CONTRACT_STATUS, SUBSCRIPTIONS_TYPES } from '@/shared/types'
import { TOAST_DEFUALT_OPTIONS } from '@/shared/constants'
import { useGetPendingContractsQuery } from '@/shared/services/userQuery.service'
import { useGetDuoPendingContractsQuery } from '@/education/subscriptions/services/contract-query.service'
import { useGetStudentSubscriptionQuery } from '@/education/cohorts/services/cohort.service'

const MESSAGES_BY_SUB_STATUS: Record<
  string,
  {
    title: string
    description: string
    status?: UseToastOptions['status']
  }
> = {
  [SUBSCRIPTIONS_TYPES.WAITING_PAYMENT_CONFIRMATION]: {
    title: 'Suscripción En Proceso',
    description: 'Cuando apruebe el pago, se habilitará tu suscripción.',
    status: 'warning',
  },
  [SUBSCRIPTIONS_TYPES.PAUSED]: {
    title: 'Suscripción Pausada',
    description: 'Comunicate con Secretaria para regularizar tu suscripción.',
    status: 'error',
  },
  [SUBSCRIPTIONS_TYPES.CANCELLED]: {
    title: 'Suscripción Cancelada',
    description: 'Comunicate con Secretaria para más información.',
    status: 'error',
  },
}

export const CohortRoot = () => {
  const { code } = useParams()
  const { state } = useLocation()
  const navigate = useNavigate()

  const toast = useToast({
    ...TOAST_DEFUALT_OPTIONS,
  })

  const cameFromApp = state && state.fromAplication

  const [isStudent, setIsStudent] = useState<boolean | 'isVerifying'>(
    cameFromApp ? cameFromApp : 'isVerifying',
  )

  const { id, isStaff } = useSessionState()
  const { studentId, duo, subscription } = useStudent(code!)

  useEffect(() => {
    setIsStudent(!!studentId)
  }, [])

  const { data: studentSubscription, isSuccess } =
    useGetStudentSubscriptionQuery(
      { studentId },
      { skip: !subscription && !duo },
    )

  useEffect(() => {
    const handleSubscriptionStatus = (status: string | undefined) => {
      const message = MESSAGES_BY_SUB_STATUS[status || '']

      if (message) {
        toast(message)
        navigate('/')
      }
    }

    if (studentSubscription && isSuccess) {
      handleSubscriptionStatus(studentSubscription.status)
    }
  }, [studentSubscription, isSuccess])

  const { data: subContract } = useGetPendingContractsQuery(id, {
    skip: !subscription,
  })

  useEffect(() => {
    if (subContract && subContract.status === CONTRACT_STATUS.PENDIENTE) {
      window.location.href = '/'
    }
  }, [subContract])

  const { data: duoContract } = useGetDuoPendingContractsQuery(
    {
      duoId: duo!,
    },
    {
      skip: !duo,
    },
  )

  useEffect(() => {
    if (
      duoContract &&
      duoContract.subscription.contracts[0].status === CONTRACT_STATUS.PENDIENTE
    ) {
      toast({
        title: 'Contrato pendiente de firma',
        description: `Debes esperar a que ${duoContract.subscriptionOwner} firme el contrato para acceder a la cursada.`,
        status: 'warning',
      })

      navigate('/')
    }
  }, [duoContract])

  if (!isStaff) {
    if (isStudent === 'isVerifying') {
      return <ApplicationLoader />
    }

    if (!isStudent) {
      return <Navigate to='/' replace />
    }
  }

  return <Outlet />
}
