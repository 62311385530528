import React, { ReactNode } from 'react'
import { ButtonProps, StyleProps } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button as NucbaButton } from '@nucba-coding/nucba-ui'

import { AssignmentStatus } from '@/shared/enums'
import { useAppSelector } from '@/shared/app/hooks'
import { sessionState } from '@/shared/features/session.slice'

type AssignmentHeroButtonProps = {
  status: AssignmentStatus
}

export const AssignmentHeroButton = ({ status }: AssignmentHeroButtonProps) => {
  const { isStaff } = useAppSelector(sessionState)

  const { pathname, state } = useLocation()
  const navigate = useNavigate()

  const isInUrl = (path: string) => pathname.includes(path)

  const isPendingOrIsInRevision =
    status === AssignmentStatus.PENDIENTE ||
    status === AssignmentStatus.EN_REVISION

  const isApprovedOrDelivered =
    status === AssignmentStatus.APROBADA ||
    status === AssignmentStatus.ENTREGADA

  const isApproved = status === AssignmentStatus.APROBADA

  const isPending = status === AssignmentStatus.PENDIENTE

  const isInRevision = status === AssignmentStatus.EN_REVISION

  if (isInUrl('entregar')) {
    return (
      <Button
        onClick={() =>
          navigate(isApprovedOrDelivered ? 'devolucion' : '..', {
            relative: isApprovedOrDelivered ? 'route' : 'path',
            state,
          })
        }
      >
        {isApprovedOrDelivered ? 'Ver devolución' : 'Ver Asignación'}
      </Button>
    )
  }

  if (isInUrl('devolucion')) {
    if (isStaff) {
      return (
        <Button
          onClick={() =>
            navigate('..', {
              relative: 'path',
              state,
            })
          }
        >
          Ver Asignación
        </Button>
      )
    }

    return (
      <Button
        onClick={() =>
          navigate(isPending ? 'entregar' : '..', {
            relative: isPending ? 'route' : 'path',
            state,
          })
        }
      >
        {isPending ? 'Entregar' : 'Ver Asignación'}
      </Button>
    )
  }

  if (isStaff) {
    return (
      <Button
        onClick={() => navigate('devolucion', { state })}
        btnStyles={{ disabled: isPendingOrIsInRevision }}
      >
        {isApproved ? 'Ver devolución' : 'Corregir'}
      </Button>
    )
  }

  return (
    <>
      <Button
        onClick={() =>
          navigate(isPending ? 'entregar' : 'devolucion', {
            state,
          })
        }
      >
        {isPending ? 'Entregar' : 'Ver devolución'}
      </Button>

      {isInRevision && (
        <Button
          onClick={() =>
            navigate('entregar', {
              state,
            })
          }
        >
          Entregar
        </Button>
      )}
    </>
  )
}

type ButtonComponentProps = {
  children: ReactNode
  onClick: () => void
  btnStyles?: StyleProps & ButtonProps
}

const Button = ({ children, onClick, btnStyles }: ButtonComponentProps) => {
  return (
    <NucbaButton
      size='sm'
      fontSize='sm'
      borderRadius={30}
      textTransform='uppercase'
      onClick={onClick}
      {...btnStyles}
    >
      {children}
    </NucbaButton>
  )
}
