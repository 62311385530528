import React from 'react'
import { GridItem, Skeleton } from '@chakra-ui/react'

import { generateArrayWithRandomKeys } from '@/shared/utils'
import { ItemProfile } from '@/education/cohorts/components/ItemProfile'

export const ItemProfileLoader = () => {
  return (
    <>
      {generateArrayWithRandomKeys(3).map(li => (
        <Skeleton key={li} borderRadius={11}>
          <GridItem w='280px' h='150px'>
            <ItemProfile text='' />
          </GridItem>
        </Skeleton>
      ))}
    </>
  )
}
