import React from 'react'
import { useNavigate } from 'react-router-dom'
import { GridItem } from '@chakra-ui/react'
import { Card } from '@nucba-coding/nucba-ui'

import { useStudent } from '@/shared/hooks'
import { useCohortPrefetch } from '../services/cohort.service'

type CohortCardProps = {
  id: string
  code: string
  image: string
}

export const CohortCard = ({ id, code, image }: CohortCardProps) => {
  const navigate = useNavigate()
  const { studentId } = useStudent(code)

  const prefetchCohortCourses = useCohortPrefetch('getCohortCourses')

  return (
    <GridItem
      key={id}
      onClick={() =>
        navigate(`/camada/${code}`, {
          state: { fromAplication: true },
        })
      }
      onMouseEnter={() => {
        prefetchCohortCourses({
          code,
          studentId,
        })
      }}
    >
      <Card width='auto' title={`Camada #${code}`} bgImage={image} />
    </GridItem>
  )
}
