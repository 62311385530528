export const slugifyCourseName = (courseName: string | undefined): string => {
  if (!courseName) return ''

  return courseName.split(' ').join('-').toLowerCase()
}

export const unformatCourseName = (courseName: string): string =>
  courseName
    .split('-')
    .map(str => str.at(0)?.toUpperCase() + str.substring(1))
    .join(' ')
