import React from 'react'
import { Skeleton } from '@chakra-ui/react'

export const ExerciseDetailsLoader = () => {
  return (
    <>
      <Skeleton my={3} h={16} borderRadius={5} />
      <Skeleton h={300} borderRadius={5} />
    </>
  )
}
