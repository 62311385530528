import { RootState } from '@/shared/app/store'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const contractMutationApi = createApi({
  reducerPath: 'contractMutationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/contratos`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).session.token

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: builder => ({
    contractViewed: builder.mutation<any, string>({
      query: id => ({
        url: `/${id}/ver`,
        method: 'PATCH',
      }),
    }),
    signedContract: builder.mutation<any, { body: FormData; id: string }>({
      query: ({ id, body }) => ({
        url: `/${id}/firmar`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
})

export const { useContractViewedMutation, useSignedContractMutation } =
  contractMutationApi
