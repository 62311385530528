import React, { useRef, useState } from 'react'
import {
  Stack,
  Text,
  InputGroup,
  InputRightElement,
  useToast,
  Box,
} from '@chakra-ui/react'
import { useCartState } from '../features/cart.slice'
import { Button, Input, NucUpload } from '@nucba-coding/nucba-ui'
import { TOAST_DEFUALT_OPTIONS } from '@/shared/constants'
import { Currencies, CustomError } from '@/shared/types'
import { useNavigate } from 'react-router-dom'
import { formatMoney } from '../utils/formatMoney'
import { useUploadReceiptFileMutation } from '@/education/subscriptions/services/billing-mutation.service'
import { Money } from '@/shared/helpers/Money'
import { Currency } from '@/shared/helpers/Currency'

type TransferDataType = {
  title: string
  subtitle: string
}

const transferData: TransferDataType[] = [
  { title: 'CUENTA', subtitle: 'NUCBA S.R.L' },
  { title: 'Alias', subtitle: 'LaNucbanetta' },
  { title: 'CBU', subtitle: '00700146-20000006053224' },
]

export const TransferPayment = () => {
  const inputRef = useRef<any>()
  const navigate = useNavigate()
  const { checkoutLink, plan, campaign, coupon } = useCartState()
  const [file, setFile] = useState<File | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const [uploadPDF] = useUploadReceiptFileMutation()

  const toastSuccess = useToast({
    ...TOAST_DEFUALT_OPTIONS,
    status: 'success',
    title: 'Comprobante de pago adjunto',
  })

  const toastError = useToast({
    ...TOAST_DEFUALT_OPTIONS,
    title: 'Lo sentimos, ha ocurrido un error',
    status: 'error',
  })

  const getInvoiceIdFromCheckoutLink = (
    checkoutLink: string,
  ): string | null => {
    const match = checkoutLink.match(/invoiceId=([^&]*)/)
    return match ? match[1] : null
  }

  const invoiceId = getInvoiceIdFromCheckoutLink(checkoutLink!)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0])
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsLoading(true)
    if (!file || invoiceId === null) return

    const formData = new FormData()
    formData.append('deposit', file)

    try {
      await uploadPDF({ formData, id: invoiceId }).unwrap()
      toastSuccess({})
      navigate('../pago-exitoso')
    } catch (error) {
      toastError({
        description: (error as CustomError).data.message,
      })
    } finally {
      setIsLoading(false)
    }
  }

  let netPrice = Money.fromString(
    String(plan?.price ?? 0),
    Currency.create(plan?.currency || Currencies.ARS),
  )
  if (campaign) {
    netPrice = netPrice.reducedByPercentage(campaign.discount).round(1)
  }
  if (coupon && coupon.valid) {
    netPrice = netPrice.reducedByPercentage(coupon.discount).round(1)
  }

  return (
    <Stack spacing={5}>
      <Text fontWeight={600}>
        Debes transferir{' '}
        {formatMoney(netPrice.getValue() / (plan?.installments || 1), {
          currency: netPrice.getCurrency().toString(),
        })}{' '}
        a la siguiente cuenta:
      </Text>
      {transferData?.map(val => (
        <Stack key={val.title} spacing={1}>
          <Text fontWeight={600} textTransform='capitalize'>
            {val.title}
          </Text>
          <Text>{val.subtitle}</Text>
        </Stack>
      ))}
      <Box>
        <form onSubmit={handleSubmit}>
          <InputGroup position='relative'>
            <input
              type='file'
              accept='.pdf,.jpg,.jpeg,.png'
              style={{ display: 'none' }}
              onChange={handleFileChange}
              ref={inputRef}
            />
            <Input
              variant='outline'
              isReadOnly
              placeholder='Adjuntar comprobante'
              width={{ base: 260, '2xl': 300 }}
              pr={10}
              cursor='pointer'
              value={inputRef.current?.files[0]?.name || ''}
              onClick={() => inputRef.current.click()}
            />

            <InputRightElement
              position='absolute'
              left={{ base: 215, '2xl': 255 }}
            >
              <NucUpload iconColor='#CBD5E0' />
            </InputRightElement>
          </InputGroup>
          <Button
            size='sm'
            fontSize='lg'
            type='submit'
            disabled={!file}
            isLoading={isLoading}
            mt={4}
          >
            Enviar
          </Button>
        </form>
      </Box>
    </Stack>
  )
}
