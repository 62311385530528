import React from 'react'
import { useParams, Link, useLocation } from 'react-router-dom'
import {
  HStack,
  ListItem,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  useMediaQuery,
} from '@chakra-ui/react'

import { openInNewTab } from '@/shared/utils'
import { ListOfClasses } from '../components/ListOfClasses'
import { slugifyCourseName } from '@/education/shared/utils'
import { useGetCohortClassByClassIDQuery } from '../services/cohort.service'
import { ClassCardLoader } from '@/education/shared/components/loaders/ClassCardLoader'
import { ClassVideoLoader } from '@/education/shared/components/loaders/ClassVideoLoader'
import { OneLineTextLoader } from '@/education/shared/components/loaders/OneLineTextLoader'
import { Breadcrumb } from '@/shared/components/Breadcrumb'
import { useNucbaTheme, useStudent } from '@/shared/hooks'
import { LinkifyWrapper } from '@/shared/components/LinkifyWrapper'
import { VimeoPlayer } from '@/shared/components/VimeoPlayer'

export const Class = () => {
  const { colors } = useNucbaTheme()
  const { code, course, classId } = useParams()
  const { state } = useLocation()
  const [isSmallerThan1460, isSmallerThan900] = useMediaQuery([
    '(max-width: 1460px)',
    '(max-width: 900px)',
  ])
  const student = useStudent(code!)

  const tabsHeaderGeneralStyles = {
    _selected: { bg: 'nucba.primary', fontWeight: 'bold' },
    fontSize: `${isSmallerThan1460 ? 'small' : 'sm'}`,
    bg: 'nucba.black-70',
    color: 'white',
    fontWeight: 'medium',
    display: 'inline',
    w: 'max-content',
  }

  const { data, isLoading, isError } = useGetCohortClassByClassIDQuery({
    classId: classId!,
    studentId: student.studentId,
  })

  return (
    <Stack
      w={isSmallerThan900 ? '90%' : '95%'}
      h='95vh'
      alignContent='center'
      ml='auto'
      pt={8}
    >
      <Breadcrumb />

      {/* CONTAINER  */}
      <HStack
        w='100%'
        justifyContent='space-between'
        alignItems='flex-start'
        pt={{ base: '1rem', '2xl': '2rem' }}
      >
        {/* REPRODUCTOR Y TABS */}
        <Stack
          as='section'
          flexGrow={1}
          minW={{ base: 'calc(65% - 2rem)', '2xl': 'calc(70% - 2rem)' }}
          maxW={{ base: 'calc(65% - 2rem)', '2xl': 'calc(70% - 2rem)' }}
        >
          <Stack gap={6} w={isSmallerThan1460 ? '100%' : '85%'}>
            <Stack>
              {/* Título de clase */}
              <Text
                as='h2'
                fontSize={isSmallerThan1460 ? 'xl' : '3xl'}
                fontWeight='bold'
                mt={0}
              >
                {isLoading ? (
                  <OneLineTextLoader />
                ) : data?.className ? (
                  `${data?.className} 🎓`
                ) : (
                  'Clase no disponible'
                )}
              </Text>

              {/* Reproductor */}
              {isLoading ? (
                <ClassVideoLoader />
              ) : !data?.vimeoId || isError ? (
                <Stack
                  h={{ base: '300px', '2xl': '400px' }}
                  w='70%'
                  justifyContent='center'
                  alignItems='center'
                  alignSelf='center'
                  bgColor='#151515'
                >
                  <Text
                    fontSize='3xl'
                    fontWeight='bold'
                    color='nucba.primary'
                    textAlign='center'
                  >
                    Video no disponible
                  </Text>
                </Stack>
              ) : (
                <VimeoPlayer
                  vimeoId={+data.vimeoId}
                  height={isSmallerThan1460 ? 300 : 400}
                />
              )}
            </Stack>

            {/* Tabs */}
            <Stack alignItems='baseline' w='full' overflowX='hidden'>
              <Tabs variant='soft-rounded' isLazy w='full' defaultIndex={0}>
                <HStack w='full' justifyContent='space-between'>
                  <TabList
                    gap={isSmallerThan1460 ? 2 : 4}
                    alignItems='center'
                    flexWrap={'wrap'}
                  >
                    <Tab {...tabsHeaderGeneralStyles}>INFO</Tab>
                    <Tab {...tabsHeaderGeneralStyles}>RECURSOS</Tab>
                    <Tab {...tabsHeaderGeneralStyles}>CONTENIDO PRÁCTICO</Tab>
                    {/*<Tab {...tabsHeaderGeneralStyles}>CONTENIDO TEÓRICO</Tab>*/}
                  </TabList>
                  {data?.assignmentId && (
                    <Link
                      to={`/camada/${code}/curso/${slugifyCourseName(
                        course,
                      )}/asignacion/${data?.assignmentId}`}
                      state={{ ...state, fromApplication: true }}
                    >
                      <Text
                        noOfLines={1}
                        lineHeight={1}
                        textDecoration='underline'
                        _hover={{
                          color: colors.nucba.primary,
                        }}
                      >
                        Ver entrega
                      </Text>
                    </Link>
                  )}
                </HStack>
                <TabPanels mb={8} pt={2}>
                  <TabPanel width='full'>
                    {isLoading ? (
                      <ClassCardLoader />
                    ) : (
                      <LinkifyWrapper>
                        <Text
                          whiteSpace='pre-wrap'
                          fontSize={{ base: 'sm', '2xl': 'md' }}
                        >
                          {data?.summary || 'Información no disponible'}
                        </Text>
                      </LinkifyWrapper>
                    )}
                  </TabPanel>
                  <TabPanel width='full'>
                    <UnorderedList
                      overflowY={'auto'}
                      spacing={2}
                      listStyleType='none'
                      ml={0}
                    >
                      {data?.resources[0] ? (
                        data?.resources?.map((item: string) => (
                          <ListItem key={item} textDecoration='none'>
                            👉 &nbsp;
                            <Text
                              as='a'
                              cursor='pointer'
                              fontSize={{ base: 'sm', '2xl': 'md' }}
                              _hover={{ textDecoration: 'underline' }}
                              onClick={() => openInNewTab(item)}
                            >
                              {item}
                            </Text>
                          </ListItem>
                        ))
                      ) : (
                        <Text>Recursos no disponibles</Text>
                      )}
                    </UnorderedList>
                  </TabPanel>
                  <TabPanel width='full'>
                    <Text
                      whiteSpace='pre-wrap'
                      fontSize={{ base: 'sm', '2xl': 'md' }}
                    >
                      {data?.challenge || 'Contenido no disponible'}
                    </Text>
                  </TabPanel>
                  <TabPanel width='full'>
                    <Text fontSize={{ base: 'sm', '2xl': 'md' }}>
                      Contenido no disponible
                    </Text>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Stack>
          </Stack>
        </Stack>

        {/* LISTADO DE CLASES */}
        <Stack
          as='section'
          minW={{ base: 'calc(35% - 3rem)', '2xl': 'calc(30% - 1rem)' }}
          maxW={{ base: 'calc(35% - 3rem)', '2xl': 'calc(30% - 1rem)' }}
          pos='sticky'
          top={6}
          right={0}
        >
          <Text
            as='h3'
            fontSize={isSmallerThan1460 ? 'base' : 'xl'}
            fontWeight='bold'
          >
            Listado de clases
          </Text>
          <ListOfClasses
            courseId={data?.courseId}
            simplifiedVersion
            wrapperStyles={{
              minW: isSmallerThan1460 ? '' : '400px',
              w: '100%',
              pr: 0,
            }}
          />
        </Stack>
      </HStack>
    </Stack>
  )
}
