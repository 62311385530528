import React, { useEffect, useState } from 'react'
import { Box, FormLabel, Stack, Text, Wrap, WrapItem } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { PageContainer } from '../../container/PageContainer'
import { PageHeading } from '../../components/PageHeading'

import { useNavigate, useSearchParams } from 'react-router-dom'
import type { Input as InputType } from '@/shared/types'
import { PASSWORD_VALIDATION } from '@/shared/constants'
import { useValidateEmailMutation } from '@/inscription/services/inscription.service'
import { useErrorMessage } from '@/shared/hooks'
import { Input } from '@nucba-coding/nucba-ui'
import { FormOutlineInput } from '@/inscription/components/FormOutlineInput'
import { SubmitButton } from '@/inscription/components/SubmitButton'

const inputs: InputType[] = [
  {
    name: 'password',
    label: 'Contraseña',
    type: 'password',
  },
  {
    name: 'confirmPassword',
    label: 'Confirmar Contraseña',
    type: 'password',
  },
  {
    name: 'firstname',
    label: 'Nombre',
  },
  {
    name: 'lastname',
    label: 'Apellido',
  },
  {
    name: 'document',
    label: 'Documento',
    type: 'number',
  },
  {
    name: 'birthdate',
    label: 'Fecha de nacimiento',
    type: 'date',
  },
  {
    name: 'phone',
    label: 'Celular',
  },
]

const initialValues = {
  password: '',
  confirmPassword: '',
  firstname: '',
  lastname: '',
  document: '',
  birthdate: '',
  phone: '',
}

const validationSchema = Yup.object({
  password: Yup.string()
    .trim()
    .min(8, 'Mínimo 8 caracteres')
    .matches(
      PASSWORD_VALIDATION,
      'Debe contener al menos una minúscula, una mayúscula, un número y un símbolo',
    )
    .required('Campo requerido'),
  confirmPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('password')], 'Las contraseñas no coinciden')
    .required('Campo requerido'),
  firstname: Yup.string()
    .required('Campo requerido')
    .max(255, 'El nombre no puede tener más de 255 caracteres'),
  lastname: Yup.string()
    .required('Campo requerido')
    .max(255, 'El apellido no puede tener más de 255 caracteres'),
  document: Yup.number().required('Campo requerido'),
  phone: Yup.string()
    .required('Campo requerido')
    .max(255, 'El teléfono no puede tener más de 255 caracteres'),
  birthdate: Yup.date().required('Campo requerido'),
})

export const CreateAccountDuo = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  useEffect(() => {
    if (!token) {
      navigate('/inscripcion')
    }
  }, [token])

  const [email, setEmail] = useState<string>('')
  const [emailValidation, setEmailValidation] = useState('')

  const [
    validateEmail,
    { error: validateEmailError, isLoading: validateEmailIsLoading },
  ] = useValidateEmailMutation()
  useErrorMessage(validateEmailError)

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = async event => {
    const value = event.currentTarget.value
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (!emailRegex.test(value)) {
      setEmailValidation('Correo electrónico inválido')
      return
    }

    const response = await validateEmail({ email: value })

    if ('error' in response) {
      setEmailValidation('Correo en uso')
    } else {
      setEmailValidation('Correo válido')
      setEmail(value)

      setTimeout(() => {
        setEmailValidation('')
      }, 2000)
    }
  }

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setEmail(event.currentTarget.value)
  }

  const handleSubmit = async (values: typeof initialValues) => {
    const { birthdate, document, password, firstname, lastname, phone } = values

    return navigate(`/inscripcion/datos-personales-duo?token=${token}`, {
      state: {
        birthdate,
        document,
        email,
        password,
        firstname,
        lastname,
        phone,
      },
      replace: true,
    })
  }

  return (
    <PageContainer>
      <Stack spacing={8} m='auto'>
        <Stack align={{ base: 'center', md: 'start' }}>
          <PageHeading title='1. Cuenta' />
          <Text fontSize='14px'>Para poder crear tu cuenta en NUCBA</Text>
        </Stack>
        <Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ dirty, errors }) => (
              <Form>
                <Stack spacing={10}>
                  <Wrap
                    maxW='640px'
                    spacing={8}
                    justify={{ base: 'center', md: 'start' }}
                  >
                    <Stack>
                      <FormLabel mb={0} fontSize='13px'>
                        Correo Electronico
                      </FormLabel>
                      <Input
                        variant='outline'
                        type='email'
                        name='email'
                        width={{ base: 260, '2xl': 300 }}
                        max='2014-01-01'
                        value={email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {validateEmailIsLoading || emailValidation ? (
                        <Box maxW={300} mt={1} fontSize='xs'>
                          {validateEmailIsLoading ? (
                            <Text color='nucba.white'>Validando...</Text>
                          ) : (
                            <Text
                              color={
                                emailValidation === 'Correo válido'
                                  ? '#48BB78'
                                  : 'nucba.primary'
                              }
                            >
                              {emailValidation}
                            </Text>
                          )}
                        </Box>
                      ) : null}
                    </Stack>
                    {inputs.map(input => (
                      <WrapItem key={input.name}>
                        <FormOutlineInput {...input} />
                      </WrapItem>
                    ))}
                  </Wrap>
                  <Stack align={{ base: 'center', md: 'end' }}>
                    <Text fontSize='sm'>
                      ¿Ya tenes una cuenta?{' '}
                      <Text
                        as='a'
                        color='nucba.primary'
                        cursor='pointer'
                        _hover={{ textDecor: 'underline' }}
                        onClick={() => navigate('/inscripcion/ingresar-cuenta')}
                      >
                        Iniciá Sesión
                      </Text>
                    </Text>
                    <SubmitButton
                      errors={errors}
                      dirty={dirty}
                      isLoading={false}
                      disabled={Boolean(emailValidation)}
                    >
                      Siguiente
                    </SubmitButton>
                  </Stack>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Stack>
    </PageContainer>
  )
}
