import React from 'react'
import { useParams } from 'react-router-dom'
import { Heading, Text } from '@chakra-ui/react'

import { ExerciseResources } from '../components/ExerciseResources'
import { ContentTemplate } from '../../shared/template/ContentTemplate'
import { useGetAssignmentDetailsQuery } from '../services/assignment.service'
import { SectionContainerWithScroll } from '@/education/shared/components/SectionContainerWithScroll'
import { ExerciseDetailsLoader } from '@/education/shared/components/loaders/ExerciseDetailsLoader'
import { LinkifyWrapper } from '@/shared/components/LinkifyWrapper'

export const Assignment = () => {
  const { code, assignmentId } = useParams()

  const { data, isLoading } = useGetAssignmentDetailsQuery({
    assignmentId: assignmentId!,
    code: code!,
  })

  return (
    <ContentTemplate h='40vh' mt='50px'>
      <SectionContainerWithScroll h='100% !important' overflowY='unset'>
        {isLoading ? (
          <ExerciseDetailsLoader />
        ) : (
          <>
            <Heading lineHeight={2}>{data?.exercise.name}</Heading>
            <LinkifyWrapper>
              <Text whiteSpace='pre-wrap' pb={'20'}>
                {data?.exercise.description}
              </Text>
            </LinkifyWrapper>
            {!!data?.exercise.resources.length && (
              <>
                <Text as='h4' fontSize='xl' fontWeight='bold' lineHeight={2}>
                  Recursos:
                </Text>
                <ExerciseResources resources={data?.exercise.resources} />
              </>
            )}
          </>
        )}
      </SectionContainerWithScroll>
    </ContentTemplate>
  )
}
