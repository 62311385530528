import { Stack } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const SectionContainerWithScroll = styled(Stack)`
  width: 95%;
  height: 47vh;
  margin: 0.5rem 0;
  padding-right: 1rem;

  overflow-y: ${({ overflowY }) => overflowY || 'scroll'};

  &::-webkit-scrollbar {
    width: 12px;
    margin-left: 3rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #17181e;
    border-radius: 30px;
  }
`
