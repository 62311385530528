import React, { HTMLInputTypeAttribute } from 'react'
import { Field, FieldProps } from 'formik'
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
} from '@chakra-ui/react'
import { Input, PasswordInput } from '@nucba-coding/nucba-ui'
import { DateTime } from '@/shared/helpers'

type FormOutlineInputProps = {
  label: string
  name: string

  placeholder?: string

  /** @default text */

  type?: HTMLInputTypeAttribute
  onBlur?: () => void
}

export const FormOutlineInput = ({
  label,
  name,
  placeholder,
  type = 'text',
}: FormOutlineInputProps) => {
  const currentDate = DateTime.createFromDate(new Date()).formatDate({
    dateStyle: 'short',
  })

  const fechaOriginal = currentDate
  const partes = fechaOriginal.split('/')

  const nuevoAño = `20${partes[2]}`
  const nuevoMes = partes[1].padStart(2, '0')
  const nuevoDia = partes[0].padStart(2, '0')

  const fechaReorganizada = `${nuevoAño}-${nuevoMes}-${nuevoDia}`

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl
          isInvalid={Boolean(form.errors[name] && form.touched[name])}
        >
          <FormLabel fontSize='13px'>{label}</FormLabel>

          {type === 'password' ? (
            <PasswordInput
              width={{ base: 260, '2xl': 300 }}
              variant='outline'
              {...field}
            />
          ) : (
            <InputGroup>
              <Input
                variant='outline'
                type={type}
                placeholder={placeholder}
                width={{ base: 260, '2xl': 300 }}
                max={fechaReorganizada}
                {...field}
              />
            </InputGroup>
          )}

          <FormErrorMessage
            maxW={{ base: 250, sm: 300 }}
            color='nucba.primary'
            fontSize='xs'
          >
            {String(form.errors[name])}
          </FormErrorMessage>

          {name === 'password' && (
            <Box maxW={300} mt={1} fontSize='xs'>
              <FormHelperText fontSize='small'>
                Mínimo 8 caracteres, una minúscula, una mayúscula, un número y
                un símbolo.
              </FormHelperText>
              <FormHelperText fontSize='small'>
                Los símbolos permitidos son: @, $, !, %, *, ?, y &
              </FormHelperText>
            </Box>
          )}
        </FormControl>
      )}
    </Field>
  )
}
