import React from 'react'

import { HStack, Show, Stack, Text, WrapItem, useToast } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { SubmitButton } from '../SubmitButton'

import { useLoginMutation } from '@/auth/services/auth.service'
import { PageHeading } from '@/education/shared/components/PageHeading'
import Scrollable from '@/shared/components/Scroleable'
import { PASSWORD_VALIDATION, TOAST_DEFUALT_OPTIONS } from '@/shared/constants'
import { startSession } from '@/shared/features/session.slice'
import { useErrorMessage } from '@/shared/hooks'
import {
  PAYMENT_METHODS_TYPES,
  type CustomError,
  type Input,
} from '@/shared/types'
import { useDispatch } from 'react-redux'
import { FormOutlineInput } from '../FormOutlineInput'
import { Summary } from '../summary/Summary'
import { PageContainer } from '../../container/PageContainer'
import { checkoutLinkAdded, useCartState } from '../../features/cart.slice'
import { useCreateSubscriptionMutation } from '../../services/inscription.service'

const inputs: Input[] = [
  {
    name: 'email',
    label: 'Correo Electrónico',
    type: 'email',
  },
  {
    name: 'password',
    label: 'Contraseña',
    type: 'password',
  },
  {
    name: 'invitationEmail',
    label: 'Correo Electrónico de invitación',
  },
]

const initialValues = {
  email: '',
  invitationEmail: '',
  password: '',
}

const validationSchema = Yup.object({
  email: Yup.string().email('Email inválido').required('Campo requerido'),
  invitationEmail: Yup.string()
    .email('Email inválido')
    .required('Campo requerido'),
  password: Yup.string()
    .min(8, 'Mínimo 8 caracteres')
    .matches(
      PASSWORD_VALIDATION,
      'Debe contener al menos una minúscula, una mayúscula, un número y un símbolo',
    )
    .required('Campo requerido'),
})

export const LoginFormDuo = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { cohort, plan, coupon, paymentMethod, checkoutLink } = useCartState()

  const toast = useToast({
    ...TOAST_DEFUALT_OPTIONS,
    title: 'Lo sentimos, ha ocurrido un error',
    status: 'error',
    variant: 'top-accent',
  })

  const [createSubscription, { error: createSubscriptionError }] =
    useCreateSubscriptionMutation()
  useErrorMessage(createSubscriptionError)

  const [login, { error: loginError }] = useLoginMutation()
  useErrorMessage(loginError)

  const handleFormSubmit = async ({
    email,
    invitationEmail,
    password,
  }: typeof initialValues) => {
    const loginRes = await login({ email, password })

    if (!('error' in loginRes)) {
      dispatch(startSession(loginRes.data))
      const subscriptionRes = await createSubscription({
        planId: plan?.id || '',
        cohortId: cohort?.id || '',
        coupon: coupon?.code || '',
        invitationEmail: invitationEmail || '',
      })

      if (!('error' in subscriptionRes)) {
        dispatch(
          checkoutLinkAdded({
            checkoutLink: subscriptionRes.data.checkout_link,
          }),
        )
        navigate(
          paymentMethod?.type === PAYMENT_METHODS_TYPES.TRANSFER
            ? `/inscripcion/pago-pendiente/${checkoutLink}`
            : `/inscripcion/pago-pendiente`,
        )
      } else {
        toast({
          description: (subscriptionRes.error as CustomError).data.message,
        })
      }
    }
  }

  return (
    <PageContainer>
      <Stack spacing={8}>
        <Stack>
          <PageHeading>Iniciar sesión</PageHeading>
          <Text fontSize='14px'>Para poder iniciar sesión en NUCBA</Text>
        </Stack>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting, errors, dirty }) => (
            <Form>
              <Stack spacing={16} maxW={{ base: 600, '2xl': 700 }}>
                <HStack
                  wrap='wrap'
                  justify='space-between'
                  align='top'
                  spacing={0}
                  gap={5}
                >
                  {inputs.map(input => (
                    <WrapItem key={input.name}>
                      <FormOutlineInput {...input} />
                    </WrapItem>
                  ))}
                </HStack>

                <Stack>
                  <Text fontSize='sm'>
                    ¿Es la primera vez que usas NUCBA?{' '}
                    <Text
                      as='a'
                      color='nucba.primary'
                      cursor='pointer'
                      _hover={{ textDecor: 'underline' }}
                      onClick={() => {
                        navigate(`/inscripcion/crear-cuenta`)
                      }}
                    >
                      Regístrate
                    </Text>
                  </Text>
                </Stack>

                <SubmitButton
                  errors={errors}
                  dirty={dirty}
                  isLoading={isSubmitting}
                >
                  Iniciar sesión
                </SubmitButton>
              </Stack>
            </Form>
          )}
        </Formik>
      </Stack>
      <>
        <Show above='base' below='md'>
          <Scrollable withCta />
        </Show>
        <Show above='md'>
          <Stack w='60%'>
            <Summary />
          </Stack>
        </Show>
      </>
    </PageContainer>
  )
}
