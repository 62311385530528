import React from 'react'

import { HStack, Skeleton } from '@chakra-ui/react'

import { generateArrayWithRandomKeys } from '@/shared/utils'

export const ProductsLoader = () => {
  return (
    <HStack
      gap='15px'
      py={2.5}
      px={16}
      flexWrap={{ base: 'wrap', md: 'nowrap' }}
      justify='center'
    >
      {generateArrayWithRandomKeys(3).map(i => (
        <Skeleton key={i} borderRadius={30} minW='300px' h={400} />
      ))}
    </HStack>
  )
}
