import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { assignmentApi } from './assignment.service'
import { AssignmentStatus, CohortUserRoles } from '@/shared/enums'
import { RootState } from '@/shared/app/store'
import { SessionState } from '@/shared/features/session.slice'
import { randomKeyGenerator } from '@/shared/utils'
import { DateTime } from '@/shared/helpers'

type SubmitAssignmentRequest = {
  assignmentId: string
  body: {
    URL: string[]
    content: string
  }
}

type SubmitAssignmentResponse = {
  id: string
  status: AssignmentStatus
}

type SubmitCommentRequest = {
  assignmentId: string
  body: {
    content: string
  }
  payload: {
    senderId: string
  }
}

type SubmitCommentResponse = {
  id: string
  status: AssignmentStatus
}

type CorrectAssignemntRequest = {
  assignmentId: string
  body: {
    status?: AssignmentStatus
    content?: string
  }
  payload: {
    senderId?: string
  }
}

type CorrectAssignemntResponse = {
  id: string
  status: AssignmentStatus
}

export const assignmentActionsApi = createApi({
  reducerPath: 'assignmentActionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/entregas`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).session.token

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: builder => ({
    submitAssignment: builder.mutation<
      SubmitAssignmentResponse,
      SubmitAssignmentRequest
    >({
      query: ({ body, assignmentId }) => ({
        url: `/${assignmentId}/entregar`,
        method: 'POST',
        body,
      }),
    }),
    submitComment: builder.mutation<
      SubmitCommentResponse,
      SubmitCommentRequest
    >({
      query: ({ body, assignmentId }) => ({
        url: `/${assignmentId}/entregar`,
        method: 'POST',
        body,
      }),
      onQueryStarted(
        { assignmentId, body: { content }, payload: { senderId } },
        { dispatch, queryFulfilled, getState },
      ) {
        const { session } = getState() as any

        const { firstname, lastname, isStaff } = session as SessionState

        const newComment = {
          id: randomKeyGenerator(),
          content,
          senderId,
          author: `${firstname} ${lastname}`,
          cohortIntegrantBySenderid: {
            role: CohortUserRoles.ESTUDIANTE,
          },
        }

        const patchResult = dispatch(
          assignmentApi.util.updateQueryData(
            'getAssignmentComments',
            { assignmentId, isStaff },
            draft => ({
              ...draft,
              assignment_comments: [...draft.assignment_comments, newComment],
            }),
          ),
        )

        queryFulfilled.catch(patchResult.undo)
      },
    }),
    correctAssignment: builder.mutation<
      CorrectAssignemntResponse,
      CorrectAssignemntRequest
    >({
      query: ({ body, assignmentId }) => ({
        url: `/${assignmentId}/corregir`,
        method: 'POST',
        body,
      }),
      onQueryStarted(
        { assignmentId, body: { content, status }, payload: { senderId } },
        { dispatch, queryFulfilled, getState },
      ) {
        const { session } = getState() as any

        const { firstname, lastname, isStaff } = session as SessionState

        const { data } = assignmentApi.endpoints.getAssignmentComments.select({
          assignmentId,
          isStaff,
        })(getState() as RootState)

        const patchResult = dispatch(
          assignmentApi.util.updateQueryData(
            'getAssignmentComments',
            { assignmentId, isStaff },
            draft => {
              if (content && senderId) {
                const newComment = {
                  id: randomKeyGenerator(),
                  content,
                  senderId,
                  author: `${firstname}  ${lastname}`,
                  cohortIntegrantBySenderid: {
                    role: data?.exercise.isIntegrative
                      ? CohortUserRoles.MENTOR
                      : CohortUserRoles.COMENTOR,
                  },
                }

                return {
                  ...draft,
                  assignment_comments: [
                    ...draft.assignment_comments,
                    newComment,
                  ],
                }
              }

              if (status) {
                const now = DateTime.nowUTC().date

                const newStatusChange = {
                  id: randomKeyGenerator(),
                  new: status,
                  previous:
                    data?.assignmentStatus_changes.at(-1)?.new ?? status,
                  updatedAt: now,
                }

                return {
                  ...draft,
                  status,
                  assignmentStatus_changes: [
                    ...draft.assignmentStatus_changes,
                    newStatusChange,
                  ],
                }
              }
            },
          ),
        )

        queryFulfilled.catch(patchResult.undo)
      },
    }),
  }),
})

export const {
  useSubmitAssignmentMutation,
  useSubmitCommentMutation,
  useCorrectAssignmentMutation,
} = assignmentActionsApi
