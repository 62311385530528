import React, { useEffect } from 'react'

import { Stack, Box, HStack, Text, Button } from '@chakra-ui/react'
import { TabMenu } from '../components/tab/TabMenu'
import { PageContainer } from '../container/PageContainer'
import styled from '@emotion/styled'
import { useAppDispatch } from '@/shared/app/hooks'
import { clearCart } from '../features/cart.slice'
import { Logo } from '@/shared/components/Logo'
import { useGetNucbaCampaignsQuery } from '../services/inscriptionQuery.service'
import { motion } from 'framer-motion'
import { useNucbaTheme } from '@/shared/hooks'
import { useSessionState } from '@/shared/features/session.slice'
import { useNavigate } from 'react-router-dom'
import { Slider } from '@nucba-coding/nucba-ui'

const Title = styled.p`
  background: linear-gradient(
    117deg,
    #fff 50.39%,
    rgba(255, 255, 255, 0) 95.92%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  background-clip: text;
  font-style: normal;
  font-weight: 600;
  font-size: 100px;

  @media (max-width: 1024px) {
    font-size: 80px;
  }

  @media (max-width: 480px) {
    font-size: 40px;
  }
`

export const Inscription = () => {
  // Comentario de cabala
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { colors } = useNucbaTheme()
  const { session } = useSessionState()

  const { data } = useGetNucbaCampaignsQuery()

  useEffect(() => {
    dispatch(clearCart())
  }, [])

  const bannerVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.56 } },
  }

  return (
    <PageContainer>
      <Stack spacing={8} align='center' w='full'>
        {data?.campaigns[0] && (
          <motion.div
            variants={bannerVariants}
            initial='hidden'
            animate='visible'
            style={{
              position: 'fixed',
              top: 0,
              width: '100%',
              background: colors.nucba.primary,
              height: 'auto',
              paddingBlock: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 20,
            }}
          >
            <Text
              fontSize={{ base: 'xs', md: 'sm' }}
              textAlign='center'
              width='80%'
              h='min-content'
              p={{ base: '0px', md: '10px' }}
              overflow='hidden'
              textOverflow='ellipsis'
            >
              <b>{data?.campaigns[0].name}</b> {data?.campaigns[0].description}
            </Text>
          </motion.div>
        )}
        <Box />
        <HStack
          w='full'
          justifyContent={{ base: 'center', md: 'space-between' }}
        >
          <Box
            onClick={() => {
              navigate('/')
            }}
            cursor='pointer'
          >
            <Logo />
          </Box>
          <Box display={{ base: 'none', md: 'flex' }}>
            <Slider
              titles={[
                'Educación Competitiva',
                'Cursada en vivo',
                'Contenido variado',
                'Sistema de entregas',
              ]}
            />
          </Box>
        </HStack>
        <Box>
          <Title>Bienvenidos a NUCBA</Title>
        </Box>
        <HStack w={{ base: '100%', md: '60%' }}>
          <Text fontSize={{ base: 'xs', sm: 'md' }} textAlign='center'>
            Descubre en NUCBA tu próximo paso en la carrera digital.
            Comprometidos con brindar educación de calidad, llegamos a toda
            Latinoamérica. Estudiar con nosotros reduce la brecha del
            desconocimiento y te conecta con una red de estudiantes apasionados.{' '}
          </Text>
        </HStack>
        {session && (
          <Button
            onClick={() => {
              navigate('/')
            }}
          >
            Ir a la plataforma
          </Button>
        )}
        <TabMenu />
        <HStack>
          <Text fontWeight='bold'>Hecho con ❤️ en NUCBA</Text>
        </HStack>
      </Stack>
    </PageContainer>
  )
}
