import React from 'react'
import { Box } from '@chakra-ui/react'

type HeroProps = {
  children: React.ReactNode
  image: string
}

export const Hero = ({ children, image }: HeroProps) => {
  return (
    <Box
      maxW='100%'
      height='50vh'
      position='relative'
      bg={`url(${image})  center, linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20.25%, #131415 95%)`}
      bgBlendMode='darken'
    >
      {children}
    </Box>
  )
}
