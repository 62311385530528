import styled from '@emotion/styled'
import { motion } from 'framer-motion'

export const Slider = styled(motion.span)`
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;

  height: 4px;
  width: 50px;
  margin: 0 auto;

  border-radius: 8px;
`
