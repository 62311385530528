import React, { ReactElement } from 'react'
import { HStack, Text } from '@chakra-ui/react'
import { NucCode } from '@nucba-coding/nucba-ui'

import { openInNewTab } from '@/shared/utils'

type AboutCourseProps = {
  title: string
  description: string
  items?: { element: string | ReactElement; to?: string }[]
}

export const AboutCourse = ({
  title,
  description,
  items = [],
}: AboutCourseProps) => {
  return (
    <>
      <Text fontSize='xl' fontWeight={700} textTransform='uppercase'>
        {title}
      </Text>
      <Text color='#E6E6E6' fontSize='sm' fontWeight={400}>
        {description}
      </Text>
      {items?.map((item, index) => (
        <HStack
          key={index}
          onClick={item.to ? () => openInNewTab(item.to!) : () => null}
        >
          <NucCode />
          {typeof item.element === 'string' ? (
            <Text
              textTransform='uppercase'
              cursor='pointer'
              _hover={{ textDecoration: 'underline' }}
            >
              {item.element}
            </Text>
          ) : (
            item.element
          )}
        </HStack>
      ))}
    </>
  )
}
