import React, { useEffect } from 'react'

import { HStack, Stack, Text, WrapItem } from '@chakra-ui/react'
import * as Yup from 'yup'

import { PageHeading } from '../components/PageHeading'
import { PageContainer } from '../container/PageContainer'

import { useSessionState } from '@/shared/features/session.slice'
import { useNucbaTheme } from '@/shared/hooks'
import { Input } from '@/shared/types'
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { FormOutlineInput } from '../components/FormOutlineInput'
import { SubmitButton } from '../components/SubmitButton'

const inputs: Input[] = [
  {
    name: 'instagram',
    label: 'Instagram',
  },
  {
    name: 'twitter',
    label: 'Twitter',
  },
]

const initialValues = {
  instagram: '',
  twitter: '',
}

const validationSchema = Yup.object({
  instagram: Yup.string(),
  twitter: Yup.string(),
})

export const SocialMedia = () => {
  const navigate = useNavigate()
  const { colors } = useNucbaTheme()
  const { token } = useSessionState()

  useEffect(() => {
    if (!token) {
      navigate('/inscripcion')
    }
  }, [token])

  const handleSubmit = async (values: typeof initialValues) => {
    const { instagram, twitter } = values
    return navigate('/inscripcion/TyC', {
      state: {
        instagram,
        twitter,
      },
      replace: true,
    })
  }

  return (
    <HStack h='60vh'>
      <Stack w='100%' mx='auto' align='center'>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ dirty, errors }) => (
            <Form>
              <PageContainer>
                <Stack spacing={8}>
                  <Stack textAlign={{ base: 'center', md: 'start' }}>
                    <PageHeading title='4. Completa tus redes sociales' />
                  </Stack>
                  <Stack spacing={16} maxW={{ base: 600, '2xl': 700 }}>
                    <HStack
                      spacing={0}
                      gap={5}
                      flexWrap='wrap'
                      justify={{ base: 'center', md: 'flex-start' }}
                    >
                      {inputs.map(input => (
                        <WrapItem key={input.name}>
                          <FormOutlineInput {...input} />
                        </WrapItem>
                      ))}
                    </HStack>
                    <HStack
                      alignSelf={{ base: 'center', md: 'flex-end' }}
                      alignItems='center'
                      spacing={4}
                    >
                      <Text
                        fontSize='sm'
                        color={colors.nucba['white-70']}
                        cursor='pointer'
                        _hover={{ textDecoration: 'underline' }}
                        onClick={() => navigate('/inscripcion/TyC')}
                      >
                        Omitir
                      </Text>
                      <SubmitButton
                        errors={errors}
                        dirty={dirty}
                        isLoading={false}
                      >
                        Siguiente
                      </SubmitButton>
                    </HStack>
                  </Stack>
                </Stack>
              </PageContainer>
            </Form>
          )}
        </Formik>
      </Stack>
    </HStack>
  )
}
