import React, { useEffect } from 'react'
import { Show, Stack, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import { Summary } from '../components/summary/Summary'
import { PageContainer } from '../container/PageContainer'
import { PaymentConfirmation } from '../components/PaymentConfirmation'
import { TransferPayment } from '../components/TransferPayment'
import { PageHeading } from '../components/PageHeading'
import { useCartState } from '../features/cart.slice'
import { PAYMENT_METHODS_TYPES } from '@/shared/types'
import Scrollable from '@/shared/components/Scroleable'

export const PendingPay = () => {
  const navigate = useNavigate()
  const { paymentMethod, checkoutLink } = useCartState()
  const isTransfer = paymentMethod?.type === PAYMENT_METHODS_TYPES.TRANSFER

  useEffect(() => {
    if (!checkoutLink) {
      navigate('/inscripcion/crear-cuenta', { replace: true })
    }
  }, [checkoutLink])

  return (
    <PageContainer>
      <Stack spacing={8}>
        <PageHeading title='Pago' />
        <Text fontSize='14px'>
          Para confirmar tu inscripción, debés realizar el pago.
        </Text>
        {isTransfer ? <TransferPayment /> : <PaymentConfirmation />}
      </Stack>
      <>
        <Show above='base' below='md'>
          <Scrollable withCta />
        </Show>
        <Show above='md'>
          <Stack w='60%'>
            <Summary />
          </Stack>
        </Show>
      </>
    </PageContainer>
  )
}
