import { createApi } from '@reduxjs/toolkit/query/react'
import { gql } from 'graphql-request'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'

import { CACHE_TIME } from '@/shared/constants'
import { RootState } from '@/shared/app/store'

type GetCourseDetailsResponse = {
  courses: {
    name: string
    image: string
    description: string
    teaser: string
    Certificates: {
      course: {
        name: string
      }
      product: {
        name: string
      }
      type: 'PARTIAL' | 'INTEGRATIVE'
      createdAt: Date
      user: {
        profiles: {
          firstname: string
          lastname: string
        }[]
      }
    }[]
  }[]
}

type GetCourseDetailsTransformResponse = {
  name: string
  image: string
  description: string
  teaser: string
  Certificates: {
    course: {
      name: string
    }
    product: {
      name: string
    }
    type: 'PARTIAL' | 'INTEGRATIVE'
    createdAt: Date
    user: {
      profiles: {
        firstname: string
        lastname: string
      }[]
    }
  }[]
}

export const courseApi = createApi({
  reducerPath: 'courseApi',
  baseQuery: graphqlRequestBaseQuery({
    url: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).session.token

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  keepUnusedDataFor: CACHE_TIME,
  endpoints: builder => ({
    getCourseDetails: builder.query<
      GetCourseDetailsTransformResponse,
      { courseId: string; userId: string }
    >({
      query: ({ courseId, userId }) => ({
        document: gql`
          query CourseDetails($id: String, $userId: String) {
            courses(where: { id: { _eq: $id } }) {
              name
              image
              description
              teaser
              Certificates(where: { userId: { _eq: $userId } }) {
                course {
                  name
                }
                product {
                  name
                }
                type
                createdAt
                user {
                  profiles {
                    firstname
                    lastname
                  }
                  id
                }
              }
            }
          }
        `,
        variables: {
          id: courseId,
          userId,
        },
      }),
      transformResponse: (data: GetCourseDetailsResponse) => data.courses[0],
    }),
  }),
})

export const { useGetCourseDetailsQuery, usePrefetch: useCoursePrefetch } =
  courseApi
