import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Image } from '@chakra-ui/react'

type IsologoProps = {
  /**@default 90 */
  size?: number
}

export const Isologo = ({ size = 90 }: IsologoProps) => {
  const navigate = useNavigate()

  return (
    <Image
      cursor='pointer'
      boxSize={`${size}px`}
      src='https://res.cloudinary.com/dcatzxqqf/image/upload/v1681409550/Galileo-Platform/Assets/LogoI_gdk6aa_1_1_ep1o8l.webp'
      alt='Nucba Logo'
      onClick={() => navigate('/')}
    />
  )
}
