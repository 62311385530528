import React, { useEffect } from 'react'
import { HStack, Stack, Text, WrapItem } from '@chakra-ui/react'
import * as Yup from 'yup'

import { PageHeading } from '../../components/PageHeading'
import { PageContainer } from '../../container/PageContainer'

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useRegisterDuoMutation } from '../../services/inscription.service'
import { useErrorMessage, useRedirect } from '@/shared/hooks'
import { useAppDispatch } from '@/shared/app/hooks'
import { updateSession } from '@/shared/features/session.slice'
import { Form, Formik } from 'formik'
import { FormOutlineInput } from '../../components/FormOutlineInput'
import { Input } from '@/shared/types'
import { SubmitButton } from '../../components/SubmitButton'

const inputs: Input[] = [
  {
    name: 'country',
    label: 'País',
  },
  {
    name: 'province',
    label: 'Provincia',
  },
  {
    name: 'city',
    label: 'Ciudad',
  },
  {
    name: 'locality',
    label: 'Localidad',
  },
  {
    name: 'street',
    label: 'Calle',
  },
  {
    name: 'number',
    label: 'Altura',
    type: 'number',
  },
  {
    name: 'postalCode',
    label: 'Código Postal',
  },
]

const initialValues = {
  country: '',
  province: '',
  city: '',
  locality: '',
  street: '',
  number: '',
  postalCode: '',
}

const validationSchema = Yup.object({
  country: Yup.string()
    .required('Campo requerido')
    .max(255, 'El país no puede tener más de 255 caracteres'),
  province: Yup.string()
    .required('Campo requerido')
    .max(255, 'La provincia no puede tener más de 255 caracteres'),
  city: Yup.string()
    .required('Campo requerido')
    .max(255, 'La ciudad no puede tener más de 255 caracteres'),
  locality: Yup.string()
    .required('Campo requerido')
    .max(255, 'La localidad no puede tener más de 255 caracteres'),
  street: Yup.string()
    .required('Campo requerido')
    .max(255, 'La calle no puede tener más de 255 caracteres'),
  number: Yup.number()
    .required('Campo requerido')
    .integer('La altura debe ser un entero'),
  postalCode: Yup.string()
    .required('Campo requerido')
    .max(55, 'El código postal no puede tener más de 55 caracteres'),
})

export const PersonalInfoDuo = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  useEffect(() => {
    if (!token) {
      navigate('/inscripcion')
    }
  }, [token])

  useRedirect(
    '/inscripcion/crear-cuenta-duo',
    'email',
    'password',
    'firstname',
    'lastname',
    'document',
    'phone',
    'birthdate',
  )

  const [registerDuo, { error: registerDuoError }] = useRegisterDuoMutation()
  useErrorMessage(registerDuoError)

  const handleSubmit = async (values: typeof initialValues) => {
    const body = {
      email: state.email,
      password: state.password,
      token: token!,
      profile: {
        firstname: state.firstname,
        lastname: state.lastname,
        document: String(state.document),
        phone: String(state.phone),
        birthdate: state.birthdate,
        address: { ...values, number: String(values.number) },
      },
    }

    const res = await registerDuo(body)

    if (!('error' in res)) {
      dispatch(updateSession({ token: res.data.token }))
      navigate('/inscripcion/redes-sociales')
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ dirty, errors, isSubmitting }) => (
        <Form>
          <PageContainer>
            <Stack spacing={8} m='auto'>
              <Stack align={{ base: 'center', md: 'start' }}>
                <PageHeading title='2. Datos Personales' />
                <Text fontSize='14px'>Para poder inscribirte en NUCBA</Text>
              </Stack>
              <Stack spacing={16} maxW={{ base: 600, '2xl': 700 }}>
                <HStack
                  wrap='wrap'
                  justify={{ base: 'center', md: 'space-between' }}
                  spacing={0}
                  gap={5}
                >
                  {inputs.map(input => (
                    <WrapItem key={input.name}>
                      <FormOutlineInput {...input} />
                    </WrapItem>
                  ))}
                </HStack>
                <SubmitButton
                  errors={errors}
                  dirty={dirty}
                  isLoading={isSubmitting}
                  alignSelf={{ base: 'center', md: 'end' }}
                >
                  Crear cuenta
                </SubmitButton>
              </Stack>
            </Stack>
          </PageContainer>
        </Form>
      )}
    </Formik>
  )
}
