import React from 'react'
import {
  isRouteErrorResponse,
  useLocation,
  useNavigate,
  useRouteError,
} from 'react-router-dom'
import { Box, Button, Image, Stack, Text } from '@chakra-ui/react'

import { ERROR_MESSAGES } from '../constants'
import { useNucbaTheme } from '../hooks'

export const Error = () => {
  const { colors } = useNucbaTheme()
  const { pathname } = useLocation()

  const error = useRouteError()
  const navigate = useNavigate()

  return (
    <Stack
      justify='center'
      alignItems='center'
      flexDirection='row'
      height='100vh'
      maxWidth='80%'
      m='0 auto'
      gap='1rem'
      fontFamily="'Poppins',sans-serif"
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='flex-start'
        gap={10}
        width='40%'
      >
        <Text
          color={colors.nucba.primary}
          as='h2'
          fontSize='7xl'
          fontWeight={700}
        >
          {isRouteErrorResponse(error)
            ? `${error.status} Error`
            : 'Error desconocido'}
        </Text>
        <Text as='h3' fontSize='xl' fontWeight={700}>
          {isRouteErrorResponse(error)
            ? error.statusText
            : 'Algo inesperado pasó'}
        </Text>
        <Text>
          {isRouteErrorResponse(error)
            ? ERROR_MESSAGES[String(error.status)]
            : 'Parece que la página que buscás no existe o fue removida'}
        </Text>
        {pathname.includes('/inscripcion') ? (
          <Button onClick={() => navigate('/inscripcion', { replace: true })}>
            Volver a Inscripción
          </Button>
        ) : (
          <Button onClick={() => navigate('/', { replace: true })}>
            Volver al inicio
          </Button>
        )}
      </Box>

      <Box display='flex' width='40%'>
        <Image
          objectFit='contain'
          src='https://res.cloudinary.com/dcatzxqqf/image/upload/v1681409377/Galileo-Platform/Images/banner/SteveJobs_dy1jcs_n2qceg.webp'
          alt='Steve Jobs'
        />
      </Box>
    </Stack>
  )
}
