import React from 'react'
import { useParams } from 'react-router-dom'
import { StyleProps, Text } from '@chakra-ui/react'

import { useGetAmountOfSubmittedAssignmentsQuery } from '@/education/cohorts/services/cohort.service'

type AmountOfSubmittedAssignmentsProps = {
  exerciseId: string
  textStyles?: StyleProps
}

export const AmountOfSubmittedAssignments = ({
  exerciseId,
  textStyles,
}: AmountOfSubmittedAssignmentsProps) => {
  const { code } = useParams()

  const { data: assignemnts, isLoading } =
    useGetAmountOfSubmittedAssignmentsQuery({
      code: code!,
      exerciseId,
    })

  if (isLoading) {
    return <Text>Cargando</Text>
  }

  return (
    <Text fontSize='xs' {...textStyles}>
      {assignemnts?.submitted}/{assignemnts?.total} Entregas
    </Text>
  )
}
