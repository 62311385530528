import * as React from 'react'
import { motion, useTransform } from 'framer-motion'

import { SheetDraggableProps } from './types'
import { useSheetContext } from './context'
import { useDragConstraints } from './hooks'
import { mergeRefs } from './utils'
import styles from './styles'
import { Box, VStack } from '@chakra-ui/react'

const SheetHeader = React.forwardRef<any, SheetDraggableProps>(
  ({ children, style, disableDrag, ...rest }, ref) => {
    const { indicatorRotation, dragProps } = useSheetContext()
    const { constraintsRef, onMeasureDragConstraints } = useDragConstraints()
    const _dragProps = disableDrag ? undefined : dragProps

    const indicator1Transform = useTransform(
      indicatorRotation,
      r => `translateX(2px) rotate(${r}deg)`,
    )

    const indicator2Transform = useTransform(
      indicatorRotation,
      r => `translateX(-2px) rotate(${-1 * r}deg)`,
    )

    return (
      <motion.div
        {...rest}
        ref={mergeRefs([ref, constraintsRef])}
        style={{ ...styles.headerWrapper, ...style }}
        {..._dragProps}
        dragConstraints={constraintsRef}
        onMeasureDragConstraints={onMeasureDragConstraints}
      >
        {children || (
          <VStack
            bg={`url(https://res.cloudinary.com/dcatzxqqf/image/upload/v1679871402/Galileo-Platform/Images/banner/assigmentBannerBlur_r7edoz.webp) center/cover no-repeat ,  linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 56.25%, #000000 92.64%)`}
          >
            <div className='react-modal-sheet-header' style={styles.header}>
              <motion.span
                className='react-modal-sheet-drag-indicator'
                style={{ ...styles.indicator, transform: indicator1Transform }}
              />
              <motion.span
                className='react-modal-sheet-drag-indicator'
                style={{ ...styles.indicator, transform: indicator2Transform }}
              />
            </div>
            <Box p={2}>
              <span>Detalle de la inscripción</span>
            </Box>
          </VStack>
        )}
      </motion.div>
    )
  },
)

SheetHeader.displayName = 'SheetHeader'

export default SheetHeader
