import React, { useState, useEffect } from 'react'
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react'
import { useLocation, Link } from 'react-router-dom'

type BreadcurmbState = {
  path: string
  to: string
}[]

export const Breadcrumb = () => {
  const [breadcrumb, setBreadcrumb] = useState<BreadcurmbState>([])
  const { pathname, state } = useLocation()

  const routes = [
    {
      path: '/',
      name: 'Home',
    },
    {
      path: 'camada',
      name: 'Camada',
    },
    {
      path: 'curso',
      name: 'Curso',
    },
    {
      path: 'clase',
      name: 'Clase',
    },
    {
      path: 'asignacion',
      name: 'Asignación',
    },
    {
      path: 'entregas',
      name: 'Entregas',
    },
    {
      path: 'entrega',
      name: 'Entrega',
    },
    {
      path: 'detalles',
      name: 'Detalles',
    },
    {
      path: 'entregar',
      name: 'Entregar',
    },
    {
      path: 'devolucion',
      name: 'Devolución',
    },
  ]

  useEffect(() => {
    const paths = pathname.split('/').filter(path => path)

    paths.unshift('/')

    const breadcrumb = paths
      .map((path, index) => {
        const route = routes.find(route => route.path === path)

        return {
          path: route && route.name,
          to:
            route &&
            `${paths
              .slice(
                0,
                paths.findIndex(
                  (path, i) =>
                    routes.find(route => route.path === path) && i > index,
                ),
              )
              .join('/')
              .replaceAll('//', '/')}`,
        }
      })
      .filter(route => route.path && route.to)

    setBreadcrumb(breadcrumb as BreadcurmbState)
  }, [pathname])

  return (
    <ChakraBreadcrumb spacing={1}>
      {breadcrumb.map(({ path, to }, index) => (
        <BreadcrumbItem fontWeight={600} fontSize='sm' key={path}>
          {breadcrumb.length !== index + 1 ? (
            <BreadcrumbLink as={Link} to={to} state={state}>
              {path}
            </BreadcrumbLink>
          ) : (
            <BreadcrumbLink color='nucba.primary' isCurrentPage>
              {path}
            </BreadcrumbLink>
          )}
        </BreadcrumbItem>
      ))}
    </ChakraBreadcrumb>
  )
}
