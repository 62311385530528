import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { HStack, Text } from '@chakra-ui/react'
import { Button } from '@nucba-coding/nucba-ui'

import {
  useExercisePrefetch,
  useGetExercisesByCourseQuery,
} from '../services/exercise.service'

import { CardContainer } from '@/education/shared/components/ClassCardContainer'
import { ClassCardContent } from '@/education/shared/components/ClassCardContent'
import { AmountOfSubmittedAssignments } from '@/education/shared/components/AmountOfSubmittedAssignments'
import { assignmentIsDisable } from '@/education/shared/utils'
import { DateTime } from '@/shared/helpers'
import { ListLoader } from '@/education/shared/components/loaders/ListLoader'

export const ListOfExercises = () => {
  const { code } = useParams()
  const { state } = useLocation()
  const navigate = useNavigate()

  const { data, isLoading } = useGetExercisesByCourseQuery({
    courseId: state && state.courseId!,
    code: code!,
  })

  const prefetchExerciseDetails = useExercisePrefetch('getExerciseDetails')

  return isLoading ? (
    <ListLoader />
  ) : (
    <>
      {data?.cohort_classes.map(_class => {
        const isPending = assignmentIsDisable(_class.date)

        const { id: exerciseId, name } = _class.class.exercises[0]

        return (
          <CardContainer key={_class.id} cursor='auto !important'>
            <ClassCardContent heading={name}>
              <HStack spacing={8}>
                {isPending ? (
                  <HStack gap={2} divider={<span>|</span>}>
                    <Text fontSize='sm'>
                      Disponible el{' '}
                      {DateTime.createFromDate(new Date(_class.date), false)
                        .addDays(1)
                        .formatDate({ dateStyle: 'short' })}
                    </Text>
                    <Text
                      textTransform='capitalize'
                      fontSize='sm'
                      color='nucba.primary'
                    >
                      Pendiente
                    </Text>
                  </HStack>
                ) : (
                  <HStack gap={2} divider={<span>|</span>}>
                    <AmountOfSubmittedAssignments
                      exerciseId={exerciseId}
                      textStyles={{ fontSize: 'sm' }}
                    />
                    <Text color='#48BB78' fontSize='sm'>
                      Disponible
                    </Text>
                  </HStack>
                )}
                <Button
                  textTransform='uppercase'
                  borderRadius={20}
                  size='sm'
                  fontSize='sm'
                  fontWeight={700}
                  onClick={() => navigate(`entrega/${exerciseId}`, { state })}
                  onMouseEnter={
                    !isPending
                      ? () =>
                          prefetchExerciseDetails({
                            code: code!,
                            exerciseId,
                          })
                      : () => null
                  }
                >
                  Corregir
                </Button>
              </HStack>
            </ClassCardContent>
          </CardContainer>
        )
      })}
    </>
  )
}
