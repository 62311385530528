import { createApi } from '@reduxjs/toolkit/query/react'
import { gql } from 'graphql-request'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'

import { AssignmentStatus } from '@/shared/enums'
import { Comment } from '@/shared/types'
import { CACHE_TIME } from '@/shared/constants'
import { RootState } from '@/shared/app/store'

type GetAssignmentDetailsResponse = {
  users_assignments: {
    dueDate: string
    status: AssignmentStatus
    exercise: {
      id: string
      name: string
      solution: string
      resources: string[]
      description: string
      defaultAnswers: string[]
      isIntegrative: boolean
      class: {
        name: string
        cohort_classes: {
          date: Date
        }[]
      }
    }
  }[]
}

type GetAssignmentDetailsTransformResponse = {
  dueDate: string
  status: AssignmentStatus
  exercise: {
    id: string
    name: string
    solution: string
    resources: string[]
    description: string
    defaultAnswers: string[]
    isIntegrative: boolean
    class: {
      name: string
      cohort_classes: {
        date: Date
      }[]
    }
  }
}

type GetAssignmentCommentsResponse = {
  users_assignments: {
    URL: string[]
    dueDate: string
    status: AssignmentStatus
    exercise: {
      isIntegrative: boolean
    }
    assignmentStatus_changes: {
      id: string
      new: AssignmentStatus
      previous: AssignmentStatus
      updatedAt: Date
    }[]
    assignment_comments: Comment[]
  }[]
}

type GetAssignmentCommentsTransformResponse = {
  URL: string[]
  dueDate: string
  status: AssignmentStatus
  exercise: {
    isIntegrative: boolean
  }
  assignmentStatus_changes: {
    id: string
    new: AssignmentStatus
    previous: AssignmentStatus
    updatedAt: Date
  }[]
  assignment_comments: Comment[]
}

type GetAssignmentsByExerciseResponse = {
  users_assignments: {
    id: string
    status: AssignmentStatus
    cohortIntegrantByStudentid: {
      user: {
        profiles: [
          {
            firstname: string
            lastname: string
          },
        ]
      }
    }
    assignmentStatus_changes: {
      updatedAt: Date
    }[]
  }[]
}

type GetAllAssignmentsByStudentResponse = {
  users_assignments: {
    id: string
  }[]
}

export const assignmentApi = createApi({
  reducerPath: 'assignmentApi',
  baseQuery: graphqlRequestBaseQuery({
    url: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).session.token

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  keepUnusedDataFor: CACHE_TIME,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: ['Assignment', 'Comment', 'Exercise'],
  endpoints: builder => ({
    getAssignmentDetails: builder.query<
      GetAssignmentDetailsTransformResponse,
      { assignmentId: string; code: string }
    >({
      query: ({ assignmentId, code }) => ({
        document: gql`
          query AssignmentDetails($assignmentId: String, $code: String) {
            users_assignments(where: { id: { _eq: $assignmentId } }) {
              dueDate
              status
              exercise {
                id
                name
                solution
                resources
                description
                defaultAnswers
                isIntegrative
                class {
                  name
                  cohort_classes(where: { cohort: { code: { _eq: $code } } }) {
                    date
                  }
                }
              }
            }
          }
        `,
        variables: {
          assignmentId,
          code,
        },
      }),
      providesTags: ['Assignment'],
      transformResponse: (data: GetAssignmentDetailsResponse) =>
        data.users_assignments[0],
    }),
    getAssignmentComments: builder.query<
      GetAssignmentCommentsTransformResponse,
      { assignmentId: string; isStaff: boolean }
    >({
      query: ({ assignmentId, isStaff }) => ({
        document: gql`
          query AssignmentComments($id: String_comparison_exp) {
            users_assignments(where: { id: $id }) {
              URL
              dueDate
              status
              exercise {
                isIntegrative
              }
              assignmentStatus_changes(order_by: { updatedAt: asc }) {
                id
                new
                previous
                updatedAt
              }
              assignment_comments(order_by: { createdAt: asc }) {
                id
                content
                senderId
                author
                cohortIntegrantBySenderid {
                  role
                ${isStaff ? 'user { id profiles { firstname lastname } }' : ''}
                }
              }
            }
          }
        `,
        variables: {
          id: {
            _eq: assignmentId,
          },
        },
      }),
      providesTags: ['Assignment', 'Comment'],
      transformResponse: (data: GetAssignmentCommentsResponse) =>
        data.users_assignments[0],
    }),
    getAssignmentsByExercise: builder.query<
      GetAssignmentsByExerciseResponse,
      { code: string; exerciseId: string }
    >({
      query: ({ code, exerciseId }) => ({
        document: gql`
          query AssignmentsByExercise($code: String, $exerciseId: String) {
            users_assignments(
              where: {
                cohort: { code: { _eq: $code } }
                exerciseId: { _eq: $exerciseId }
              }
            ) {
              id
              status
              cohortIntegrantByStudentid {
                user {
                  profiles {
                    firstname
                    lastname
                  }
                }
              }
              assignmentStatus_changes(
                order_by: { updatedAt: asc }
                where: { new: { _eq: "ENTREGADA" } }
                limit: 1
              ) {
                updatedAt
              }
            }
          }
        `,
        variables: {
          code,
          exerciseId,
        },
      }),
      providesTags: ['Exercise'],
    }),
    getAllAssignmentsByStudent: builder.query<
      GetAllAssignmentsByStudentResponse,
      { studentId: string }
    >({
      query: ({ studentId }) => ({
        document: gql`
          query AllAssignmentsByStudent($studentId: String) {
            users_assignments(where: { studentId: { _eq: $studentId } }) {
              id
            }
          }
        `,
        variables: {
          studentId,
        },
      }),
    }),
  }),
})

export const {
  useGetAssignmentDetailsQuery,
  useGetAssignmentCommentsQuery,
  useGetAssignmentsByExerciseQuery,
  useGetAllAssignmentsByStudentQuery,
  usePrefetch: useAssignmentPrefetch,
} = assignmentApi
