import React from 'react'
import styled from '@emotion/styled'

import {
  useGetUserDuosQuery,
  useGetUserSubscriptionsQuery,
} from '@/shared/services/userQuery.service'
import { useSessionState } from '@/shared/features/session.slice'
import { ContentTemplate } from '../../shared/template/ContentTemplate'
import { HStack, Heading, Stack, Text, VStack } from '@chakra-ui/react'
import { SubscriptionsCardLoader } from '@/education/shared/components/loaders/SubscriptionCardLoader'
import { SubscriptionCard } from '../components/SubscriptionCard'
import { DuoCard } from '../components/DuoCard'
import { NucCart } from '@nucba-coding/nucba-ui'
import { Link } from 'react-router-dom'

export const SectionWithScroll = styled(VStack)`
  max-height: 40vh;
  margin: 0.5rem 0;
  padding-right: 1rem;

  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
    margin-left: 3rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #17181e;
    border-radius: 30px;
  }
`

export const Subscriptions = () => {
  const { id } = useSessionState()

  const { data: subscriptions, isLoading: subscriptionsLoading } =
    useGetUserSubscriptionsQuery(id)

  const { data: duos, isLoading: duosLoading } = useGetUserDuosQuery(id)
  const isLoading = subscriptionsLoading || duosLoading

  return (
    <ContentTemplate>
      <Stack w={{ md: '70%' }} maxW={800} p={10} pt={20} spacing={12}>
        <Stack spacing={6}>
          <HStack justify='space-between' align='center'>
            <Heading as='h2' fontSize='2xl' fontWeight={600}>
              MIS SUSCRIPCIONES
            </Heading>
            <HStack>
              <Text
                as={Link}
                to='/inscripcion'
                _hover={{ textDecoration: 'underline', fontWeight: 'bold' }}
              >
                Ir a la tienda
              </Text>
              <NucCart />
            </HStack>
          </HStack>
          <SectionWithScroll
            justify='space-between'
            align='self-start'
            spacing={8}
            paddingBottom={4}
          >
            {isLoading ? (
              <SubscriptionsCardLoader />
            ) : subscriptions!.length > 0 ? (
              subscriptions?.map(sub => (
                <SubscriptionCard
                  key={sub.id}
                  {...sub}
                  invoice={sub.invoices.at(0)}
                />
              ))
            ) : (
              <HStack>
                <Text>
                  Aún no tiene suscripciones en NUCBA. Inscribite en nuestra
                </Text>
                <Text
                  as={Link}
                  to='/inscripcion'
                  color='nucba.primary'
                  _hover={{ textDecoration: 'underline', fontWeight: 'bold' }}
                >
                  Tienda
                </Text>
              </HStack>
            )}
          </SectionWithScroll>
        </Stack>
        <Stack spacing={6}>
          <Heading as='h2' fontSize='2xl' fontWeight={600}>
            MIS DÚOS
          </Heading>
          <SectionWithScroll
            justify='space-between'
            align='self-start'
            spacing={8}
          >
            {isLoading ? (
              <SubscriptionsCardLoader />
            ) : duos!.length > 0 ? (
              duos?.map(duo => <DuoCard key={duo.id} {...duo} />)
            ) : (
              <Stack>
                <HStack>
                  <Text>
                    Aún no formas parte de un dúo en NUCBA. Inscribite en
                    nuestra
                  </Text>
                  <Text
                    as={Link}
                    to='/inscripcion'
                    color='nucba.primary'
                    _hover={{ textDecoration: 'underline', fontWeight: 'bold' }}
                  >
                    Tienda
                  </Text>
                </HStack>
                <Text>
                  ¡Descubre el poder del aprendizaje en equipo con la
                  suscripción DUO de NUCBA! Con nuestra <b> modalidad 2x1 </b>,
                  obtienes el doble de beneficios al suscribirte junto a otra
                  persona.
                </Text>
              </Stack>
            )}
          </SectionWithScroll>
        </Stack>
      </Stack>
    </ContentTemplate>
  )
}
