import React from 'react'
import { Stack, Text } from '@chakra-ui/react'
import { ProgressBar } from '@nucba-coding/nucba-ui'

type ProgressBarWithLabelProps = {
  label: string
  value: number
}

export const ProgressBarWithLabel = ({
  label,
  value,
}: ProgressBarWithLabelProps) => {
  return (
    <Stack align='center' spacing={3}>
      <Text textTransform='uppercase'>{label}</Text>
      <ProgressBar value={value} w={{ base: 200, sm: 500 }} />
    </Stack>
  )
}
