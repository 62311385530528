import React, { ReactNode } from 'react'
import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
} from '@chakra-ui/react'

type CheckboxProps = {
  children: ReactNode
} & ChakraCheckboxProps

export const Checkbox = ({ children, ...props }: CheckboxProps) => {
  return (
    <ChakraCheckbox
      iconColor='nucba.primary'
      colorScheme='whiteAlpha'
      isFocusable
      {...props}
    >
      {children}
    </ChakraCheckbox>
  )
}
