import React, { useEffect } from 'react'
import { Show, Stack, Text } from '@chakra-ui/react'

import { Summary } from '../components/summary/Summary'
import { PageContainer } from '../container/PageContainer'
import { PageHeading } from '../components/PageHeading'

import { CreateAccountForm } from '../components/forms/CreateAccountForm'
import { useNavigate } from 'react-router-dom'
import Scrollable from '@/shared/components/Scroleable'
import { useCartState } from '../features/cart.slice'
import { CreateAccountFormDuo } from '../components/forms/CreateAccountFormDuo'

export const CreateAccount = () => {
  const navigate = useNavigate()
  const { plan, cohort } = useCartState()

  useEffect(() => {
    if (!plan || !cohort) {
      navigate('/inscripcion')
    }
  }, [plan])

  return (
    <PageContainer>
      <Stack spacing={8}>
        <Stack align={{ base: 'center', md: 'start' }}>
          <PageHeading title='1. Cuenta' />
          <Text fontSize='14px'>Para poder crear tu cuenta en NUCBA</Text>
        </Stack>
        {plan?.isDuo ? <CreateAccountFormDuo /> : <CreateAccountForm />}
      </Stack>
      <>
        <Show above='base' below='md'>
          <Scrollable withCta />
        </Show>
        <Show above='md'>
          <Stack w='50%'>
            <Summary />
          </Stack>
        </Show>
      </>
    </PageContainer>
  )
}
