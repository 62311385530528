import React, { FormEvent, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { HStack, Button as ChakraButton, Stack } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import ResizeTextarea from 'react-textarea-autosize'
import { NucSend } from '@nucba-coding/nucba-ui'

import { AssignmentsActions } from './AssignmentsActions'
import { InputWithScroll } from './InputWithScroll'
import { assignmentState } from '../features/assignment.slice'
import {
  useCorrectAssignmentMutation,
  useSubmitCommentMutation,
} from '../services/assignmentActions.service'
import { assignmentApi } from '../services/assignment.service'

import { sessionState } from '@/shared/features/session.slice'
import { useAppDispatch, useAppSelector } from '@/shared/app/hooks'
import { AssignmentStatus, CohortUserRoles } from '@/shared/enums'
import { useErrorMessage, useStudent } from '@/shared/hooks'
import { useGetCohortUserRoleQuery } from '@/education/cohorts/services/cohort.service'

type SubmitCommentProps = {
  isIntegrative: boolean
}

export const SubmitComment = ({ isIntegrative }: SubmitCommentProps) => {
  const { assignmentId, code } = useParams()

  const [content, setContent] = useState<string>('')

  const dispatch = useAppDispatch()
  const { isStaff, id } = useAppSelector(sessionState)
  const { status } = useAppSelector(assignmentState)
  const { studentId } = useStudent(code!)

  const { data } = useGetCohortUserRoleQuery(
    { userId: id, code: code! },
    { skip: !isStaff },
  )

  const isCorrector =
    (isIntegrative && data?.role === CohortUserRoles.MENTOR) ||
    (!isIntegrative && data?.role === CohortUserRoles.COMENTOR)

  const [
    submitCommentAsStudent,
    { isSuccess: isStudentCommentSuccess, error: isStudentCommentError },
  ] = useSubmitCommentMutation()

  const [
    submitCommentAsStaff,
    { isSuccess: isStaffCommentSuccess, error: isStaffCommentError },
  ] = useCorrectAssignmentMutation()

  useErrorMessage(isStudentCommentError)
  useErrorMessage(isStaffCommentError)

  useEffect(() => {
    if (isStudentCommentSuccess || isStaffCommentSuccess) {
      dispatch(assignmentApi.util.invalidateTags(['Comment']))
    }
  }, [isStudentCommentSuccess, isStaffCommentSuccess])

  const isAble =
    status === AssignmentStatus.EN_REVISION ||
    status === AssignmentStatus.ENTREGADA

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setContent('')

    if (isStaff) {
      return submitCommentAsStaff({
        assignmentId: assignmentId!,
        body: { content },
        payload: { senderId: studentId },
      })
    }

    return submitCommentAsStudent({
      assignmentId: assignmentId!,
      body: { content },
      payload: { senderId: studentId },
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <HStack align='flex-end'>
        <Stack flexGrow={6}>
          {isCorrector && <AssignmentsActions />}
          <Stack>
            <InputWithScroll
              as={ResizeTextarea}
              value={content}
              placeholder={
                isAble && (!isStaff || isCorrector)
                  ? 'Escribí aquí.... (DALE, PONE ALGO).'
                  : 'Chat no disponible'
              }
              isReadOnly={!isAble || (isStaff && !isCorrector)}
              mt={2}
              minH={70}
              maxH={300}
              fontWeight={400}
              letterSpacing={0.75}
              borderRadius={16}
              cursor={isAble ? 'text' : 'not-allowed'}
              onChange={e => setContent(e.target.value)}
            />
          </Stack>
        </Stack>
        <ChakraButton
          as={motion.button}
          type='submit'
          whileTap={{ scale: 0.95 }}
          title='Enviar'
          minW={50}
          mb='5px !important'
          bgColor='#373B44'
          isDisabled={!content || !isAble}
        >
          <NucSend width='18' height='18' />
        </ChakraButton>
      </HStack>
    </form>
  )
}
