import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { HStack, useToast } from '@chakra-ui/react'

import { DefaultAnswersPopover } from './DefaultAnswersPopover'
import { useCorrectAssignmentMutation } from '../services/assignmentActions.service'
import {
  assignmentState,
  updateCurrentAssignment,
} from '../features/assignment.slice'
import {
  assignmentApi,
  useGetAssignmentDetailsQuery,
} from '../services/assignment.service'
import { exerciseApi } from '../services/exercise.service'

import { AssignmentStatus } from '@/shared/enums'
import { useAppDispatch, useAppSelector } from '@/shared/app/hooks'
import { ConfirmationModal } from '@/shared/components/ConfirmationModal'
import { useErrorMessage } from '@/shared/hooks'
import { sessionState } from '@/shared/features/session.slice'
import { TOAST_DEFUALT_OPTIONS } from '@/shared/constants'

export const AssignmentsActions = () => {
  const toast = useToast({
    ...TOAST_DEFUALT_OPTIONS,
    status: 'success',
  })

  const { code, assignmentId } = useParams()

  const dispatch = useAppDispatch()
  const { isStaff } = useAppSelector(sessionState)
  const { status } = useAppSelector(assignmentState)

  const isDelivered = status === AssignmentStatus.ENTREGADA
  const isInRevision = status === AssignmentStatus.EN_REVISION

  const { data: assignment } = useGetAssignmentDetailsQuery(
    {
      assignmentId: assignmentId!,
      code: code!,
    },
    {
      skip: !isStaff,
    },
  )

  const [correctAssignment, { error, isSuccess }] =
    useCorrectAssignmentMutation()
  useErrorMessage(error)

  useEffect(() => {
    if (isSuccess) {
      dispatch(assignmentApi.util.invalidateTags(['Comment', 'Exercise']))
      dispatch(exerciseApi.util.invalidateTags(['Exercise']))
    }
  }, [isSuccess])

  const handleCorrectAssignment = (status: AssignmentStatus) => {
    correctAssignment({
      assignmentId: assignmentId!,
      body: {
        status,
      },
      payload: {},
    })

    dispatch(updateCurrentAssignment({ status }))

    toast({
      title:
        status === AssignmentStatus.APROBADA
          ? 'Entrega aprobada correctamente'
          : status === AssignmentStatus.VENCIDA
            ? 'Entrega vencida y chat cerrado correctamente'
            : 'Entrega puesta en revisión',
    })
  }

  return (
    <HStack justify='space-between'>
      <HStack>
        <DefaultAnswersPopover
          title='respuestas guardadas'
          btnText='mensajes directos'
          answers={assignment?.exercise.defaultAnswers || []}
        />
      </HStack>
      <HStack>
        {assignment?.exercise.isIntegrative && (
          <>
            <ConfirmationModal
              btnText='Cerrar chat'
              btnProps={{
                disabled: !isDelivered && !isInRevision,
                _disabled: {
                  bgColor: 'nucba.gray',
                  cursor: 'not-allowed',
                },
              }}
              title='¿Desea Cerrar este chat?'
              action={() => handleCorrectAssignment(AssignmentStatus.VENCIDA)}
            />
            <ConfirmationModal
              btnText='En Revisión'
              btnProps={{
                disabled: !isDelivered,
                _disabled: {
                  bgColor: 'nucba.gray',
                  cursor: 'not-allowed',
                },
              }}
              title='¿Desea poner en revisión esta entrega?'
              action={() =>
                handleCorrectAssignment(AssignmentStatus.EN_REVISION)
              }
            />
          </>
        )}
        <ConfirmationModal
          btnText='Aprobar'
          btnProps={{
            bgColor: 'nucba.primary',
            _hover: { bgColor: 'nucba.primaryHover' },
            _active: { bgColor: 'nucba.primary' },
            disabled: !isDelivered || isInRevision,
          }}
          title='¿Desea aprobar esta entrega?'
          action={() => handleCorrectAssignment(AssignmentStatus.APROBADA)}
        />
      </HStack>
    </HStack>
  )
}
