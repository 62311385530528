import React, { useEffect } from 'react'
import { HStack, Stack, WrapItem } from '@chakra-ui/react'
import * as Yup from 'yup'

import { useAppDispatch } from '@/shared/app/hooks'
import { initialState, updateSession } from '@/shared/features/session.slice'
import { useErrorMessage, useRedirect } from '@/shared/hooks'
import { Input } from '@/shared/types'
import { Form, Formik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { FormOutlineInput } from '../FormOutlineInput'
import { SubmitButton } from '../SubmitButton'
import { checkoutLinkAdded, useCartState } from '../../features/cart.slice'
import {
  useCreateSubscriptionMutation,
  useRegisterMutation,
} from '../../services/inscription.service'

const inputs: Input[] = [
  {
    name: 'country',
    label: 'País',
  },
  {
    name: 'province',
    label: 'Provincia',
  },
  {
    name: 'city',
    label: 'Ciudad',
  },
  {
    name: 'locality',
    label: 'Localidad',
  },
  {
    name: 'street',
    label: 'Calle',
  },
  {
    name: 'number',
    label: 'Altura',
    type: 'number',
  },
  {
    name: 'postalCode',
    label: 'Código Postal',
  },
]

const initialValues = {
  country: '',
  province: '',
  city: '',
  locality: '',
  street: '',
  number: '',
  postalCode: '',
}

const validationSchema = Yup.object({
  country: Yup.string()
    .required('Campo requerido')
    .max(255, 'El país no puede tener más de 255 caracteres'),
  province: Yup.string()
    .required('Campo requerido')
    .max(255, 'La provincia no puede tener más de 255 caracteres'),
  city: Yup.string()
    .required('Campo requerido')
    .max(255, 'La ciudad no puede tener más de 255 caracteres'),
  locality: Yup.string()
    .required('Campo requerido')
    .max(255, 'La localidad no puede tener más de 255 caracteres'),
  street: Yup.string()
    .required('Campo requerido')
    .max(255, 'La calle no puede tener más de 255 caracteres'),
  number: Yup.number()
    .required('Campo requerido')
    .integer('La altura debe ser un entero'),
  postalCode: Yup.string()
    .required('Campo requerido')
    .max(55, 'El código postal no puede tener más de 55 caracteres'),
})

export const PersonalInfoForm = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  useRedirect(
    '/inscripcion/crear-cuenta',
    'email',
    'password',
    'firstname',
    'lastname',
    'document',
    'phone',
    'birthdate',
  )

  const dispatch = useAppDispatch()
  const { cohort, plan, coupon } = useCartState()

  useEffect(() => {
    if (!cohort || !plan) {
      navigate('/inscripcion/planes')
    }
  }, [state])

  const [register, { error: registerError }] = useRegisterMutation()
  useErrorMessage(registerError)

  const [createSubscription, { error: createSubscriptionError }] =
    useCreateSubscriptionMutation()
  useErrorMessage(createSubscriptionError)

  const handleSubmit = async (values: typeof initialValues) => {
    const body = {
      email: state.email,
      password: state.password,
      profile: {
        firstname: state.firstname,
        lastname: state.lastname,
        document: String(state.document),
        phone: String(state.phone),
        birthdate: state.birthdate,
        address: { ...values, number: String(values.number) },
      },
    }

    const res = await register(body)

    if (!('error' in res)) {
      dispatch(updateSession({ ...initialState, token: res.data.token }))

      const subscriptioRes = await createSubscription({
        planId: plan?.id || '',
        cohortId: cohort?.id || '',
        coupon: coupon?.code || '',
        invitationEmail: state.invitationEmail,
      })

      if ('data' in subscriptioRes && subscriptioRes.data) {
        dispatch(
          checkoutLinkAdded({
            checkoutLink: subscriptioRes.data.checkout_link,
          }),
        )

        navigate(`/inscripcion/pago-pendiente`)
      } else {
        console.error(
          'Error or missing data in createSubscription response:',
          subscriptioRes,
        )
      }
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ dirty, errors, isSubmitting }) => (
        <Form>
          <Stack spacing={8}>
            <Stack spacing={16} maxW={{ base: 600, '2xl': 700 }}>
              <HStack
                wrap='wrap'
                justify={{ base: 'center', md: 'space-between' }}
                spacing={0}
                gap={5}
              >
                {inputs.map(input => (
                  <WrapItem key={input.name}>
                    <FormOutlineInput {...input} />
                  </WrapItem>
                ))}
              </HStack>
              <SubmitButton
                errors={errors}
                dirty={dirty}
                isLoading={isSubmitting}
                alignSelf={{ base: 'center', md: 'self-start' }}
              >
                Crear cuenta
              </SubmitButton>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}
