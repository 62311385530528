import { Stack } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const CardContainer = styled(Stack)`
  background-color: #17181e;
  padding-left: ${props => (props.simplifiedVersion ? 0 : '3rem')};
  padding-block: 0.75rem;
  border-radius: 20px;
  user-select: none;
  cursor: ${props =>
    props.hasExercise && !props.simplifiedVersion ? 'pointer' : 'auto'};
`
