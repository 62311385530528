import React, { useEffect, useState } from 'react'
import {
  ButtonProps,
  Modal as ChakraModal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  StyleProps,
  useDisclosure,
} from '@chakra-ui/react'
import { Button, ButtonSecondary } from '@nucba-coding/nucba-ui'
import { useLocation } from 'react-router-dom'

const OverlayOne = () => (
  <ModalOverlay bg='blackAlpha.400' backdropFilter='blur(12px) ' />
)

type ModalProps = {
  title: string
  btnText?: string

  btnProps?: StyleProps & ButtonProps

  action?: () => void
  onMouseEnter?: () => void
  modalIsOpen?: boolean
  isNotCancelable?: boolean
}

export const ConfirmationModal = ({
  title,
  btnText,
  btnProps,
  action,
  modalIsOpen,
  isNotCancelable,
}: ModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [overlay, setOverlay] = useState(<OverlayOne />)
  const { pathname } = useLocation()

  useEffect(() => {
    if (modalIsOpen !== undefined) {
      modalIsOpen ? onOpen() : onClose()
    }
  }, [modalIsOpen, onOpen, onClose])

  useEffect(() => {
    if (pathname === '/') {
      onOpen()
    }
  }, [pathname, onOpen])

  return (
    <>
      {btnText && (
        <ButtonSecondary
          fontSize={{ base: 'sm', '2xl': 'sm' }}
          textTransform='capitalize'
          onClick={() => {
            setOverlay(<OverlayOne />)
            onOpen()
          }}
          {...btnProps}
        >
          {btnText}
        </ButtonSecondary>
      )}
      <ChakraModal
        isCentered
        isOpen={isOpen}
        onClose={isNotCancelable ? () => null : onClose}
      >
        {overlay}
        <ModalContent
          bg='nucba.background'
          maxW='30%'
          padding={4}
          borderRadius={15}
        >
          <ModalHeader fontSize='sm' fontWeight='bold' textAlign='center'>
            {title}
          </ModalHeader>

          <ModalFooter m='0 auto' mt={1}>
            {!isNotCancelable && (
              <ButtonSecondary fontSize='md' w='50%' mr={4} onClick={onClose}>
                Cancelar
              </ButtonSecondary>
            )}
            <Button
              fontSize='md'
              w={!isNotCancelable ? '50%' : '100%'}
              borderRadius={30}
              onClick={
                action
                  ? () => {
                      action()
                      onClose()
                    }
                  : onClose
              }
            >
              Aceptar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  )
}
