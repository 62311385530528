import React from 'react'
import { StyleProps, Text } from '@chakra-ui/react'

import { openInNewTab } from '@/shared/utils'

type AssignmentLinkProps = {
  text: string
  link: string
} & StyleProps

export const AssignmentLink = ({
  text,
  link,
  ...rest
}: AssignmentLinkProps) => {
  return (
    <Text
      as='a'
      textTransform='uppercase'
      cursor='pointer'
      _hover={{
        textDecoration: 'underline',
      }}
      onClick={() => openInNewTab(link)}
      {...rest}
    >
      {text}
    </Text>
  )
}
