import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Heading, Stack, Text } from '@chakra-ui/react'
import { Button } from '@nucba-coding/nucba-ui'

import { assignmentIsDisable, slugifyCourseName } from '../../shared/utils'
import {
  useGetCohortNextAssignmentByStudentQuery,
  useGetCohortNextClassQuery,
} from '../services/cohort.service'
import { useAssignmentPrefetch } from '@/education/assignments/services/assignment.service'
import { BannerLoader } from '@/education/shared/components/loaders/BannerLoader'
import { useStudent } from '@/shared/hooks/useStudent'
import { useAppSelector } from '@/shared/app/hooks'
import { sessionState } from '@/shared/features/session.slice'
import { DateTime } from '@/shared/helpers'

type BannerProps = {
  bgImage: string
}

const isNextAssignmentDisableDueToStudentExpelled = (
  expelledAt: Date,
  assignmentOpenDay: Date,
) => {
  return DateTime.createFromDate(new Date(expelledAt!)).lowerThan(
    DateTime.createFromDate(new Date(assignmentOpenDay)),
  )
}

export const Banner = ({ bgImage }: BannerProps) => {
  const navigate = useNavigate()
  const { code } = useParams()
  const { studentId, expelledAt } = useStudent(code!)

  const { isStaff } = useAppSelector(sessionState)

  const { data: nextAssignment, isLoading: nextAssignmentIsLoading } =
    useGetCohortNextAssignmentByStudentQuery(
      { studentId, code: code! },
      {
        skip: isStaff,
      },
    )

  const { data: nextClass, isLoading: nextClassIsLoading } =
    useGetCohortNextClassQuery(code!, {
      skip: !isStaff,
    })

  const prefetchAssignmentDetails = useAssignmentPrefetch(
    'getAssignmentDetails',
    {
      force: false,
    },
  )
  return nextAssignmentIsLoading || nextClassIsLoading ? (
    <BannerLoader />
  ) : nextAssignment || nextClass ? (
    <Stack
      flexGrow={1}
      maxW='800px'
      h='full'
      justify='space-between'
      py={4}
      px={8}
      bg={`url(${bgImage}) center/cover no-repeat ,  linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 56.25%, #000000 92.64%)`}
      borderRadius={23}
    >
      {!isStaff &&
      expelledAt &&
      isNextAssignmentDisableDueToStudentExpelled(
        expelledAt,
        nextAssignment!.exercise.class.cohort_classes[0].date!,
      ) ? (
        <Stack>
          <Heading as='h3' fontSize={{ base: 'xl', '2xl': '2xl' }}>
            Lo sentimos, ya no perteneces a esta camada
          </Heading>
          <Text fontSize={{ base: 'md', '2xl': 'lg' }}>
            No podrás ver las siguientes entregas
          </Text>
        </Stack>
      ) : (
        <>
          <Stack spacing={1}>
            <Heading as='h3' fontSize={{ base: 'lg', '2xl': '1.5rem' }}>
              📋{' '}
              {isStaff ? nextClass?.class.name : nextAssignment?.exercise.name}
            </Heading>
            <Text fontSize={{ base: 'sm', '2xl': 'lg' }}>
              {isStaff
                ? 'Esta va a ser tu próxima clase.'
                : 'Esta va a ser tu próxima entrega.'}
            </Text>
          </Stack>
          {!isStaff && (
            <Button
              alignSelf='flex-end'
              paddingInline={5}
              fontSize='sm'
              borderRadius={30}
              textTransform='uppercase'
              disabled={assignmentIsDisable(
                nextAssignment?.exercise.class.cohort_classes[0]!.date ||
                  new Date(),
              )}
              onClick={() =>
                navigate(
                  `curso/${slugifyCourseName(
                    nextAssignment?.exercise.class.course.name || '',
                  )}/asignacion/${nextAssignment?.id}`,
                  {
                    state: {
                      fromApplication: true,
                      courseId: nextAssignment?.exercise.class.course.id || '',
                    },
                  },
                )
              }
              onMouseEnter={() =>
                prefetchAssignmentDetails({
                  assignmentId: nextAssignment!.id,
                  code: code!,
                })
              }
            >
              {assignmentIsDisable(
                nextAssignment?.exercise.class.cohort_classes[0]!.date ||
                  new Date(),
              )
                ? 'Entrega no disponible'
                : 'Ir a la entrega'}
            </Button>
          )}
        </>
      )}
    </Stack>
  ) : (
    <Stack
      flexGrow={1}
      h='full'
      justify='space-around'
      paddingInline={8}
      bg={`url(${bgImage}) center/cover no-repeat, linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 56.25%, #000000 92.64%)`}
      borderRadius={23}
    >
      <Stack>
        <Heading as='h3' fontSize={{ base: 'xl', '2xl': '3xl' }}>
          {' '}
          📋{' '}
          {isStaff
            ? 'Todavia no está disponible tu próxima clase 😣 '
            : 'Todavia no está disponible tu próxima entrega 😣 '}
        </Heading>
        <Text fontSize={{ base: 'sm', '2xl': 'lg' }}>
          {isStaff
            ? 'En los próximos días se publicará tu próxima clase'
            : 'En los próximos días será publicada'}
        </Text>
      </Stack>
    </Stack>
  )
}
