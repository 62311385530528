import { combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createTransform from 'redux-persist/es/createTransform'

// SERVICES

import { authApi } from '@/auth/services/auth.service'
import { inscriptionApi } from '@/inscription/services/inscription.service'
import { inscriptionQueryApi } from '@/inscription/services/inscriptionQuery.service'
import { cohortApi } from '@/education/cohorts/services/cohort.service'
import { courseApi } from '@/education/cohorts/services/course.service'
import { assignmentApi } from '@/education/assignments/services/assignment.service'
import { assignmentActionsApi } from '@/education/assignments/services/assignmentActions.service'
import { exerciseApi } from '@/education/assignments/services/exercise.service'
import { profileApi } from '@/education/cohorts/services/profile.service'
import { userMutationApi } from '../services/user-mutation.service'
import { userQueryApi } from '../services/userQuery.service'
import { contractQueryApi } from '@/education/subscriptions/services/contract-query.service'
import { billingQueryApi } from '@/education/subscriptions/services/billing-query.service'
import { billingMutationApi } from '@/education/subscriptions/services/billing-mutation.service'
import { contractMutationApi } from '@/education/subscriptions/services/contract-mutation.service'

// FEATURES

import sessionReducer from '../features/session.slice'
import assignmentReducer from '@/education/assignments/features/assignment.slice'
import cartReducer from '@/inscription/features/cart.slice'

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [inscriptionApi.reducerPath]: inscriptionApi.reducer,
  [inscriptionQueryApi.reducerPath]: inscriptionQueryApi.reducer,
  [cohortApi.reducerPath]: cohortApi.reducer,
  [courseApi.reducerPath]: courseApi.reducer,
  [assignmentApi.reducerPath]: assignmentApi.reducer,
  [assignmentActionsApi.reducerPath]: assignmentActionsApi.reducer,
  [exerciseApi.reducerPath]: exerciseApi.reducer,
  [userQueryApi.reducerPath]: userQueryApi.reducer,
  [userMutationApi.reducerPath]: userMutationApi.reducer,
  [contractQueryApi.reducerPath]: contractQueryApi.reducer,
  [contractMutationApi.reducerPath]: contractMutationApi.reducer,
  [billingQueryApi.reducerPath]: billingQueryApi.reducer,
  [billingMutationApi.reducerPath]: billingMutationApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  session: sessionReducer,
  assignment: assignmentReducer,
  cart: cartReducer,
})

const whitelist = ['session', 'cart']

const SetTransform = createTransform(
  inboundState => {
    return window.btoa(JSON.stringify(inboundState))
  },
  outboundState => {
    return JSON.parse(window.atob(outboundState))
  },
  { whitelist },
)

export const persistedReducer = persistReducer(
  { key: 'root', storage, whitelist, transforms: [SetTransform] },
  rootReducer,
)
