import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '@/shared/app/store'
import { AssignmentStatus } from '@/shared/enums'

interface AssignmentState {
  id: string
  status: AssignmentStatus | ''
}

const initialState: AssignmentState = {
  id: '',
  status: '',
}

type CurrentAssignment = {
  id: string
  status: AssignmentStatus
}

const assignmentSlice = createSlice({
  name: 'assignment',
  initialState,
  reducers: {
    currentAssignment(state, action: PayloadAction<CurrentAssignment>) {
      return { ...state, ...action.payload }
    },
    updateCurrentAssignment(
      state,
      action: PayloadAction<Partial<CurrentAssignment>>,
    ) {
      return { ...state, ...action.payload }
    },
    restartAssignment() {
      return initialState
    },
  },
})

export const assignmentState = (state: RootState): AssignmentState =>
  state.assignment

export const { currentAssignment, updateCurrentAssignment, restartAssignment } =
  assignmentSlice.actions
export default assignmentSlice.reducer
