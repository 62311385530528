import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Center, Grid, GridItem, Text } from '@chakra-ui/react'
import { Card } from '@nucba-coding/nucba-ui'

import { PageHeading } from '../../shared/components/PageHeading'
import { slugifyCourseName } from '../../shared/utils'
import {
  useGetCohortCoursesFirstClassesQuery,
  useGetCohortCoursesQuery,
} from '../services/cohort.service'
import { useStudent } from '@/shared/hooks'
import { useCoursePrefetch } from '../services/course.service'
import { useAppSelector } from '@/shared/app/hooks'
import { sessionState } from '@/shared/features/session.slice'
import { CohortCoursesLoader } from '@/education/shared/components/loaders/CohortCoursesLoader'
import { CohortClassStatus } from '@/shared/enums'
import { DateTime } from '@/shared/helpers'

const isCourseDisableDueToStudentExpelled = (
  expelledAt: Date,
  courseFirstClassDate: Date,
) => {
  return DateTime.createFromDate(new Date(expelledAt!)).lowerThan(
    DateTime.createFromDate(new Date(courseFirstClassDate)),
  )
}

export const CohortCourses = () => {
  const { code } = useParams()
  const navigate = useNavigate()
  const { studentId, expelledAt } = useStudent(code!)

  const { id, isStaff } = useAppSelector(sessionState)

  const prefetchCourseDetails = useCoursePrefetch('getCourseDetails')

  const { data, isLoading } = useGetCohortCoursesQuery({
    code: code!,
    studentId,
  })

  const { data: coursesFirstClasses, isLoading: isCoursesFirstClassesLoading } =
    useGetCohortCoursesFirstClassesQuery(
      {
        code: code!,
        studentId,
      },
      {
        skip: !expelledAt,
      },
    )

  return (
    <>
      <PageHeading mb={3}>Mis módulos</PageHeading>
      <Grid
        templateColumns={{
          base: 'repeat(auto-fit, 250px)',
          '2xl': 'repeat(auto-fit, 330px)',
        }}
        gap={10}
        mb={3}
      >
        {isLoading || isCoursesFirstClassesLoading ? (
          <CohortCoursesLoader />
        ) : (
          data?.cohorts[0]._products_cohorts.map(({ product }) =>
            product._product_courses.map(({ course }, index) => {
              const isAble =
                isStaff ||
                !index ||
                product._product_courses[index - 1].course.classes[0]
                  .cohort_classes[0].status === CohortClassStatus.FINALIZADA

              if (expelledAt) {
                const existCourse =
                  coursesFirstClasses?._products_cohorts.flatMap(
                    ({ product }) =>
                      product._product_courses.filter(
                        ({ course: _course }) => _course.id === course.id,
                      ),
                  )

                if (existCourse) {
                  const firstClassDate =
                    existCourse[0].course.classes[0].cohort_classes[0].date

                  const isDisabled = isCourseDisableDueToStudentExpelled(
                    expelledAt,
                    firstClassDate,
                  )

                  if (isDisabled) {
                    return null
                  }
                }
              }

              return isAble ? (
                <GridItem
                  key={course.id}
                  onClick={() =>
                    navigate(`curso/${slugifyCourseName(course.name)}`, {
                      state: { courseId: course.id, courseName: course.name },
                    })
                  }
                  onMouseEnter={() =>
                    prefetchCourseDetails({ courseId: course.id, userId: id })
                  }
                >
                  <Card
                    w='auto'
                    h={{ base: 260, '2xl': 400 }}
                    title={course.name}
                    bgImage={course.image}
                  />
                </GridItem>
              ) : (
                <GridItem key={course.id} pos='relative' cursor='not-allowed'>
                  <Card
                    w='auto'
                    h={{ base: 260, '2xl': 400 }}
                    title={course.name}
                    bgImage={course.image}
                  />
                  <Center
                    pos='absolute'
                    inset={0}
                    w='full'
                    h={{ base: 260, '2xl': 400 }}
                    backdropFilter='blur(3px);'
                  >
                    <Text fontSize='xl' textAlign='center' wordBreak='keep-all'>
                      Próximamente disponible
                    </Text>
                  </Center>
                </GridItem>
              )
            }),
          )
        )}
      </Grid>
    </>
  )
}
