import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Stack, StyleProps } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { ContentTemplate } from '../../shared/template/ContentTemplate'
import { ClassCard } from './ClassCard'
import {
  useGetCohortClassesByCourseQuery,
  useGetCohortNextClassQuery,
} from '../services/cohort.service'
import { useStudent } from '@/shared/hooks/useStudent'
import { ListLoader } from '@/education/shared/components/loaders/ListLoader'
import { CohortClassStatus } from '@/shared/enums'
import { DateTime } from '@/shared/helpers'

type ListOfClassesProps = {
  courseId?: string
  wrapperStyles?: StyleProps
  simplifiedVersion?: boolean
  withScroll?: boolean
}

const isClassDisableDueToStudentExpelled = (
  expelledAt: Date,
  classDate: Date,
) => {
  return DateTime.createFromDate(new Date(expelledAt!)).lowerThan(
    DateTime.createFromDate(new Date(classDate)),
  )
}

export const ListOfClasses = ({
  courseId,
  withScroll = false,
  wrapperStyles,
  simplifiedVersion = false,
}: ListOfClassesProps) => {
  const { code, classId } = useParams()
  const { state } = useLocation()
  const { studentId, expelledAt } = useStudent(code!)

  const { data: nextClassId } = useGetCohortNextClassQuery(code!, {
    skip: simplifiedVersion,
  })

  const { data, isLoading } = useGetCohortClassesByCourseQuery({
    code: code!,
    courseId: state ? state.courseId : courseId || '',
    studentId,
  })

  const isSomeClassInCourse = data?.cohort_classes.some(
    _class => _class.status === CohortClassStatus.EN_CURSO,
  )
  const [expanded, setExpanded] = useState<false | number>(false)

  return (
    <ContentTemplate {...wrapperStyles}>
      <ClassesContainer
        spacing={5}
        align='flex-end'
        withScroll={withScroll || simplifiedVersion}
        simplifiedVersion={simplifiedVersion}
      >
        {isLoading ? (
          <ListLoader withScroll={withScroll || simplifiedVersion} />
        ) : (
          data?.cohort_classes.map(
            (_class, index) =>
              (!!expelledAt &&
                isClassDisableDueToStudentExpelled(
                  expelledAt,
                  _class.date,
                )) || (
                <ClassCard
                  key={_class.id}
                  cohortClassID={_class.id}
                  i={index}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  classDetail={{ ..._class.class }}
                  date={_class.date}
                  status={_class.status}
                  remarkClass={
                    simplifiedVersion
                      ? classId === _class.id
                      : isSomeClassInCourse
                        ? _class.status === CohortClassStatus.EN_CURSO
                        : nextClassId?.id === _class.id
                  }
                  simplifiedVersion={simplifiedVersion}
                />
              ),
          )
        )}
      </ClassesContainer>
    </ContentTemplate>
  )
}

const ClassesContainer = styled(Stack)`
  width: 95%;
  height: ${({ withScroll, simplifiedVersion }) =>
    withScroll && simplifiedVersion ? '80vh' : withScroll ? '47vh' : 'auto'};

  align-items: ${({ simplifiedVersion }) =>
    simplifiedVersion ? 'flex-start' : ''};
  padding-bottom: ${({ withScroll }) => (withScroll ? '10px' : '20px')};
  padding-block: ${({ withScroll }) => (withScroll ? '10px' : '20px')};
  padding-left: ${({ withScroll }) => (withScroll ? '2px' : 0)};

  overflow-y: ${({ withScroll }) => (withScroll ? 'scroll' : 'unset')};

  &::-webkit-scrollbar {
    width: 8px;
    margin-left: 3rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #17181e;
    border-radius: 30px;
  }
`
