import { RootState } from '@/shared/app/store'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { PAYMENT_METHODS_TYPES, PAYMENT_STATUS } from '@/shared/types'

interface UploadReceiptFileResponse {
  message: string
  data: {
    id: string
    subscriptionId: string
    status: PAYMENT_STATUS
    amount: number
    dueDate: Date
    paymentMethod: PAYMENT_METHODS_TYPES
    payments: [
      {
        id: string
        depositUrl: string
        status: string
      },
    ]
  }
}

export const billingMutationApi = createApi({
  reducerPath: 'billingMutationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/facturacion`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).session.token

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: builder => ({
    uploadReceiptFile: builder.mutation<
      UploadReceiptFileResponse,
      { formData: FormData; id: string }
    >({
      query: ({ formData, id }) => ({
        url: `/boletas/${id}/adjuntar-comprobante`,
        method: 'POST',
        body: formData,
      }),
    }),
  }),
})

export const { useUploadReceiptFileMutation } = billingMutationApi
