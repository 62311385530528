/**
 * @param money El valor monetario a formatear
 * @param options Las opciones de formateo. La currency por defecto es 'ARS'
 * @returns string
 */

export const formatMoney = (
  money: number,
  options: Omit<Intl.NumberFormatOptions, 'style'> = { currency: 'ARS' },
): string => {
  return new Intl.NumberFormat('es-AR', {
    style: 'currency',
    ...options,
  }).format(money)
}

export const reduceByPercentage = (money: number, percentage: number) => {
  const reduction = money * (percentage / 100)
  const difference = money - reduction
  const rounded = Math.round(difference)

  return rounded
}
