import React from 'react'
import { StyleProps } from '@chakra-ui/react'
import { Button } from '@nucba-coding/nucba-ui'
import { FormikErrors } from 'formik'

type SubmitButtonProps = {
  children: React.ReactNode
  isLoading: boolean
  errors: FormikErrors<any>
  dirty: boolean
  disabled?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg'
} & StyleProps

export const SubmitButton = ({
  children,
  isLoading,
  errors,
  dirty,
  disabled = false,
  size,
  ...rest
}: SubmitButtonProps) => {
  const isDisable = disabled || Boolean(...Object.values(errors)) || !dirty

  return (
    <Button
      type='submit'
      isDisabled={isDisable}
      disabled={isDisable}
      isLoading={isLoading}
      w='max-content'
      fontSize='md'
      size={size}
      {...rest}
    >
      {children}
    </Button>
  )
}
