import React from 'react'
import { Avatar, HStack, Stack, Text } from '@chakra-ui/react'
import { Message } from '@nucba-coding/nucba-ui'

import { CohortUserRoles } from '@/shared/enums'
import { LinkifyWrapper } from '@/shared/components/LinkifyWrapper'

type ChatItemProps = {
  message: string

  /**@default false */
  isSender?: boolean

  /**@default false */
  isConsecutive?: boolean

  author: string
  role: CohortUserRoles
}

export const ChatItem = ({
  isSender = false,
  isConsecutive = false,
  message,
  author,
  role,
}: ChatItemProps) => {
  return (
    <Stack spacing={3} align={isSender ? 'flex-end' : 'flex-start'}>
      {!isConsecutive && (
        <HStack
          mt={3}
          ml={isSender ? 0 : 2}
          mr={isSender ? 2 : 0}
          gap={isSender ? 4 : 0}
        >
          <Avatar name={author} borderRadius={13} order={isSender ? 2 : 1} />
          <Stack spacing={0} order={isSender ? 1 : 2}>
            <Text
              fontSize={{ base: '14px', '2xl': 'sm' }}
              fontWeight={600}
              lineHeight={1.4}
              letterSpacing={0.8}
            >
              {author}
            </Text>
            <Text as='span' fontSize='xs' fontWeight={400} letterSpacing={0.5}>
              {role}
            </Text>
          </Stack>
        </HStack>
      )}

      <Message
        boxStyle={{
          w: 'auto',
          maxW: { base: '550px', '2xl': '650px' },
          whiteSpace: 'pre-wrap',
        }}
      >
        <LinkifyWrapper>{message}</LinkifyWrapper>
      </Message>
    </Stack>
  )
}
