import React from 'react'
import { Navigate } from 'react-router-dom'

import { AuthLayout } from '@/container/AuthLayout'
import { useAppSelector } from '@/shared/app/hooks'
import { sessionState } from '@/shared/features/session.slice'

export const Auth = () => {
  const { session } = useAppSelector(sessionState)

  if (session) {
    return <Navigate to='/' replace />
  }

  return <AuthLayout />
}
