import React, { useState, useEffect, useRef } from 'react'
import { Text } from '@chakra-ui/react'

type TimeLeftProps = {
  expiresAt: Date
}

export const TimeLeft = ({ expiresAt }: TimeLeftProps) => {
  const calculateTime = () => +new Date(expiresAt) - +new Date()

  const getTimeLeft = () => {
    const timeLeft = new Date(calculateTime())
    return `${timeLeft.getMinutes()}:${
      String(timeLeft.getSeconds()).length > 1
        ? timeLeft.getSeconds()
        : `0${timeLeft.getSeconds()}`
    }`
  }

  const refInterval = useRef<NodeJS.Timer>()

  const [time, setTime] = useState(getTimeLeft)
  const [timeOver, setTimeOver] = useState(false)

  useEffect(() => {
    if (calculateTime() < 0) return setTimeOver(true)

    const interval = setInterval(() => {
      setTime(getTimeLeft)
    }, 1000)

    refInterval.current = interval

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    const isExpired = time.split(':').every(val => +val === 0)

    if (isExpired) return setTimeOver(true)
  }, [time])

  useEffect(() => {
    if (timeOver) return clearInterval(refInterval.current)
  }, [timeOver])

  return (
    <Text maxW={500} fontSize='sm' textAlign='center' color='nucba.white-70'>
      {!timeOver
        ? `El código expira en ${time} minutos`
        : 'Upsss, el tiempo se ha agotado 😢'}
    </Text>
  )
}
