import React from 'react'
import { Field, FieldProps } from 'formik'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputProps,
} from '@chakra-ui/react'
import { Input, PasswordInput } from '@nucba-coding/nucba-ui'

type FormInputProps = {
  label: string
  name: string

  /** @default text */

  type?: string
  placeholder?: string
  isDisabled?: boolean
  inputProps?: InputProps
}

export const FormInput = ({
  label,
  name,
  type = 'text',
  placeholder = '',
  isDisabled = false,
  inputProps,
}: FormInputProps) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl
          display='flex'
          flexDir='column'
          alignItems='center'
          isInvalid={Boolean(form.errors[name] && form.touched[name])}
          isDisabled={isDisabled}
        >
          <FormLabel fontSize='sm'>{label}</FormLabel>

          {type === 'password' ? (
            <PasswordInput
              width={{ base: 250, xl: 300 }}
              placeholder={placeholder}
              {...field}
            />
          ) : (
            <Input
              width={{ base: 250, xl: 300 }}
              type={type}
              placeholder={placeholder}
              {...inputProps}
              {...field}
            />
          )}

          <FormErrorMessage
            maxW={300}
            textAlign='center'
            color='nucba.primary'
            fontSize='xs'
            style={{ height: '20px' }}
          >
            {String(form.errors[name])}
          </FormErrorMessage>
        </FormControl>
      )}
    </Field>
  )
}
