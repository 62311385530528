import * as React from 'react'

import Sheet, { SheetRef } from '../utils/bottom_sheet/'
import { Summary } from '@/inscription/components/summary/Summary'
import { useCartState } from '@/inscription/features/cart.slice'
import { ButtonProps } from '@chakra-ui/react'

type ContentHeightProps = {
  /**@default false */
  withCoupon?: boolean

  /**@default false */
  withCta?: boolean
  ctaProps?: ButtonProps

  action?: () => void
}

const ContentHeight = ({
  withCoupon,
  withCta,
  ctaProps,
  action,
}: ContentHeightProps) => {
  const [isOpen] = React.useState(true)
  const ref = React.useRef<SheetRef>()
  const snapTo = (i: number) => ref.current?.snapTo(i)

  const { plan } = useCartState()

  if (plan) {
    snapTo(0)
  }

  return (
    <>
      <Sheet
        ref={ref}
        isOpen={isOpen}
        onClose={() => snapTo(0)}
        initialSnap={1}
        snapPoints={[-100, 40]}
        detent='content-height'
      >
        <Sheet.Container>
          <Sheet.Header />

          <Sheet.Content>
            <Sheet.Scroller>
              <Summary
                withCoupon={withCoupon}
                withCta={withCta}
                action={action}
                ctaProps={ctaProps}
              />
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop onTap={() => snapTo(1)} />
      </Sheet>
    </>
  )
}

export default ContentHeight
