import React from 'react'
import { HStack, Stack, Text, Avatar } from '@chakra-ui/react'
import { AnimatePresence, motion } from 'framer-motion'

import { useAppSelector } from '../../app/hooks'
import { sessionState } from '../../features/session.slice'
import { useNucbaTheme } from '@/shared/hooks'

const avatarVariants = {
  closed: {
    opacity: 0,
  },
  open: {
    opacity: 1,
    transition: {
      delay: 0.1,
      duration: 0.5,
    },
  },
}

type SidebarAvatarProps = {
  isOpen: boolean
}

export const SidebarAvatar = ({ isOpen }: SidebarAvatarProps) => {
  const { colors } = useNucbaTheme()
  const { firstname, lastname } = useAppSelector(sessionState)

  return (
    <HStack spacing={5} ml={isOpen ? '-15px' : 0}>
      <Avatar name={`${firstname} ${lastname}`} borderRadius={10} />
      <AnimatePresence>
        {isOpen && (
          <Stack as={motion.div} variants={avatarVariants} spacing={0}>
            <Text>
              <Text
                as='span'
                display='inline-block'
                fontSize='sm'
                color={colors.nucba['white-70']}
              >
                Hola{' '}
              </Text>
              👋
            </Text>

            <Text lineHeight={1} fontSize='lg'>
              {firstname}
            </Text>
          </Stack>
        )}
      </AnimatePresence>
    </HStack>
  )
}
