import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../app/store'
import { UserRoles } from '../enums'
import { useAppSelector } from '../app/hooks'

export interface SessionState {
  id: string
  token: string
  firstname: string
  lastname: string
  session: boolean
  studentOf: StudentOf[]
  email: string
  createdAt?: Date
  instagram?: string
  isStaff: boolean
}

export const initialState: SessionState = {
  id: '',
  token: '',
  firstname: '',
  lastname: '',
  session: false,
  studentOf: [],
  email: '',
  isStaff: false,
}

type StartSession = {
  id: string
  token: string
  firstname: string
  lastname: string
  email: string
  createdAt?: Date
  instagram?: string
  roles: UserRoles[]
}

type UpdateSession = Partial<SessionState>

export type StudentOf = {
  cohort: string
  studentId: string
  expelledAt: Date | null
  subscription?: string
  duo?: string
}

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    startSession(state, action: PayloadAction<StartSession>) {
      const { roles, ...rest } = action.payload

      const isStaff = roles.some(role =>
        role.includes(
          UserRoles.MENTOR ||
            UserRoles.ADMIN ||
            UserRoles.COMENTOR ||
            UserRoles.STAFF,
        ),
      )

      return { ...state, ...rest, isStaff, session: true }
    },
    endSession() {
      return initialState
    },
    updateSession(state, action: PayloadAction<UpdateSession>) {
      return { ...state, ...action.payload }
    },
    isStudentOf(state, action: PayloadAction<StudentOf[]>) {
      return { ...state, studentOf: action.payload }
    },
  },
})

export const sessionState = (state: RootState): SessionState => state.session
export const useSessionState = () => useAppSelector(sessionState)

export const { startSession, endSession, updateSession, isStudentOf } =
  sessionSlice.actions
export default sessionSlice.reducer
