import * as amplitude from '@amplitude/analytics-browser'

const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY || ''

export const tracker = {
  init: () => {
    amplitude.init(amplitudeApiKey, {
      defaultTracking: true,
    })
  },
  track: (eventType: string, eventProperties?: Record<string, any>) => {
    amplitude.track(eventType, eventProperties)
  },
  getUserId: () => {
    return amplitude.getUserId()
  },
  setUserId: (userId: string) => {
    if (!amplitude.getUserId()) {
      amplitude.setUserId(userId)
    }
  },
  clearUserId: () => {
    amplitude.reset()
  },
}
