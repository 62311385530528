import React from 'react'
import {
  Modal as ChakraModal,
  ModalOverlay,
  useDisclosure,
  UseDisclosureReturn,
  ModalProps as ChakraModalProps,
} from '@chakra-ui/react'

type ModalProps = {
  trigger: React.ReactElement
  children: (disclosureProps: UseDisclosureReturn) => React.ReactNode
  modalProps?: Partial<ChakraModalProps>
}

export const Modal = ({ children, trigger, modalProps }: ModalProps) => {
  const disclosureProps = useDisclosure()

  return (
    <>
      {React.cloneElement(trigger, { onClick: disclosureProps.onOpen })}
      <ChakraModal
        {...modalProps}
        isOpen={disclosureProps.isOpen}
        onClose={disclosureProps.onClose}
      >
        <ModalOverlay
          bg='blackAlpha.400'
          backdropFilter='blur(12px)'
          onClick={disclosureProps.onClose}
          zIndex={-1}
        />
        {children(disclosureProps)}
      </ChakraModal>
    </>
  )
}
