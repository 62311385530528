import React from 'react'
import {
  Box,
  HStack,
  Stack,
  Text,
  Avatar as ChakraAvatar,
} from '@chakra-ui/react'
import { NucCalendar, NucEnvelope, NucInstagram } from '@nucba-coding/nucba-ui'

import { useAppSelector } from '@/shared/app/hooks'
import { Hero } from '@/shared/components/Hero'
import { Isologo } from '@/shared/components/Isologo'
import { sessionState } from '@/shared/features/session.slice'
import { DateTime } from '@/shared/helpers'
import { ContentTemplate } from '@/education/shared/template/ContentTemplate'

export const ProfileHero = () => {
  const { firstname, lastname, studentOf, email, createdAt, instagram } =
    useAppSelector(sessionState)

  return (
    <Hero image='https://res.cloudinary.com/dcatzxqqf/image/upload/v1679871403/Galileo-Platform/Images/banner/profileHero_tjzpyo.webp'>
      <ContentTemplate>
        <HStack justify='center'>
          <Isologo />
        </HStack>
        <Stack w='80%' align='flex-start' spacing={5}>
          <HStack>
            <ChakraAvatar
              name={`${firstname} ${lastname}`}
              size='2xl'
              border='2px solid #0D51FF'
              borderRadius={15}
            />
            <HStack>
              <Box w='9px' h='75px' bgColor='nucba.primary' borderRadius={15} />
              <Stack align='flex-start' spacing={-2}>
                <Text fontSize='5xl'>{`${firstname} ${lastname}`}</Text>
                <HStack align='baseline'>
                  {studentOf.length ? (
                    <>
                      <Text fontSize='lg'>Camada </Text>
                      <HStack divider={<span>|</span>} gap={2}>
                        {studentOf.map(({ cohort }) => (
                          <Text key={cohort}>{cohort}</Text>
                        ))}
                      </HStack>
                    </>
                  ) : (
                    <Text>Todavía no contas con una camada 😣</Text>
                  )}
                </HStack>
              </Stack>
            </HStack>
          </HStack>
          <Stack spacing={4}>
            <HStack>
              <NucCalendar />
              <Text>
                Miembro desde el{' '}
                {DateTime.createFromDate(new Date(createdAt!)).formatDate({
                  dateStyle: 'long',
                })}
              </Text>
            </HStack>
            {instagram && (
              <HStack>
                <NucInstagram />
                <Text>{instagram}</Text>
              </HStack>
            )}
            <HStack>
              <NucEnvelope />
              <Text>{email}</Text>
            </HStack>
          </Stack>
        </Stack>
      </ContentTemplate>
    </Hero>
  )
}
