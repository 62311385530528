import React, { useState } from 'react'
import {
  Box,
  Button,
  HStack,
  Stack,
  TabPanel,
  Text,
  VStack,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import slugify from 'slugify'
import { CartState, productSelected } from '../features/cart.slice'
import { useAppDispatch } from '@/shared/app/hooks'
import { PRODUCTS_TYPES, Product } from '@/shared/types'
import { useInscriptionPrefetch } from '../services/inscriptionQuery.service'

type ProductCardProps = {
  products: Product[]
  type: PRODUCTS_TYPES
  isLoading: boolean
}

const Title = styled.p`
  align-self: flex-start;
  padding-inline: 15px;
  background: linear-gradient(
    110.72deg,
    #737373 15.77%,
    rgba(201, 201, 201, 0.860937) 30.52%,
    rgba(185, 185, 185, 0.66) 54.06%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  white-space: wrap;

  @media (max-width: 768px) {
    font-size: 26px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`

export const ProductCard = ({ products, type }: ProductCardProps) => {
  const dispatch = useAppDispatch()
  const [showMore, setShowMore] = useState(false)
  const itemsToShow = showMore ? products.length : 3
  const itemContainerPadding = 5

  const prefetchCohortsWithProductAvailableDetails = useInscriptionPrefetch(
    'getCohortsWithProductAvailable',
  )

  const handleSelectProduct = (
    selectedProduct: NonNullable<CartState['product']>,
  ) => {
    dispatch(
      productSelected({
        id: selectedProduct.id,
        name: selectedProduct.name,
        type,
      }),
    )
  }

  const handleShowMore = () => {
    setShowMore(!showMore)
  }

  return (
    <TabPanel>
      <Box
        display='flex'
        flexWrap='wrap'
        gap={5}
        w={{ base: '340px', md: '750px', lg: '1100px' }}
        justifyContent='center'
        p={`${itemContainerPadding}px`}
      >
        {products.slice(0, itemsToShow).map(product => (
          <Stack
            key={product.id}
            padding={25}
            marginTop={{ base: '10px', md: 'none' }}
            justifyContent='space-between'
            align='center'
            position='relative'
            height={400}
            borderRadius='3xl'
            w='300px'
            bg={`url(https://res.cloudinary.com/dcatzxqqf/image/upload/v1667923325/Galileo-Platform/Images/_DSC4934_cibkpa.jpg) center/cover no-repeat,  linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 56.25%, #131415 99%)`}
          >
            <HStack alignSelf='self-start' justify='space-between' w='full'>
              <Title>{product.name}</Title>
              <Text>
                {product && product.hasDuoPlan && (
                  <Box
                    px={2}
                    borderRadius='full'
                    backgroundColor='#48BB78'
                    boxShadow=' 0px 0px 20px rgba(0, 0, 0, 0.25)'
                  >
                    DUO
                  </Box>
                )}
              </Text>
            </HStack>
            <VStack>
              {product && product.hasDuoPlan && (
                <Text cursor='default' fontSize='xs'>
                  Acceso para dos personas
                </Text>
              )}
              <Link
                to={`${slugify(product.name, { lower: true })}?product=${
                  product.id
                }`}
                onMouseEnter={() =>
                  prefetchCohortsWithProductAvailableDetails({
                    productId: product.id,
                  })
                }
              >
                <Button
                  disabled={
                    !product.hasAvailableCohort || !product.hasPlansEnable
                  }
                  onClick={() => handleSelectProduct(product)}
                >
                  Inscribirme ahora
                </Button>
              </Link>
              <Text
                color={
                  product.hasAvailableCohort && product.hasPlansEnable
                    ? '#48BB78'
                    : 'nucba.primary'
                }
                as='span'
                fontSize='xs'
              >
                {product.hasAvailableCohort && product.hasPlansEnable
                  ? 'FECHAS DISPONIBLES'
                  : 'AGOTADO'}
              </Text>
            </VStack>
          </Stack>
        ))}
      </Box>
      <HStack justifyContent='center' mt={4}>
        {!showMore && itemsToShow < products.length && (
          <Button gap={3} onClick={handleShowMore}>
            Ver mas
          </Button>
        )}
        {showMore && (
          <Button gap={3} onClick={handleShowMore}>
            Ver menos
          </Button>
        )}
      </HStack>
    </TabPanel>
  )
}
