import React from 'react'
import { Navigate } from 'react-router-dom'

import ExercisesLayout from '@/container/ExercisesLayout'
import { useAppSelector } from '@/shared/app/hooks'
import { sessionState } from '@/shared/features/session.slice'

export const ExercisesRoot = () => {
  const { isStaff } = useAppSelector(sessionState)

  if (!isStaff) {
    return <Navigate to='/' replace />
  }

  return <ExercisesLayout />
}
