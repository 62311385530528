import React, { useState } from 'react'
import {
  Box,
  Link,
  ListItem,
  ModalContent,
  ModalHeader,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react'
import { Button } from '@nucba-coding/nucba-ui'

import { useNucbaTheme } from '@/shared/hooks'
import { useGithubEducationLinkQuery } from '@/shared/services/user-mutation.service'
import { isMyKnownError } from '@/shared/guards'

export const RedeemGitHubEducation = () => {
  const { colors } = useNucbaTheme()
  const [requestLink, setRequestLink] = useState(false)

  const { data, isLoading, error, isError } = useGithubEducationLinkQuery(
    null,
    {
      skip: !requestLink,
    },
  )

  return (
    <ModalContent
      bg='nucba.background'
      w='fit-content'
      paddingInline={8}
      paddingBlock={4}
      borderRadius={15}
    >
      <ModalHeader fontSize='md' fontWeight='bold' textAlign='center'>
        GitHub Education
      </ModalHeader>

      <Stack gap={4}>
        <Stack gap={1}>
          <Text fontSize='14px'>
            Mira los{' '}
            <Link
              href='https://github.com/education/students'
              target='_blank'
              rel='noopener noreferrer'
              color={colors.cyan?.[500]}
              textDecoration='underline'
            >
              beneficios
            </Link>{' '}
            de ser parte de GitHub Education
          </Text>

          <Box>
            <Text fontSize='14px' fontWeight='bold' marginBottom={1}>
              Requisitos
            </Text>
            <UnorderedList fontSize='13px' spacing={1}>
              <ListItem>
                Debes tener una cuenta en{' '}
                <Link
                  href='https://github.com'
                  target='_blank'
                  rel='noopener noreferrer'
                  _hover={{ textDecoration: 'underline' }}
                >
                  GitHub
                </Link>
              </ListItem>
              <ListItem>
                Puedes reclamar este beneficio hasta un máximo de 3 veces por
                año
              </ListItem>
            </UnorderedList>
          </Box>
        </Stack>

        <Button
          size='sm'
          fontSize='sm'
          w='90%'
          alignSelf='center'
          marginTop={200}
          onClick={() => setRequestLink(true)}
          disabled={!!data?.link || isLoading || isError}
        >
          {isLoading
            ? 'Generando link de acceso...'
            : '¡Quiero mi GitHub Student Developer Pack!'}
        </Button>

        {isError && (
          <Text
            color={colors.red?.[600]}
            fontSize='13px'
            fontWeight='semibold'
            textAlign='center'
          >
            {isMyKnownError(error) ? error.data.message : 'Ocurrió un error'}
          </Text>
        )}

        {data?.link && (
          <Text fontSize='14px' textAlign='center'>
            ¡Listo! Haz{' '}
            <Link
              href={data.link}
              target='_blank'
              rel='noopener noreferrer'
              color={colors.cyan?.[500]}
              textDecor='underline'
            >
              click aquí
            </Link>{' '}
            para acceder al Developer Pack
          </Text>
        )}
      </Stack>
    </ModalContent>
  )
}
