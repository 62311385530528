import React from 'react'
import {
  NovuProvider,
  PopoverNotificationCenter,
  NotificationBell,
} from '@novu/notification-center'
import { HStack, Text } from '@chakra-ui/react'
import { useAppSelector } from '../app/hooks'
import { sessionState } from '../features/session.slice'

const Footer = () => {
  return (
    <HStack justify='center' textColor='#FFFFFF'>
      <Text fontWeight='bold'>Hecho con ❤️ en NUCBA</Text>
    </HStack>
  )
}

export const styles = {
  bellButton: {
    root: {
      svg: {
        color: '#FFFFFF',
      },
    },
  },
  unseenBadge: {
    root: { color: '#FFFFF', background: '#ff005c' },
  },
  header: {
    markAsRead: {
      fontSize: '12px',
    },
    title: { color: '#FFFFFF' },
    cog: { display: 'none' },
  },
  notifications: {
    listItem: {
      layout: {
        borderRadius: '7px',
        color: '#FFFFFF',
        fontSize: '12px',
      },
      unread: {
        '::before': { background: '#ff005c' },
      },
    },
  },
}

export const HeaderNovu = () => {
  const { id } = useAppSelector(sessionState)

  return (
    <NovuProvider
      styles={styles}
      i18n='es'
      subscriberId={id}
      applicationIdentifier={
        process.env.REACT_APP_NOVU_APPLICATION_IDENTIFIER ?? ''
      }
    >
      <PopoverNotificationCenter colorScheme={'dark'} footer={() => <Footer />}>
        {({ unseenCount }) => (
          <NotificationBell
            unseenBadgeBackgroundColor='#ff005c'
            unseenCount={unseenCount}
          />
        )}
      </PopoverNotificationCenter>
    </NovuProvider>
  )
}
