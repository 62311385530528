import React from 'react'
import { ButtonProps, Stack } from '@chakra-ui/react'
import { Button } from '@nucba-coding/nucba-ui'

import { ProductSummary } from './ProductSummary'
import { Price } from './Price'
import { Discount } from './Discount'

type SummaryProps = {
  /**@default false */
  withCoupon?: boolean

  /**@default false */
  withCta?: boolean
  ctaProps?: ButtonProps

  action?: () => void
}

export const Summary = ({
  withCoupon = false,
  withCta = false,
  action,
  ctaProps,
}: SummaryProps) => {
  return (
    <Stack
      w='full'
      spacing={8}
      bg={{
        base: 'linear-gradient(325deg, #000 0.74%, #2B2B2B 100%)',
        md: 'transparent ',
      }}
    >
      <ProductSummary />
      <Stack spacing={6}>
        <Price canRemoveCoupon={withCoupon} />

        {withCoupon && <Discount />}
      </Stack>
      {withCta && (
        <Button
          alignSelf={{ base: 'center', md: 'flex-end' }}
          bottom={{ base: 8, md: 0 }}
          w={{ base: '50%', md: '30%' }}
          size='sm'
          fontSize='md'
          letterSpacing={1}
          mt={`${20} !important`}
          onClick={action}
          {...ctaProps}
        >
          Siguiente
        </Button>
      )}{' '}
      *
    </Stack>
  )
}
