import React from 'react'

import { useAppDispatch } from '@/shared/app/hooks'
import { useNucbaTheme } from '@/shared/hooks'
import {
  Currencies,
  PAYMENT_METHODS_TYPES,
  PLANS_TYPES,
  PRODUCTS_TYPES,
} from '@/shared/types'
import { Box, HStack, Stack, Text, VStack } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useSearchParams } from 'react-router-dom'
import {
  campaignSelected,
  planSelected,
  useCartState,
  validateCouponAsync,
} from '../features/cart.slice'
import { formatMoney } from '../utils/formatMoney'

const Title = styled.h2`
  display: flex;
  align-items: flex-end;
  background: linear-gradient(
    110.72deg,
    #737373 15.77%,
    rgba(201, 201, 201, 0.860937) 30.52%,
    rgba(185, 185, 185, 0.66) 54.06%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-style: normal;
  font-weight: 900;
`

type PlansCardProps = {
  id: string
  name: string
  installments: number
  duration: number
  currency: Currencies
  price: number
  description: string
  type: PLANS_TYPES
  isDuo: boolean
  paymentMethod: {
    id: string
    name: string
    type: PAYMENT_METHODS_TYPES
    paymentPolicy: string
  } | null
  campaign: {
    name: string
    discount: number
  } | null
}

export const PlansCard = (plan: PlansCardProps) => {
  const [, setSearchParams] = useSearchParams()

  const { colors } = useNucbaTheme()
  const dispatch = useAppDispatch()
  const { plan: planCart, coupon, product } = useCartState()

  const handlePlanClick = async () => {
    try {
      const { campaign, ...payload } = plan
      dispatch(planSelected(payload))
      dispatch(campaignSelected({ campaign }))
      await dispatch(
        validateCouponAsync({ code: coupon?.code || '', planId: plan.id }),
      )

      setSearchParams(
        prev => {
          const searchParams = new URLSearchParams(prev)
          searchParams.set('plan', plan.id)
          return searchParams
        },
        { preventScrollReset: true },
      )
    } catch (error) {
      console.error('Error al validar cupón o seleccionar plan', error)
    }
  }

  return (
    <VStack
      w='full'
      flexGrow='1'
      align='start'
      bg={`linear-gradient(180deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0.55) 56.77%, rgba(0, 0, 0, 0.00) 100%)`}
      p={4}
      outline={
        planCart?.id === plan.id ? `1px solid ${colors.nucba.primary}` : ''
      }
      borderRadius='3xl'
      cursor='pointer'
      onClick={handlePlanClick}
    >
      <HStack w='full' justifyContent='flex-end' position='relative' left={4}>
        {plan.campaign ? (
          <Stack bg='#3E3E3E' px={2} py={1}>
            <Text
              fontSize={{ base: 'xs', md: 'sm' }}
              fontWeight='bold'
              as='span'
            >
              {plan.campaign.name} {plan.campaign.discount}%
            </Text>
          </Stack>
        ) : null}
      </HStack>
      <Text as={Title} fontSize={{ base: 'xl', md: '3xl' }} lineHeight={1.2}>
        {plan.name}
      </Text>
      <Stack spacing={1} fontSize={{ base: '14px', md: 'sm' }}>
        {product?.type === PRODUCTS_TYPES.ON_DEMAND ? (
          <Stack
            flexDir={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'normal', md: 'center' }}
            gap={{ base: 0, md: 2 }}
            divider={
              <Text as='span' display={{ base: 'none', md: 'inline-block' }}>
                |
              </Text>
            }
          >
            <Text as='span'>Clases asíncronas</Text>
            <Text as='span'>Acceso al instante</Text>
          </Stack>
        ) : (
          <Stack
            flexDir={{ base: 'column', md: 'row' }}
            alignItems={{ base: 'normal', md: 'center' }}
            gap={{ base: 0, md: 2 }}
            divider={
              <Text as='span' display={{ base: 'none', md: 'inline-block' }}>
                |
              </Text>
            }
          >
            <Text as='span'>2 clases semanales</Text>

            <Text as='span'>
              Acceso por {plan.duration} {plan.duration === 1 ? 'mes' : 'meses'}
            </Text>
          </Stack>
        )}
        <Text as='span'>Contenidos complementario grabados</Text>
        <Text as='span'>Entregas y Proyecto Final</Text>
      </Stack>
      <HStack w='full' justifyContent='flex-end'>
        {plan.isDuo && (
          <Box
            px={2}
            borderRadius='full'
            backgroundColor='#48BB78'
            boxShadow=' 0px 0px 20px rgba(0, 0, 0, 0.25)'
          >
            DUO
          </Box>
        )}
        <HStack bg='#1E1E1E' p={2} px={4} borderRadius={12}>
          <Text as='span' fontSize='xs' fontWeight='bold'>
            {plan.paymentMethod?.name}
          </Text>
        </HStack>
      </HStack>
      <HStack w='100%' h={0.5} borderRadius='full' bg={colors.white} m='auto' />
      <VStack w='full' alignItems='flex-end' spacing={0}>
        <Text textAlign='end' fontWeight='bold' as='p'>
          Total:{' '}
          {formatMoney(plan.price, {
            currency: plan.currency,
          })}{' '}
        </Text>
        <Text textAlign='end' as='p' fontSize='sm'>
          {' '}
          <b>
            <Text as='span' color='nucba.primary'>
              {' '}
              {plan.installments} {plan.installments === 1 ? 'cuota' : 'cuotas'}{' '}
              sin interés de{' '}
            </Text>
            {formatMoney(plan.price / plan.installments, {
              currency: plan.currency,
            })}
          </b>{' '}
        </Text>
      </VStack>
    </VStack>
  )
}
