import React, { ReactNode } from 'react'
import { HStack } from '@chakra-ui/react'

type PageContainerProps = {
  children: ReactNode
}

export const PageContainer = ({ children }: PageContainerProps) => {
  return (
    <HStack
      w={{ base: '92%', '2xl': '97%' }}
      mx='auto'
      justify='space-between'
      align='flex-start'
    >
      {children}
    </HStack>
  )
}
