import React from 'react'
import { HStack, Box } from '@chakra-ui/react'

import { PageHeading } from './PageHeading'

type ModulePresentationProps = {
  title: string

  /**@default 5xl */
  fontSize?: string | Record<string, string>
}

export const ModulePresentation = ({
  title,
  fontSize = '5xl',
}: ModulePresentationProps) => {
  return (
    <HStack spacing={3}>
      <Box w='9px' h='45px' bgColor='nucba.primary' borderRadius={15} />
      <PageHeading fontSize={fontSize}>{title}</PageHeading>
    </HStack>
  )
}
