import React from 'react'
import { CohortInfo } from '../components/CohortInfo'
import { CohortCourses } from '../components/CohortCourses'

import { ContentTemplate } from '../../shared/template/ContentTemplate'

export const Cohort = () => {
  return (
    <ContentTemplate>
      <CohortInfo />
      <CohortCourses />
    </ContentTemplate>
  )
}
